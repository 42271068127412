// @inheritedComponent ButtonBase

import * as React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Tooltip from '@material-ui/core/Tooltip'
import withStyles from '@material-ui/core/styles/withStyles'
import ButtonBase from '@material-ui/core/ButtonBase'
import IconButton from 'components/IconButton'
import ToolTipIcon from 'components/icons/ToolTip'
import Typography from 'components/Typography'

export const styles = (theme) => ({
  root: {
    display: 'block',
    position: 'relative',
    zIndex: 0, // Prevents :after from falling behind parent elements.
    color: 'inherit',
    '&:after': {
      ...theme.mixins.absolute(),
      content: '""',
      pointerEvents: 'none',
      transition: theme.transitions.create('background-color', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    '&:hover:after': {
      textDecoration: 'none',
      backgroundColor: theme.palette.action.hover,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&$backgroundOverlay:after': {
      zIndex: -1,
    },
    '&$focusVisible:after': {
      backgroundColor: theme.palette.action.selected,
    },
    '&$selected:after, &$selected:hover:after': {
      backgroundColor: theme.palette.action.selected,
    },
    '&$disabled': {
      opacity: 0.5,
    },
  },
  backgroundOverlay: {},
  focusVisible: {},
  disabled: {},
  selected: {},
  toolTip: {
    position: 'absolute',
    right: 8,
    bottom: 8,
  },
  toolTipIcon: {
    height: 20,
    width: 20,
    '&:hover': {
      color: '#ffffff',
    },
  },
  iconButton: {
    padding: 1,
    margin: 0,
    borderRadius: 16,
    '&:hover': {
      backgroundColor: '#2E2630',
    },
  },
})

const BlockButton = React.forwardRef(function BlockButton(props, ref) {
  const {
    children,
    classes,
    className,
    disabled,
    focusVisibleClassName,
    overlayPlacement = 'foreground',
    selected,
    toolTip,
    ...other
  } = props

  const [tooltipIsOpen, setTooltipIsOpen] = React.useState(false)
  const openToolTip = (event) => {
    setTooltipIsOpen(!tooltipIsOpen)
    event.stopPropagation()
  }

  return (
    <div>
      <ButtonBase
        className={clsx(
          classes.root,
          {
            [classes.backgroundOverlay]: overlayPlacement !== 'foreground',
            [classes.disabled]: disabled,
            [classes.selected]: selected,
          },
          className,
        )}
        focusVisibleClassName={clsx(classes.focusVisible, focusVisibleClassName)}
        disabled={disabled}
        ref={ref}
        aria-pressed={selected}
        {...other}
      >
        {children}
        {toolTip && (
          <Tooltip
            open={tooltipIsOpen}
            onOpen={() => setTooltipIsOpen(true)}
            onClose={() => setTooltipIsOpen(false)}
            className={classes.toolTip}
            placement="top"
            title={
              <>
                <Typography color="inherit">{toolTip}</Typography>
              </>
            }
          >
            <IconButton className={classes.iconButton} onClick={openToolTip}>
              <ToolTipIcon className={classes.toolTipIcon} />
            </IconButton>
          </Tooltip>
        )}
      </ButtonBase>
    </div>
  )
})

BlockButton.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  focusVisibleClassName: PropTypes.string,
  overlayPlacement: PropTypes.oneOf(['background', 'foreground']),
  selected: PropTypes.bool,
  toolTip: PropTypes.string,
}

export default withStyles(styles)(BlockButton)
