import * as React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { capitalize } from '@material-ui/core/utils'
import withStyles from '@material-ui/core/styles/withStyles'
import MuiTypography from '@material-ui/core/Typography'

export const styles = {
  root: {
    /**
     * `font: 'inherit'` is needed on `root` to override button user agent stylesheet for when using:
     * <Link component="button" variant="inherit" />
     */
    font: 'inherit',
  },
}

const localStyles = (theme) => ({
  caption2: theme.typography.caption2,
  preamble: theme.typography.preamble,
  colorTextDisabled: {
    color: theme.palette.text.disabled,
  },
})

const customColors = ['textDisabled']
const customVariants = ['caption2', 'preamble']

const Typography = React.forwardRef(function Typography(props, ref) {
  const { classes, className, color, variant, ...other } = props

  const TypographyProps = {
    className: clsx(
      {
        [classes[`color${capitalize(String(color))}`]]: customColors.includes(color),
        [classes[variant]]: customVariants.includes(variant),
      },
      className,
    ),
    ref,
    ...other,
  }

  if (!customColors.includes(color)) {
    TypographyProps.color = color
  }

  if (!customVariants.includes(variant)) {
    TypographyProps.variant = variant
  }

  return <MuiTypography {...TypographyProps} />
})

Typography.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
}

export default withStyles(localStyles)(Typography)
