import * as React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { chainPropTypes } from '@material-ui/utils'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { articleType } from 'utils'
import RouterLink from 'containers/RouterLink'
import ArrowForwardIcon from 'components/icons/ArrowForward'
import Link from 'components/Link'
import Typography from 'components/Typography'

const BREAKPOINT_KEY_UP = 'sm'

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridGap: theme.spacing(1, 2),
    padding: theme.spacing(4, 3),
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.getContrastText(theme.palette.text.primary),
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: {
      gridTemplateColumns: '50px 1fr',
    },
  },
  aside: {},
  content: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
  heading: {},
  excerpt: theme.mixins.lineClamp(3),
  link: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  linkLabel: {
    marginRight: theme.spacing(1),
  },
}))

const LinkArchive = React.forwardRef(function LinkArchive(props, ref) {
  const {
    article,
    className,
    excerpt: excerptProp,
    heading: headingProp,
    index,
    url: urlProp,
    ...other
  } = props
  const classes = useStyles(props)

  // Setup props via `article` data type or custom fields.
  const displayUrl = article ? article.displayUrl || article.url : urlProp
  const url = article ? article.url : urlProp
  const excerpt = article ? article.excerpt : excerptProp
  const heading = article ? article.heading : headingProp

  const linkProps = {}
  let HeadingComponent = Typography

  if (url) {
    linkProps.component = RouterLink
    linkProps.href = url

    HeadingComponent = Link
  }

  return (
    <article className={clsx(classes.root, className)} ref={ref} {...other}>
      <div className={classes.aside}>
        <Typography component="p" variant="h4">
          {String(index + 1).padStart(2, '0')}
        </Typography>
      </div>

      <div className={classes.content}>
        <HeadingComponent className={classes.heading} component="p" variant="h6" {...linkProps}>
          {heading}
        </HeadingComponent>

        <Typography className={classes.excerpt} variant="body2">
          {excerpt}
        </Typography>

        {url && (
          <div>
            <Link className={classes.link} variant="button" {...linkProps}>
              <span className={classes.linkLabel}>{displayUrl}</span>
              <ArrowForwardIcon fontSize="small" />
            </Link>
          </div>
        )}
      </div>
    </article>
  )
})

LinkArchive.propTypes = {
  article: chainPropTypes(articleType, (props) => {
    if (!props.article && !props.heading && !props.excerpt) {
      return new Error('UI: Either `article` or `heading` + `excerpt` props must be specified.')
    }
    return null
  }),
  className: PropTypes.string,
  excerpt: PropTypes.string,
  heading: PropTypes.string,
  index: PropTypes.number.isRequired,
  url: PropTypes.string,
}

export default LinkArchive
