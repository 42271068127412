import * as React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { refType } from '@material-ui/utils'
import withStyles from '@material-ui/core/styles/withStyles'
import { useRadioGroup } from '@material-ui/core/RadioGroup'
import Collapse from '@material-ui/core/Collapse'
import FormControlLabel from '../FormControlLabel'

const SWITH_BUTTON_SIZE = 42
const SWITH_BUTTON_NEGATIVE_MARGIN = 11

export const styles = (theme) => ({
  root: {
    borderRadius: 6,
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    '& + &': {
      marginTop: theme.spacing(2),
    },
  },
  summary: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: theme.spacing(2),
  },
  formControlLabel: {
    flexGrow: 1,
    alignItems: 'inherit',
    marginLeft: -SWITH_BUTTON_NEGATIVE_MARGIN,
  },
  formControlLabelLabel: {
    marginLeft: theme.spacing(1),
  },
  label: {
    ...theme.typography.subtitle1,
    display: 'block',
    lineHeight: `${SWITH_BUTTON_SIZE}px`,
  },
  helperText: {
    ...theme.typography.body1,
    display: 'block',
    marginBottom: theme.spacing(1),
    lineHeight: 1,
    color: theme.palette.text.secondary,
  },
  icon: {
    ...theme.typography.body1,
    flexShrink: 0,
    marginTop: theme.spacing(1),
    marginLeft: 'auto',
  },
  content: {
    padding: theme.spacing(1, 2, 3, 2),
    paddingLeft: `${theme.spacing(3) + SWITH_BUTTON_SIZE - SWITH_BUTTON_NEGATIVE_MARGIN}px`,
  },
  disabled: {},
})

const FormControlBox = React.forwardRef(function FormControlBox(props, ref) {
  const {
    checked: checkedProp,
    children,
    classes,
    className,
    control,
    disabled,
    expanded,
    helperText,
    icon,
    inputRef,
    label,
    name,
    onChange,
    value,
    preventExpand = false,
    ...other
  } = props
  const radioGroup = useRadioGroup()

  let checked = checkedProp
  if (typeof checked === 'undefined' && radioGroup) {
    checked = radioGroup.value === props.value
  }

  return (
    <div
      className={clsx(
        classes.root,
        {
          [classes.disabled]: disabled,
        },
        className,
      )}
      ref={ref}
      {...other}
    >
      <div className={classes.summary}>
        <FormControlLabel
          classes={{
            root: classes.formControlLabel,
            label: classes.formControlLabelLabel,
          }}
          control={control}
          disabled={disabled}
          label={
            <>
              <span className={classes.label}>{label}</span>
              {helperText && <span className={classes.helperText}>{helperText}</span>}
            </>
          }
          checked={checked}
          name={name}
          onChange={onChange}
          value={value}
          inputRef={inputRef}
        />

        {icon && <div className={classes.icon}>{icon}</div>}
      </div>

      {children && !preventExpand && (
        <Collapse
          classes={{ wrapperInner: classes.content }}
          in={expanded || checked}
          unmountOnExit
        >
          {children}
        </Collapse>
      )}

      {children && preventExpand && <div>{children}</div>}
    </div>
  )
})

FormControlBox.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  control: PropTypes.element.isRequired,
  disabled: PropTypes.bool,
  expanded: PropTypes.bool,
  helperText: PropTypes.node,
  icon: PropTypes.node,
  inputRef: refType,
  label: PropTypes.node,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  preventExpand: PropTypes.bool,
}

export default withStyles(styles)(FormControlBox)
