import * as React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import withStyles from '@material-ui/core/styles/withStyles'
import { Form } from '@oakwood/formit'
import { useProductFlowControl } from 'containers/ProductFlowControl'
import FormControlBox from 'components/FormControlBox'
import { useTranslations } from 'containers/Translations/TranslationsContext'
import Radio from 'components/Radio'
import { ExpandMore } from 'components/icons'
import FormControlLabel from 'components/FormControlLabel'

export const styles = (theme) => ({
  root: {},
  buttons: {
    display: 'grid',
    gridGap: theme.spacing(2, 1),
    alignItems: 'flex-end',
    '$dense &': {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.down('420')]: {
      '$dense &': {
        gridTemplateColumns: 'none',
        gridTemplateRows: '1fr 1fr',
      },
    },
  },
  button: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    width: '100%',
    border: '1px solid #333',
    borderRadius: 5,
    margin: 0,
  },
  buttonRow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
    ...theme.typography.button,
    fontSize: 16,
  },
  buttonSecondaryLabel: {
    ...theme.typography.overline,
    opacity: 0.75,
  },
  buttonFullWidth: {
    '$dense &': {
      gridColumn: 'span 2',
    },
  },
  buttonSelected: {
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.background.paper,
    '&:hover': {
      backgroundColor: theme.palette.text.primary,
      color: theme.palette.background.paper,
    },
    '$popupType &': {
      // backgroundColor: 'rgba(46,38,48, 0.1)',
      backgroundColor: theme.palette.text.primary,
      // backgroundColor: theme.palette.background.paper,
      // color: theme.palette.text.primary,
      color: theme.palette.background.paper,
      '&:hover': {
        backgroundColor: theme.palette.text.primary,
        color: theme.palette.background.paper,
      },
    },
  },
  priceDescriptions: {
    display: 'flex',
    marginTop: theme.spacing(1),
    ...theme.typography.body2,
    fontSize: 12,
  },
  priceDescription: {
    flexShrink: 0,
    width: '100%',
    marginRight: '-100%',
  },
  defaultPriceLabel: {
    textDecoration: 'line-through',
    fontSize: 14,
  },
  dense: {},
  popupType: {},
  buttonLabelGroup: {
    display: 'grid',
    gridTemplateColumns: '5fr 2fr',
    padding: theme.spacing(1.5, 1, 1.5, 0),
  },
  labelNameDescription: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',

    ...theme.typography.button,
  },
  miniButtonPriceLayout: {
    display: 'none',
    flexDirection: 'row',
  },
  radioSelected: {
    // color: theme.palette.text.primary,
    color: theme.palette.background.paper,
  },
  miniButtonDefaultPrice: {
    padding: ` 0px ${theme.spacing(1)}px`,
  },
  miniButtonNameLabel: {
    whiteSpace: 'pre',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
      textAlign: 'center',
    },
  },
  miniButtonLabelGroup: {
    gridTemplateColumns: '1fr',
  },
  priceDescriptionHidden: {
    opacity: 0,
    pointerEvents: 'none',
  },
  subOptsContainer: {
    marginTop: theme.spacing(2),
    width: '100%',
    '& > div:first-child ': {
      padding: theme.spacing(1, 4, 1, 1.5),
    },
    '& >:last-child': {
      margin: 0,
      padding: 0,
      '& > div > div': {
        padding: 0,
      },
    },
  },
  subOptsExpanded: {
    '& > div:first-child ': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  subOptsLabel: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '17px',
    textTransform: 'none',
    '-webkit-user-select' : 'none'
  },
  expandedSubContainer: {
    display: 'flex',
    flexDirection: 'column',
    '& >label': {
      borderBottom: '1px solid black',
    },
    '& >:last-child': {
      borderBottom: '0px solid black',
    },
    '& > *': {
      padding: theme.spacing(1, 4, 1, 0),
    },
  },
  subsElement: {
    width: '100%',
    margin: 0,
    '-webkit-user-select' : 'none'
  },
  expandMoreRotate: {
    display: 'none',
    transform: 'rotate(-180deg)',
    transition: 'transform 0.25s ease',
  },
  expandMoreIcon: {
    transition: 'all 0.25s ease',
  },
  resetLeftMargin: {
    marginLeft: theme.spacing(-1)
  }
})

const ProductFlowChoices = React.forwardRef(function ProductFlowChoices(props, ref) {
  const { classes, className, variant: variantProp, hideDescription = false,useBasePrices, ...other } = props

  const { isPopupType, product, selectedIdx, onProductPriceChange } = useProductFlowControl()

  const subPrices = product.prices?.filter((price) => price.lineType === 'S') || []

  const [selectedSubPrice, setSelectedSubPrice] = React.useState(subPrices?.[0])
  const [subExpanded, setSubExpanded] = React.useState(false)

  const handleChange = React.useCallback(
    (id, defaultSub) => {
      if (defaultSub) {
        const idx = product.prices?.findIndex((p) => p === selectedSubPrice)
        onProductPriceChange(parseInt(idx, 10))
      } else {
        onProductPriceChange(parseInt(id, 10))
      }
    },
    [onProductPriceChange, product.prices, selectedSubPrice],
  )

  const t = useTranslations()

  let formattedPrices = []
  
  if (subPrices?.length > 1 && !isPopupType) {
    formattedPrices = [
      {
        defaultPrice: selectedSubPrice.defaultPrice || 0,
        displayDiscountAmount: selectedSubPrice.displayDiscountAmount || null,
        id: selectedSubPrice.id || 0,
        lineType: selectedSubPrice.lineType || 'S',
        name: product?.custom7 || t('Web.CTA.Subscribe'),
        price: selectedSubPrice.price || 39,
        priceDescription: selectedSubPrice.priceDescription || t('Web.CTA.Subscribe.Description'),
        supplyLongevity: null,
        prices: subPrices,
      },
    ]

    if (product?.prices?.[0]?.lineType !== 'S') formattedPrices.unshift(...product.prices?.filter((price) => price.lineType !== 'S')) // prettier-ignore
    else formattedPrices.push(...product.prices?.filter((price) => price.lineType !== 'S'))
  } 
  else if(useBasePrices){
    formattedPrices = product?.prices?.slice(0,2)
    }
  else {
    formattedPrices = product.prices
  }

  let variant = variantProp
  if (variant == null) {
    variant = isPopupType ? 'default' : 'dense'
  }

  const handleSubs = React.useCallback(() => {
    setSubExpanded((prev) => !prev)
  }, [])

  const handleSubPrice = React.useCallback(
    (value) => {
      setSelectedSubPrice(value)
      const id = product.prices?.findIndex((price) => price === value)
      onProductPriceChange(parseInt(id, 10))
      setSubExpanded((prev) => !prev)
    },
    [onProductPriceChange, product.prices],
  )

  return (
    <div
      className={clsx(
        classes.root,
        {
          [classes.popupType]: isPopupType,
          [classes.dense]: variant === 'dense',
        },
        className,
      )}
      ref={ref}
      {...other}
    >
      {product.prices?.length > 0 && formattedPrices.length > 0 && (
        <>
          <Form className={classes.buttons} name="productFlowChoices">
            {formattedPrices.map((price, idx) => {
              if (!isPopupType && price.lineType === 'S') {
                return (
                  <FormControlLabel
                    key={idx}
                    className={clsx(classes.button, {
                      [classes.buttonFullWidth]: price.fullWidth,
                      [classes.buttonSelected]:
                        selectedIdx === idx || product.prices?.[selectedIdx].lineType === 'S',
                    })}
                    onChange={(event) => handleChange(event.target.value, subPrices.length > 1)}
                    value={idx ?? 0}
                    control={
                      <Radio
                        checked={
                          selectedIdx === idx || product.prices?.[selectedIdx].lineType === 'S'
                        }
                        className={clsx({
                          [classes.buttonSelected]:
                            selectedIdx === idx || product.prices?.[selectedIdx].lineType === 'S',
                        })}
                        onChange={(event) => handleChange(event.target.value, subPrices.length > 1)}
                        value={idx ?? 0}
                      />
                    }
                    disabled={false}
                    label={
                      <span
                        className={clsx(classes.buttonLabelGroup, classes.miniButtonLabelGroup)}
                      >
                        <span
                          className={clsx(
                            classes.labelNameDescription,
                            classes.miniButtonNameLabel,
                          )}
                        >
                          {price.name}
                        </span>
                      </span>
                    }
                  >
                    {''}
                  </FormControlLabel>
                )
              }
              return (
                <FormControlLabel
                  key={idx}
                  className={clsx(classes.button, {
                    [classes.buttonFullWidth]: price.fullWidth,
                    [classes.buttonSelected]: selectedIdx === idx,
                  })}
                  onChange={(event) => handleChange(event.target.value)}
                  value={idx ?? 0}
                  control={
                    <Radio
                      checked={selectedIdx === idx}
                      className={clsx({
                        [classes.buttonSelected]: selectedIdx === idx,
                      })}
                      onChange={(event) => handleChange(event.target.value)}
                      value={idx ?? 0}
                    />
                  }
                  disabled={false}
                  label={
                    <span
                      className={clsx(classes.buttonLabelGroup, {
                        [classes.miniButtonLabelGroup]: !isPopupType,
                      })}
                    >
                      <span
                        className={clsx(classes.labelNameDescription, {
                          [classes.miniButtonNameLabel]: !isPopupType,
                        })}
                      >
                        {price.name}
                        {isPopupType && (
                          <div className={classes.priceDescriptions}>
                            <div key={idx} className={clsx(classes.priceDescription)}>
                              {price.priceDescription}
                            </div>
                          </div>
                        )}
                      </span>
                    </span>
                  }
                >
                  {''}
                </FormControlLabel>
              )
            })}
          </Form>

          {!isPopupType &&
            product.prices?.[selectedIdx]?.lineType === 'S' &&
            subPrices?.length > 1 && (
              <FormControlBox
                className={clsx(classes.subOptsContainer, {
                  [classes.subOptsExpanded]: subExpanded,
                })}
                control={
                  <Radio checked style={{ alignSelf: 'center' }} onClick={() => handleSubs()} />
                }
                icon={
                  <ExpandMore
                    className={clsx(classes.expandMoreIcon, {
                      [classes.expandMoreRotate]: subExpanded,
                    })}
                    onClick={() => handleSubs()}
                  />
                }
                label={<span className={clsx(classes.subOptsLabel, classes.resetLeftMargin)} >{selectedSubPrice.name}</span>}
                checked={subExpanded}
              >
                <div className={classes.expandedSubContainer}>
                  {subPrices.map((price, idx) => (
                    <React.Fragment key={idx}>
                    {price !== selectedSubPrice && (
                    <FormControlLabel
                      key={idx}
                      control={
                        <Radio
                          checked={price === selectedSubPrice}
                          onClick={() => handleSubPrice(price)}
                        />
                      }
                      label={<span className={classes.subOptsLabel}>{price.name}</span>}
                      disabled={false}
                      value={price.id}
                      className={classes.subsElement}
                    >
                      {''}
                    </FormControlLabel>
                    )}
                    </React.Fragment >
                  ))}
                </div>
              </FormControlBox>
            )}

          {!hideDescription && !isPopupType && (
            <div className={classes.priceDescriptions} style={{ paddingLeft: 4, marginTop: 12 }}>
              <div className={clsx(classes.priceDescription)}>
                {product.prices?.[selectedIdx]?.priceDescription || ''}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
})

ProductFlowChoices.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'dense']),
  onProductPriceChange: PropTypes.func,
  hideDescription: PropTypes.bool,
  useBasePrices: PropTypes.bool,
}

export default withStyles(styles)(ProductFlowChoices)
