/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Media from '@oakwood/oui/Media'
import IconButton from 'components/IconButton'
import Play from 'components/icons/Play'

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    flex: '0 0 20%',
    overflow: 'hidden',
    height: '100%',
  },
  collapsedContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    margin: 0,
    padding: 0,
    outline: 'none',
    border: 'none',
    cursor: 'pointer',
  },
  backgroundMediaMain: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  backgroundMedia: {
    height: '100%',
  },
  controlsIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
    backgroundColor: '#2E2630',
    borderRadius: '50%',
    width: '48px',
    height: '48px',
    '&:hover': {
      backgroundColor: 'rgb(32, 26, 33)',
      boxShadow: 'none',
    },
  },
  playIcon: {
    height: '20px',
    width: '20px',
    marginLeft: '4px',
    '&>*': {
      fill: theme.palette.background.paper,
    },
  },
}))

const TestimonialsItem = (props) => {
  const { className, itemIndex, assetUrl, videoUrl, product, productData, onSetItemExpanded } =
    props
  const classes = useStyles(props)

  const itemProps = {
    videoUrl,
    product,
    productData,
    itemIndex,
  }

  return (
    <div className={clsx(classes.root, className)}>
      <div onClick={() => onSetItemExpanded(itemProps)} className={classes.collapsedContainer}>
        {assetUrl?.breakpoints?.xs && (
          <Media
            src={assetUrl?.breakpoints?.xs}
            alt={assetUrl?.alt || 'Testimonial Image'}
            className={classes.backgroundMediaMain}
          />
        )}

        <IconButton edge="start" aria-label="Close item" className={classes.controlsIcon}>
          <Play className={classes.playIcon} />
        </IconButton>
      </div>
    </div>
  )
}

TestimonialsItem.propTypes = {
  assetUrl: PropTypes.string,
  videoUrl: PropTypes.string,
  product: PropTypes.array,
  className: PropTypes.string,
  productData: PropTypes.object,
  onSetItemExpanded: PropTypes.func,
  itemIndex: PropTypes.number,
}

export default TestimonialsItem
