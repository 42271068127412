export { default } from '@material-ui/core/Accordion'

export const styles = (theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: 'transparent',
    '& + &': {
      borderTop: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 0,
      '&:before': {
        opacity: 1,
      },
    },
  },
})
