import * as React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useGlobal } from 'api'
import { useProductFlowControl } from 'containers/ProductFlowControl'
import Button from 'components/Button'
import { useTranslations } from 'containers/Translations/TranslationsContext'
import Check from 'components/icons/Check'

const useStyles = makeStyles((theme) => ({
  priceDescriptions: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
  },
  priceDescription: {
    flexShrink: 0,
    width: '100%',
    marginRight: '-100%',
  },
  ctaArea: {
    justifyContent: 'end',
  },
  custom3: {
    paddingTop: theme.spacing(1),
  },
  add: {
    display: 'flex',
    transition: 'all .5s',
    alignItems: 'center',
    justifyContent: 'center',
    height: '56px',
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.black,
    },
  },
  addBefore: {
    height: '56px',
    backgroundColor: '#CAB7AA !important',
    backgroundOpacity: 0.5,
  },
  check: {
    opacity: 0.01,
    transition: 'all .1s',
    transform: 'scale(0.01)',
    width: 0,
  },
  checkBefore: {
    opacity: 1,
    width: '24px',
    transform: 'scale(1)',
    padding: '1px',
    height: '100%',
    border: '0.5px solid #fff',
    borderRadius: '100%',
  },
  label: {
    opacity: 1,
    transition: 'all .1s',
    transform: 'scale(1)',
    content: "''",
  },
  labelBefore: {
    opacity: 0.01,
    transform: 'scale(0.01)',
    width: 0,
  },
  disabled: {
    backgroundColor: theme.palette.action.disabledBackground,
    color: theme.palette.action.disabled,
  },
}))

const ProductFlowCta = React.forwardRef(function ProductFlowCta(props, ref) {
  const { children, showAmount = true, selectionType = '', onbuttonDescription, ...other } = props
  const classes = useStyles(props)

  const { isPopupType, expanded, onCtaClick, selectedPrice, product, addResult } =
    useProductFlowControl()
  const { checkoutEnabled, onFormatCurrency } = useGlobal()
  const t = useTranslations()

  if (!checkoutEnabled) {
    return null
  }

  const buttonLabel = showAmount
    ? `${onFormatCurrency(selectedPrice?.price || 0)} - ${t('Web.ProductFlowCTA.AddToCart')}`
    : t('Web.ProductFlowCTA.AddToCart')

  const variantId = product?.variantId || 0

  const outOfStock = product?.variants?.find((v) => v?.id === variantId)?.outOfStock || false

  let displayLabel = buttonLabel

  if (outOfStock) displayLabel = t('Web.ProductFlowCTA.OutOfStock')
  else if (isPopupType && !expanded) displayLabel = t('Web.ProductFlowCTA.AddToCart')

  return (
    <div className={classes.ctaArea}>
      <Button
        onClick={onCtaClick}
        className={clsx(classes.add, {
          [classes.addBefore]: addResult,
          [classes.disabled]: outOfStock,
        })}
        size="large"
        fullWidth
        ref={ref}
        disabled={outOfStock}
        {...other}
      >
        <Check
          className={clsx(classes.check, {
            [classes.checkBefore]: addResult,
          })}
        />
        <span
          className={clsx(classes.label, {
            [classes.labelBefore]: addResult,
          })}
        >
          {children || displayLabel}
        </span>
      </Button>
      {selectionType === 'onbutton' && selectedPrice && onbuttonDescription && (
        <div className={classes.priceDescriptions}>
          <div className={classes.priceDescription}>{selectedPrice.priceDescription}</div>
          {product?.custom3 !== null && product?.custom3 !== '' && (
            <div className={classes.custom3}>{product?.custom3}</div>
          )}
        </div>
      )}
    </div>
  )
})

ProductFlowCta.propTypes = {
  children: PropTypes.node,
  showAmount: PropTypes.bool,
  selectionType: PropTypes.string,
  onbuttonDescription: PropTypes.bool,
}

export default ProductFlowCta
