import * as React from 'react'
import PropTypes from 'prop-types'
import { useGlobal } from 'api'
import { hero as transformHero, media } from 'utils/transforms'
import ProductShowcaseComponent from './ProductShowcase'

const ProductShowcase = (props) => {
  const { products } = useGlobal()

  const slides = props.slides
    .map((slide) => {
      if (slide.component !== 'Product') {
        return slide
      }

      const product = products?.find((p) => p.productCode === slide.productCode)

      if (!product) {
        return false
      }

      return {
        ...slide,
        ...product,
      }
    })
    .filter((s) => s)

  const ingredients = []

  const transformedSlides = slides?.map(({ _uid, ...slide }) => {
    if (slide.component === 'Hero') {
      return {
        type: 'hero',
        props: transformHero(slide),
      }
    }
    if (slide.component === 'Ingredient') {
      ingredients.push({
        name: slide.name,
        nameSuffix: slide.subtitle,
        perServing: slide.perServing,
        dri: slide.dri,
        perHundredGrams: slide.perHundredGrams,
        perFifteenGrams: slide.perFifteenGrams,
        rda: slide.rda,
        attributes: slide.test,
      })

      return {
        type: 'ingredient',
        props: {
          ingredient: {
            name: slide?.name,
            features: slide?.types?.map((feature) => {
              return {
                imageUrl: feature?.mediaSrc?.filename,
                label: feature?.heading,
              }
            }),
            description: slide?.description,
            ...slide,
          },
          mediaProps: media(slide?.media),
        },
      }
    }
    if (slide.component === 'Product') {
      const xs = slide.imagesData.find((image) => image.tag === '1x')?.imageUrl
      const sm = slide.imagesData.find((image) => image.tag === '3x')?.imageUrl
      const md = slide.imagesData.find((image) => image.tag === '7x')?.imageUrl
      const mediaProps = {
        component: 'picture',
        breakpoints: {
          xs,
          sm,
          md,
        },
      }

      return {
        type: 'product',
        props: {
          product: slide,
          mediaProps,
        },
      }
    }

    return slide
  })

  const transformProps = {
    ...props,
    slides: transformedSlides,
    ingredients,
  }

  return <ProductShowcaseComponent {...transformProps} />
}

ProductShowcase.propTypes = {
  slides: PropTypes.array,
}

export default ProductShowcase
