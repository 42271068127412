import * as React from 'react'
import PropTypes from 'prop-types'
import { useGlobal } from 'api'
import ProductGroupVariantsComponent from './ProductGroupVariants'

const ProductGroupVariants = (props) => {
  const { products } = useGlobal()
  const { productCode, ctaType } = props

  const product = products.filter((p) => p.productCode === productCode)

  return <ProductGroupVariantsComponent product={product[0]} ctaType={ctaType} />
}

ProductGroupVariants.propTypes = {
  productCode: PropTypes.string,
  ctaType: PropTypes.oneOf(['link', 'button']),
}

export default ProductGroupVariants
