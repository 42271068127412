import * as React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
// import { useRouter } from 'next/router'
// import Rating from '@material-ui/lab/Rating'
import makeStyles from '@material-ui/core/styles/makeStyles'
// import { useReviews } from 'api'
import Image from 'next/image'
import { productType } from 'utils'
import Typography from 'components/Typography'
import Html from 'components/Html'
// import Link from 'components/Link'

export const useStyles = makeStyles((theme) => ({
  root: theme.mixins.verticalRhythm(2),
  heading: {},
  name: {},
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  rating: {},
  ratingLabel: {
    ...theme.typography.overline,
    marginLeft: theme.spacing(1),
  },
  number: {
    color: theme.palette.text.hint,
  },
  list: {
    ...theme.mixins.horizontalRhythm(1),
    display: 'flex',
  },
  listWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > * + *': {
      marginRight: theme.spacing(1.5),
    },
  },
  listItem: {
    width: 77,
    maxWidth: 100,
  },
  listItemWrap: {
    width: 70,
    maxWidth: 100,
  },
  listItemIcon: {
    fontSize: 44,
  },
  listItemImage: {
    width: 'auto',
    height: 44,
  },
  listItemLabel: {
    ...theme.typography.overline,
    display: 'block',
  },
  description: {},
}))

const ProductDetailsDigital = React.forwardRef(function ProductDetails(props, ref) {
  const { className, product, text, content, txtAlign = 'left', ...other } = props
  const classes = useStyles(props)

  // const router = useRouter()
  // const { totalScore, totalReviews } = useReviews(product?.productCode)

  // const handleRatingsClick = React.useCallback(() => {
  //   const reviewsOnPage = document.querySelector('#reviews')

  //   if (reviewsOnPage) {
  //     reviewsOnPage.scrollIntoView({ behavior: 'smooth' })
  //   } else {
  //     router.push(`${product.href}?reviews`)
  //   }
  // }, [product, router])

  return (
    <div className={clsx(classes.root, className)} ref={ref} {...other}>
      <Typography className={classes.heading} component="h2" variant="h5">
        <span className={classes.name}>{product.name}</span>
        <span className={classes.number}>&nbsp;{product.nameSuffix}</span>
      </Typography>

      {/* <div className={classes.row}>
        <Link // eslint-disable-line jsx-a11y/anchor-is-valid
          display="block"
          component="button"
          type="button"
          className={classes.ratingsButton}
          onClick={handleRatingsClick}
        >
          <Rating className={classes.rating} size="medium" value={totalScore} readOnly />
          <span className={classes.ratingLabel}>{totalReviews} reviews</span>
        </Link>
      </div> */}

      {product.features.length > 0 && (
        <div
          className={clsx({
            [classes.list]: product.features.length < 5,
            [classes.listWrap]: product.features.length > 4,
          })}
        >
          {product.features?.map((feature, idx) => (
            <div
              key={idx}
              className={clsx({
                [classes.listItem]: product.features.length < 5,
                [classes.listItemWrap]: product.features.length > 4,
              })}
            >
              <Image
                className={classes.listItemImage}
                src={feature.imageUrl}
                width={44}
                height={44}
                unoptimized
                alt={feature?.label || `Feature image ${idx}`}
              />
              <span className={classes.listItemLabel}>{feature.label}</span>
            </div>
          ))}
        </div>
      )}

      {content && (
        <Html
          style={{ textAlign: txtAlign, marginTop: 70 }}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      )}
      <Typography className={classes.description}>{text}</Typography>
    </div>
  )
})

ProductDetailsDigital.propTypes = {
  className: PropTypes.string,
  hideDescription: PropTypes.bool,
  product: productType.isRequired,
  text: PropTypes.string,
  content: PropTypes.string,
  txtAlign: PropTypes.string,
}

export default ProductDetailsDigital
