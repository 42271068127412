import * as React from 'react'
import createSvgIcon from '../utils/createSvgIcon'

export default createSvgIcon(
  <g
    transform="translate(0.000000,641.000000) scale(0.100000,-0.100000)"
    fill="#000000"
    stroke="none"
  >
    <path d="M3054 5419 c-881 -56 -1655 -650 -1943 -1491 -82 -240 -115 -449 -115 -723 0 -358 68 -652 223 -970 264 -537 731 -945 1295 -1130 235 -77 435 -108 696 -108 213 0 356 18 555 68 779 199 1392 816 1590 1600 45 178 65 344 65 545 0 357 -74 678 -227 984 -405 808 -1235 1284 -2139 1225z m331 -130 c396 -34 806 -195 1110 -437 467 -372 749 -903 795 -1499 39 -507 -132 -1050 -457 -1453 -73 -90 -264 -278 -348 -341 -285 -217 -583 -347 -942 -410 -167 -30 -470 -33 -638 -6 -557 87 -1049 384 -1380 831 -163 221 -296 515 -355 783 -82 374 -61 765 61 1122 312 910 1201 1492 2154 1410z" />
    <path d="M2350 4283 c-24 -12 -39 -29 -47 -53 -9 -26 -12 -286 -12 -1028 -1 -1081 -3 -1050 54 -1072 16 -6 163 -10 359 -10 322 0 334 1 360 21 l26 20 0 1039 0 1039 -24 28 -24 28 -329 3 c-297 2 -331 1 -363 -15z m610 -1078 l0 -955 -265 0 -265 0 0 955 0 955 265 0 265 0 0 -955z" />
    <path d="M3370 4287 c-52 -26 -50 12 -47 -1094 2 -976 3 -1033 20 -1050 16 -17 45 -18 378 -18 l361 0 19 24 c18 23 19 54 19 1060 -1 1096 1 1054 -47 1079 -32 17 -669 16 -703 -1z m620 -1082 l0 -955 -265 0 -265 0 0 955 0 955 265 0 265 0 0 -955z" />
  </g>,
  'Pause',
  '0 0 641.000000 641.000000',
  'xMidYMid meet',
)
