import * as React from 'react'
import PropTypes from 'prop-types'
import { useGlobal } from 'api'
import ProductHeroComponent from './ProductHero'

const ProductHero = (props) => {
  const { products } = useGlobal()

  const product = products?.find((p) => p.productCode === props.productCode)

  const packageShot = product?.imageUrl || ''

  const transformProps = {
    ...props,
    product,
    packageShot,
  }

  return <ProductHeroComponent {...transformProps} />
}

ProductHero.propTypes = {
  productCode: PropTypes.string,
  hideImage: PropTypes.bool,
  showDescription: PropTypes.bool,
}

export default ProductHero
