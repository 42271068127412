import * as React from 'react'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Media from '@oakwood/oui/Media'
import MediaReveal from '@oakwood/oui/MediaReveal'
import { productType } from 'utils'
import Tag from 'components/Tag'
import ProductCard from 'containers/ProductCard'
import Typography from 'components/Typography'

const BREAKPOINT_KEY_UP = 'md'

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: -1,
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  grid: {
    ...theme.mixins.contain('lg'),
    display: 'grid',
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: {
      gridTemplateColumns: '3fr 2fr',
    },
  },
  gridItem: {
    padding: 'var(--coa-section-spacing) var(--coa-container-spacing)',
    '&:not(:first-child)': {
      borderTop: `1px solid ${theme.palette.divider}`,
    },
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: {
      '&:not(:first-child)': {
        borderTop: 0,
        borderLeft: `1px solid ${theme.palette.divider}`,
      },
    },
  },
  heading: {
    maxWidth: 480,
    marginBottom: '1em',
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: theme.typography.h3,
  },
  text: {
    maxWidth: 580,
    marginBottom: '2em',
  },
  cols: {
    display: 'grid',
    gridGap: theme.spacing(6, 8),
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
  col: {
    ...theme.mixins.verticalRhythm(2),
  },
  figure: {
    marginBottom: theme.spacing(4),
  },
  product: {
    ...theme.mixins.contain(400),
  },
}))

function Product2(props) {
  const { entries, heading, product, text, imageUrl, selectionType } = props
  const classes = useStyles(props)

  return (
    <section className={classes.root}>
      <div className={classes.grid}>
        <div className={classes.gridItem}>
          <Typography className={classes.heading} component="h1" variant="h5">
            {heading}
          </Typography>

          {text && (
            <Typography className={classes.text} variant="body1">
              {text}
            </Typography>
          )}

          {entries?.length > 0 && (
            <div className={classes.cols}>
              {entries.map((entry, idx) => (
                <div key={idx} className={classes.col}>
                  {entry.imageUrl && (
                    <Tag
                      component="a"
                      styleRoot={false}
                      href={entry?.ctaUrl || '#'}
                      aria-label={entry?.heading || `Product media CTA`}
                    >
                      <MediaReveal className={classes.figure} width={380} height={215}>
                        <Media className={classes.media} src={entry.imageUrl} />
                      </MediaReveal>
                    </Tag>
                  )}

                  <Typography component="p" variant="h6">
                    {entry.heading}
                  </Typography>

                  <Typography variant="body1">
                    <span
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{ __html: entry.text }}
                    />
                  </Typography>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className={classes.gridItem}>
          <ProductCard
            className={classes.product}
            product={product}
            entryUrl={imageUrl}
            ctaType="button"
            selectionType={selectionType}
          />
        </div>
      </div>
    </section>
  )
}

const entryType = PropTypes.shape({
  heading: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  text: PropTypes.string.isRequired,
})

Product2.propTypes = {
  entries: PropTypes.arrayOf(entryType),
  heading: PropTypes.string.isRequired,
  product: productType.isRequired,
  text: PropTypes.string,
  imageUrl: PropTypes.string,
  selectionType: PropTypes.oneOf(['grid', 'scrollup', 'onbutton']),
}

export default Product2
