import PropTypes from 'prop-types'

const imageType = PropTypes.shape({
  imageUrl: PropTypes.string.isRequired,
})

const featureType = PropTypes.shape({
  imageUrl: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
})

const priceType = PropTypes.shape({
  defaultSelected: PropTypes.bool,
  description: PropTypes.string,
  discountAmount: PropTypes.number,
  displayDiscountAmount: PropTypes.number,
  displayPrice: PropTypes.string,
  fullWidth: PropTypes.bool,
  lineType: PropTypes.string,
  name: PropTypes.string,
  supplyLongevity: PropTypes.string,
})

const productType = PropTypes.shape({
  description: PropTypes.string,
  features: PropTypes.arrayOf(featureType),
  href: PropTypes.string,
  images: PropTypes.arrayOf(imageType),
  name: PropTypes.string,
  nameSuffix: PropTypes.string,
  priceFrom: PropTypes.number,
  prices: PropTypes.arrayOf(priceType),
  slugUri: PropTypes.string,
  supplyLongevityFrom: PropTypes.string,
})

export default productType
