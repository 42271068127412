import * as React from 'react'
import getScrollbarSize from '@material-ui/core/utils/getScrollbarSize'

const useEnhancedEffect = typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect

export default function useScrollbarSize() {
  const [scrollbarSize, setScrollbarSize] = React.useState(0)

  useEnhancedEffect(() => {
    setScrollbarSize(getScrollbarSize())
  }, [])

  return scrollbarSize
}
