export { default } from '@material-ui/core/Toolbar'

export const styles = (theme) => ({
  gutters: {
    // Override Mui styles
    ...theme.mixins.gutters('var(--coa-toolbar-spacing)'),
    [theme.breakpoints.up('sm')]: theme.mixins.gutters('var(--coa-toolbar-spacing)'),
  },
  regular: theme.mixins.toolbar,
  dense: theme.mixins.toolbarDense,
})
