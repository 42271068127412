import * as React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from '../SvgIcon'

const Cart = React.memo(
  React.forwardRef(({ amount, ...other }, ref) => (
    <SvgIcon ref={ref} {...other}>
      {amount && (
        <defs>
          <mask id={`svg-cart-amount-mask-${amount}}`}>
            <rect x="0" y="0" width="100%" height="100%" fill="#fff" />
            <text x="21" y="21.5" textAnchor="end" fill="#000" style={{ fontSize: 10 }}>
              {amount}
            </text>
          </mask>
        </defs>
      )}

      <g mask={amount ? `url(#svg-cart-amount-mask-${amount}})` : undefined}>
        <path
          d="M4.2,6.346H19.8v16.02H4.2Z"
          fill={`${amount && amount > 0 ? '#CAB7AA' : '#FFF'}`}
        />
        <path d="M17.382,5.739a8.131,8.131,0,0,0-1.236-2.433A5.341,5.341,0,0,0,11.914,1,5.344,5.344,0,0,0,7.682,3.306,8.178,8.178,0,0,0,6.446,5.739H3.75V23h16.5V5.739ZM8.538,3.946a4.279,4.279,0,0,1,3.376-1.88,4.276,4.276,0,0,1,3.376,1.88,6.973,6.973,0,0,1,.955,1.793H7.583A7,7,0,0,1,8.538,3.946ZM19.18,21.829H4.82V6.91H6.163A9.937,9.937,0,0,0,6,8.679H7.07A8.825,8.825,0,0,1,7.252,6.91h9.325a8.825,8.825,0,0,1,.182,1.769h1.07a9.845,9.845,0,0,0-.164-1.769H19.18Z" />
        {amount && <rect x="12" y="12" width="12" height="12" rx="6" ry="6" />}
      </g>
    </SvgIcon>
  )),
)

Cart.propTypes = {
  amount: PropTypes.number,
}

Cart.uiName = 'SvgIcon'

export default Cart
