import * as React from 'react'
import createSvgIcon from '../utils/createSvgIcon'

export default createSvgIcon(
  <>
    <circle cx="76.5" cy="76.5" r="76.5" fill="#2E2630" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60.6068 41.5842C60.6068 43.4269 60.6068 45.7303 61.0674 47.573C61.0674 52.6404 71.2023 60.4719 76.7303 68.764C81.7978 60.4719 91.9326 52.6404 92.3933 47.573C92.3933 45.2696 92.3933 43.4269 92.3933 41.5842H60.6068Z"
      fill="white"
    />

    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60.6068 107.921C60.6068 105.618 60.6068 103.775 61.0674 101.932C61.0674 96.8651 71.2023 89.0336 76.7303 80.7415C81.7978 89.0336 91.9326 96.8651 92.3933 101.932C92.3933 103.775 92.3933 106.079 92.3933 107.921H60.6068Z"
      fill="white"
    />

    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56 25C56 33.2921 56 41.1236 56 47.573C56.4607 57.7079 73.9663 67.382 73.9663 74.7528C73.9663 82.1236 56.4607 91.7978 56 101.933C56 107.921 56 115.753 56 124.506H97C97 115.753 97 107.921 97 101.933C96.5393 91.7978 79.0337 82.1236 79.0337 74.7528C79.0337 67.382 96.5393 57.7079 97 47.573C97 41.1236 97 33.2921 97 25H56ZM94.6966 122.202H58.3034C58.3034 115.292 58.3034 108.382 58.764 101.933C58.764 92.2584 76.2697 84.8876 76.2697 74.7528C76.2697 64.618 58.764 57.2472 58.764 47.573C58.3034 40.6629 58.3034 34.2135 58.3034 27.3034H94.6966C94.6966 34.2135 94.6966 40.6629 94.6966 47.573C94.236 57.2472 77.191 64.618 77.191 74.7528C77.191 84.8876 94.236 92.2584 94.6966 101.933C94.6966 108.382 94.6966 115.292 94.6966 122.202Z"
      fill="white"
    />
  </>,
  'BigCheck',
  '0 0 153 153',
)
