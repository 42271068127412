import * as React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useForkRef } from '@material-ui/core/utils'
import withStyles from '@material-ui/core/styles/withStyles'
import { useProductFlowControl } from 'containers/ProductFlowControl'

export const styles = {
  root: {
    position: 'relative',
  },
}

const ProductFlowPopupContainer = React.forwardRef(function ProductFlowPopupContainer(props, ref) {
  const { children, classes, className, component: Component = 'div', ...other } = props

  const { expanded, onClose } = useProductFlowControl()

  const rootRef = React.useRef(null)
  const handleRef = useForkRef(rootRef, ref)

  React.useEffect(() => {
    const handleClickAway = (event) => {
      const eventPath = event.path || (event.composedPath && event.composedPath())
      if (rootRef.current && !eventPath.includes(rootRef.current)) {
        onClose()
      }
    }

    if (expanded) {
      document.addEventListener('click', handleClickAway, { passive: true })
      return () => {
        document.removeEventListener('click', handleClickAway)
      }
    }

    return undefined
  }, [expanded, onClose])

  return (
    <Component className={clsx(classes.root, className)} ref={handleRef} {...other}>
      {children}
    </Component>
  )
})

ProductFlowPopupContainer.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  component: PropTypes.elementType,
}

export default withStyles(styles)(ProductFlowPopupContainer)
