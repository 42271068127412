import * as React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import ButtonBase from '@material-ui/core/ButtonBase'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '../Typography'

export const styles = (theme) => ({
  '@keyframes stroke-animation': {
    to: { strokeDashoffset: 0 },
  },
  root: {},
  outline: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: 'calc(100% + 80px)',
    height: 'calc(100% + 25px)',
    transform: 'translate(-50%, -50%)',
    strokeDasharray: 700,
    strokeDashoffset: 700,
    pointerEvents: 'none',
    '$root:hover &, $selected &': {
      animation: `$stroke-animation 500ms ${theme.transitions.easing.easeInOut} forwards`,
    },
  },
  selected: {},
})

const OutlinedButton = React.forwardRef(function OutlinedButton(props, ref) {
  const { children, classes, className, selected, ...other } = props

  return (
    <Typography
      className={clsx(
        classes.root,
        {
          [classes.selected]: selected,
        },
        className,
      )}
      component={ButtonBase}
      ref={ref}
      {...other}
    >
      <svg className={classes.outline} preserveAspectRatio="none" viewBox="0 0 309 71" fill="none">
        <path
          d="M123.272 9.30291C140.444 9.30291 229.652 9.80454 262.717 17.329C295.781 24.8534 301.773 29.156 306.975 38.4396C311.996 47.4011 297.63 56.5553 284.295 60.7386C253.912 70.2698 213.652 70.1386 179.942 69.9506C143.388 69.7467 106.152 65.8776 72.3111 58.1066C49.0469 52.7643 21.4834 44.5672 7.4181 32.4445C-29.7746 0.38876 104.584 -1.73292 156.336 2.78174"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      {children}
    </Typography>
  )
})

OutlinedButton.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  selected: PropTypes.bool,
}

export default withStyles(styles)(OutlinedButton)
