import * as React from 'react'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import { useUser } from 'api'
import { useApp } from 'containers/App/AppContext'
import { useTranslations } from 'containers/Translations/TranslationsContext'
import * as constants from './constants'

export const AuthHandlersContext = React.createContext({})
export const AuthContext = React.createContext({})

if (process.env.NODE_ENV !== 'production') {
  AuthHandlersContext.displayName = 'AuthHandlersContext'
  AuthContext.displayName = 'AuthContext'
}

export function useAuthHandlers() {
  return React.useContext(AuthHandlersContext)
}

export function useAuth() {
  return React.useContext(AuthContext)
}

export function AuthProvider(props) {
  const { children } = props

  const initialScreenRef = React.useRef(constants.SCREEN_LOGIN)

  const t = useTranslations()

  const [screen, setScreen] = React.useState(initialScreenRef.current)
  // const passwordPattern = '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).*$'
  const passwordPattern = '^.{6,}$'

  const { onUserAuthenticate, onUserRegister, onUserRecoverPassword } = useUser()
  const { isNavMenuOpen, onLoginDialogClose } = useApp()
  const router = useRouter()

  const [infoData, setInfoData] = React.useState()
  const [formitError, setFormitError] = React.useState()

  // Public handlers

  const resetScreen = React.useCallback(() => {
    setScreen(initialScreenRef.current)
  }, [])

  const onScreenGoBack = React.useCallback(() => {
    setScreen((prevScreen) => {
      if (prevScreen.includes(constants.SCREEN_RECOVER_PASSWORD)) {
        return constants.SCREEN_LOGIN_EMAIL
      }
      if (prevScreen.includes(constants.SCREEN_LOGIN)) {
        return constants.SCREEN_LOGIN
      }
      if (prevScreen.includes(constants.SCREEN_REGISTER)) {
        return constants.SCREEN_REGISTER
      }
      return prevScreen
    })
  }, [])

  const onLoginOrRegisterScreenToggle = React.useCallback(() => {
    setInfoData()
    setFormitError()
    setScreen((prev) => {
      return prev.includes(constants.SCREEN_LOGIN)
        ? constants.SCREEN_REGISTER
        : constants.SCREEN_LOGIN
    })
  }, [])

  const onLoginEmailScreenOpen = React.useCallback(() => {
    setInfoData()
    setFormitError()
    setScreen(constants.SCREEN_LOGIN_EMAIL)
  }, [])

  const onRegisterEmailScreenOpen = React.useCallback(() => {
    setInfoData()
    setFormitError()
    setScreen(constants.SCREEN_REGISTER_EMAIL)
  }, [])

  const onRecoverPasswordScreenOpen = React.useCallback(() => {
    setInfoData()
    setFormitError()
    setScreen(constants.SCREEN_RECOVER_PASSWORD)
  }, [])

  const onFormSubmit = React.useCallback(
    async (values, { setSubmitting }) => {
      setSubmitting(true)

      const { email: login, password, rememberMe } = values

      try {
        if (screen === constants.SCREEN_LOGIN_EMAIL) {
          await onUserAuthenticate({ login, password, rememberMe })

          if (isNavMenuOpen || router.pathname !== '/checkout') {
            router.push('/account/')
          }

          setFormitError()
          onLoginDialogClose()
        } else if (screen === constants.SCREEN_REGISTER_EMAIL) {
          await onUserRegister(values)
          await onUserAuthenticate({ login, password, rememberMe })

          if (isNavMenuOpen || router.pathname !== '/checkout') {
            router.push('/account/')
          }

          setFormitError()
          onLoginDialogClose()
        } else {
          const data = await onUserRecoverPassword(values)

          setInfoData(data)
        }
      } catch (error) {
        const { errors = {} } = error.data

        setFormitError(Object.keys(errors).reduce((a, b) => a.concat(errors[b]), []))
      }

      setSubmitting(false)
    },
    [
      screen,
      onUserAuthenticate,
      isNavMenuOpen,
      router,
      onLoginDialogClose,
      onUserRegister,
      onUserRecoverPassword,
    ],
  )

  const onLoginWithFacebook = React.useCallback(
    async (facebook) => {
      await onUserAuthenticate({ facebook })
    },
    [onUserAuthenticate],
  )

  const onRegisterWithFacebook = React.useCallback(() => {
    console.log('onRegisterWithFacebook') // eslint-disable-line no-console
  }, [])

  const onLoginWithGoogle = React.useCallback(
    async (res) => {
      await onUserAuthenticate({
        google: {
          code: res,
          redirectUri: `${window.location.protocol}//${window.location.host}`,
        },
      })
    },
    [onUserAuthenticate],
  )

  const onRegisterWithGoogle = React.useCallback(() => {
    console.log('onRegisterWithGoogle') // eslint-disable-line no-console
  }, [])

  const onSocialLoginFailure = React.useCallback(
    (error) => {
      const messages = []

      if (error.message) {
        messages.push(error.message)
      } else {
        messages.push(...Object.values(error))
      }

      // TODO: Refactor when all messages are known
      const popupMessage = messages.findIndex((m) => m === 'popup_closed_by_user')

      if (popupMessage >= 0) {
        messages[popupMessage] = t('Web.PopupClosedByUser')
      }

      return messages
    },
    [t],
  )

  // Memoize handlers context separately so that one can subscribe
  // to them without re-rendering on state updates.
  const authHandlersContext = React.useMemo(
    () => ({
      onFormSubmit,
      onLoginEmailScreenOpen,
      onLoginOrRegisterScreenToggle,
      onLoginWithFacebook,
      onLoginWithGoogle,
      onRecoverPasswordScreenOpen,
      onRegisterEmailScreenOpen,
      onRegisterWithFacebook,
      onRegisterWithGoogle,
      onScreenGoBack,
      resetScreen,
      onSocialLoginFailure,
    }),
    [
      onFormSubmit,
      onLoginEmailScreenOpen,
      onLoginOrRegisterScreenToggle,
      onLoginWithFacebook,
      onLoginWithGoogle,
      onRecoverPasswordScreenOpen,
      onRegisterEmailScreenOpen,
      onRegisterWithFacebook,
      onRegisterWithGoogle,
      onScreenGoBack,
      resetScreen,
      onSocialLoginFailure,
    ],
  )

  const authContext = {
    screen,
    passwordPattern,
    infoData,
    formitError,
    // Merge in handlers for easy access
    ...authHandlersContext,
  }

  return (
    <AuthHandlersContext.Provider value={authHandlersContext}>
      <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
    </AuthHandlersContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AuthContext
