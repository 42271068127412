import * as React from 'react'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useFormit } from '@oakwood/formit'
import { useRouter } from 'next/router'
import { articleType, extractUniqueArticleTagsByKey } from 'utils'
import ArticleCard from 'containers/ArticleCard'
import Container from 'components/Container'
import MenuItem from 'components/MenuItem'
import Section from 'components/Section'
import TextField from 'components/TextField'
import Typography from 'components/Typography'
import ArticleArchiveSlideshow from './partials/ArticleArchiveSlideshow'

const BREAKPOINT_KEY_UP = 'sm'

export const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    marginTop: -1,
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  header: {
    textAlign: 'center',
  },
  heading: {
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: theme.typography.h3,
  },
  featuredSlideshow: {
    overflow: 'visible',
    marginTop: 'var(--coa-container-spacing)',
    textAlign: 'left',
  },
  main: {
    marginTop: 'var(--coa-section-spacing)',
  },
  actions: {
    ...theme.mixins.verticalRhythm(1),
    ...theme.mixins.contain('md'),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  list: {
    display: 'grid',
    gridGap: theme.spacing(2),
    marginTop: 'var(--coa-section-spacing)',
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: {
      gridGap: theme.spacing(6, 4),
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
  },
  listItem: {},
}))

const FILTER_BY_KEY = 'slug'
// const SORT_BY_KEY = 'datePublished'

function ArticleArchive(props) {
  const { articles, featured, heading, regex } = props
  const classes = useStyles(props)

  const router = useRouter()

  const { getFieldProps, values } = useFormit({
    initialValues: {
      filterBy: 'all',
      sortBy: 'date',
    },
  })

  const articleTags = React.useMemo(
    () => extractUniqueArticleTagsByKey(articles, FILTER_BY_KEY, regex && new RegExp(regex)),
    [articles, regex],
  )

  let visibleArticles = articles
  if (values.filterBy !== 'all') {
    visibleArticles = visibleArticles.filter((a) =>
      a.tags.some((at) => at[FILTER_BY_KEY] === values.filterBy),
    )
  }

  visibleArticles = visibleArticles.filter(
    (ar) =>
      ar?.markets === '' ||
      ar?.markets?.includes(router.locale === 'redirect' ? 'eu' : router.locale),
  )

  return (
    <Section className={classes.root} gutters="padding">
      <Container className={classes.header} component="header" maxWidth="md">
        <Typography className={classes.heading} component="h1" variant="h4">
          {heading}
        </Typography>

        {featured && (
          <ArticleArchiveSlideshow className={classes.featuredSlideshow} articles={featured} />
        )}
      </Container>

      <Container className={classes.main}>
        <div className={classes.actions}>
          <TextField
            {...getFieldProps('filterBy')}
            size="small"
            variant="outlined"
            fullWidth
            select
          >
            <MenuItem value="all">All</MenuItem>
            {articleTags?.map((tag, idx) => (
              <MenuItem key={idx} value={tag.slug}>
                {tag.label}
              </MenuItem>
            ))}
          </TextField>

          <TextField {...getFieldProps('sortBy')} size="small" variant="outlined" select>
            <MenuItem value="date">Date published</MenuItem>
          </TextField>
        </div>

        <div className={classes.list}>
          {visibleArticles.map((article, idx) => (
            <ArticleCard key={idx} className={classes.listItem} article={article} />
          ))}
        </div>
      </Container>
    </Section>
  )
}

ArticleArchive.propTypes = {
  articles: PropTypes.arrayOf(articleType).isRequired,
  featured: PropTypes.arrayOf(articleType),
  heading: PropTypes.string.isRequired,
  regex: PropTypes.string,
}

export default ArticleArchive
