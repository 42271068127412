import * as React from 'react'
import createSvgIcon from '../utils/createSvgIcon'

export default createSvgIcon(
  <>
    <g
      transform="translate(0.000000,147.000000) scale(0.100000,-0.100000)"
      fill="#FFFFFF"
      stroke="none"
    >
      <path d="M205 1418 c-3 -7 -6 -60 -7 -118 l-3 -105 -49 -6 c-70 -8 -96 -40 -96 -120 0 -54 3 -64 29 -90 29 -29 30 -29 151 -29 121 0 122 0 151 29 26 26 29 36 29 90 0 80 -26 112 -96 120 l-49 6 -5 115 c-5 110 -6 115 -28 118 -12 2 -24 -3 -27 -10z" />
      <path d="M715 1418 c-3 -7 -6 -202 -7 -433 l-3 -420 -49 -6 c-70 -8 -96 -40 -96 -120 0 -54 3 -64 29 -90 29 -29 30 -29 151 -29 121 0 122 0 151 29 26 26 29 36 29 90 0 80 -26 112 -96 120 l-49 6 -5 430 -5 430 -23 3 c-12 2 -24 -3 -27 -10z" />
      <path d="M205 908 c-3 -7 -4 -202 -3 -433 l3 -420 25 0 25 0 0 430 0 430 -23 3 c-12 2 -24 -3 -27 -10z" />
      <path d="M715 278 c-3 -8 -4 -61 -3 -119 3 -104 3 -104 28 -104 l25 0 0 115 c0 112 -1 115 -23 118 -12 2 -24 -3 -27 -10z" />
    </g>
  </>,
  'Filter',
  '0 0 96 96',
)
