/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import * as React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Image from 'next/image'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useEmblaCarousel } from 'embla-carousel/react'
import Typography from 'components/Typography'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  emblaViewport: {
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 2),
    },
  },
  emblaWrapper: {
    display: 'flex',
    alignItems: 'center',
    // gap: theme.spacing(2),
    // [theme.breakpoints.between('sm', 'md')]: {
    //   gap: theme.spacing(4),
    // },
    // [theme.breakpoints.up('md')]: {
    //   gap: theme.spacing(4),
    // },
  },
  emblaSlide: {
    position: 'relative',
    flex: '0 0 auto',
    flexShrink: 0,
  },
  concern: {
    width: 'fit-content',
    minWidth: '124px',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '72px',
      padding: theme.spacing(1),
      gap: theme.spacing(0),
    },
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    gap: theme.spacing(1),
    padding: theme.spacing(1),
    border: '1px solid transparent',
    '&:hover': {
      opacity: 0.8,
    },
  },
  selected: {
    border: `1px solid ${theme.palette.common.black}`,
    backgroundColor: theme.palette.common.white,
  },
  allProducts: {
    width: '100%',
    height: '48px',
    display: 'grid',
    placeItems: 'center',
  },
  name: {
    fontWeight: 500,
    color: theme.palette.text.primary,
    textTransform: 'uppercase',
    textAlign: 'center',
    whiteSpace: 'pre',
  },
  nameSlected: {
    color: theme.palette.primary.main,
  },
}))

const Concerns = (props) => {
  const {
    concerns,
    products,
    onSetTransformedProducts,
    setSelectedC,
    items,
    onSetTransformedItems,
  } = props
  const classes = useStyles(props)

  const [selectedConcern, setSelectedConcern] = React.useState(concerns?.[0]?.name || '')
  const [emblaRef, embla] = useEmblaCarousel({
    containScroll: 'trimSnaps',
    dragFree: true,
  })

  const handleConcernClick = React.useCallback(
    (productCodes, name) => {
      const filteredProducts = []
      const filteredItems = []

      if (productCodes.toLowerCase() === 'all') {
        onSetTransformedProducts(products)
        onSetTransformedItems(items)
        setSelectedConcern(name)
        setSelectedC(concerns.find((concern) => concern.name === name))

        return
      }

      productCodes?.split(',')?.forEach((productCode) => {
        filteredProducts.push(
          products.find((prod) => prod?.productCode.trim() === productCode.trim()),
        )

        if (items && items.length > 0) {
          filteredItems.push(items?.find((item) => item.productCode === productCode.trim()))
        }
      })

      setSelectedConcern(name)
      setSelectedC(concerns.find((concern) => concern.name === name))
      onSetTransformedProducts(filteredProducts)
      onSetTransformedItems(filteredItems)
    },
    [concerns, items, onSetTransformedItems, onSetTransformedProducts, products, setSelectedC],
  )

  React.useEffect(() => {
    if (!embla) return
    embla.reInit()
  }, [embla])

  return (
    <section className={classes.root}>
      <div className={classes.emblaViewport} ref={emblaRef}>
        <div className={classes.emblaWrapper}>
          {concerns.map((concern) => {
            const { name, imageUrl, productCodes } = concern

            return (
              <div className={classes.emblaSlide} key={name}>
                <div
                  className={clsx(classes.concern, {
                    [classes.selected]: selectedConcern === name,
                  })}
                  onClick={() => handleConcernClick(productCodes, name)}
                >
                  {imageUrl && (
                    <Image src={imageUrl} width={48} height={48} alt={name} objectFit="contain" />
                  )}
                  <Typography
                    variant="caption2"
                    className={clsx(classes.name, {
                      [classes.nameSlected]: selectedConcern === name,
                    })}
                  >
                    {name}
                  </Typography>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

Concerns.propTypes = {
  concerns: PropTypes.array,
  products: PropTypes.array,
  onSetTransformedProducts: PropTypes.func,
  setSelectedC: PropTypes.func,
  items: PropTypes.array,
  onSetTransformedItems: PropTypes.func,
}

export default Concerns
