import * as React from 'react'
import PropTypes from 'prop-types'
import { useGlobal } from 'api'
import Product2Component from './Product2'

const Product2 = (props) => {
  const { products } = useGlobal()

  const product = products?.find((p) => p.productCode === props.productCode)

  const packageShot = product?.imageUrl || ''

  const transformProps = {
    ...props,
    product,
    packageShot,
    entries: props.entries.map((entry) => ({
      ...entry,
      imageUrl: entry.imageUrl.filename,
    })),
  }

  return <Product2Component {...transformProps} />
}

Product2.propTypes = {
  entries: PropTypes.array,
  productCode: PropTypes.string,
}

export default Product2
