import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import getConfig from 'utils/getConfig'

const { publicRuntimeConfig } = getConfig()

export const gtmFormatProduct = (product, isReceipt = false) => {
  return {
    name: product.name || product.productVariantName,
    id: product.variantId || product.productVariantId,
    price: (product.priceFrom || product.orderPrice || 0).toFixed(2),
    brand: 'Källa',
    category: '',
    variant: '',
    qty: isReceipt ? product.qtyReserved : product.qtyRequested,
  }
}

export const gtmFormatReceiptProduct = (product) => gtmFormatProduct(product, true)

export const gtm = (data) => {
  if (typeof window !== 'undefined') {
    if (
      process.env.NODE_ENV === 'production' &&
      publicRuntimeConfig.EXTERNAL_ADDONS !== 'disable'
    ) {
      window.dataLayer = window.dataLayer || []

      window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
      window.dataLayer.push(data)
    } else {
      // eslint-disable-next-line no-console
      console.log(':: GTM DISABLED ::')
      // console.log('GTM::', JSON.stringify(data))
    }
  }
}

export const useGtmTracker = (event, data) => {
  const memoizedData = React.useMemo(() => (data ? JSON.stringify(data) : false), [data])

  useEffect(() => {
    if (event && memoizedData && publicRuntimeConfig.EXTERNAL_ADDONS !== 'disable') {
      gtm({
        event,
        ...JSON.parse(memoizedData),
      })
    }
  }, [event, memoizedData])
}

const GtmTracker = (props) => {
  const { event, data } = props

  useGtmTracker(event, data)

  return null
}

GtmTracker.propTypes = {
  event: PropTypes.string,
  data: PropTypes.object,
}

GtmTracker.uiName = 'GtmTracker'

export default GtmTracker
