import * as React from 'react'
import { Formit, Form } from '@oakwood/formit'
import PropTypes from 'prop-types'
import Alert from '@material-ui/lab/Alert'
import { useGlobal, useUser } from 'api'
import getApiClient from 'utils/apiClient'
import ArrowNextIcon from 'components/icons/ArrowNext'
import Checkbox from 'components/Checkbox'
import FormControlLabel from 'components/FormControlLabel'
import IconButton from 'components/IconButton'
import Link from 'components/Link'
import { useProductFlowControl } from 'containers/ProductFlowControl'
import TextField from 'components/TextField'
import Typography from 'components/Typography'
import { useTranslations } from 'containers/Translations/TranslationsContext'

const ProductFlowNotification = React.forwardRef(function ProductFlowNotification(props, ref) {
  const { settings } = useGlobal()
  const [error, setError] = React.useState()
  const [isSubscribed, setIsSubscribed] = React.useState(false)

  const { product } = useProductFlowControl()

  const { toggleText = false, className } = props

  const { isAuthenticated, email } = useUser()

  const apiClient = getApiClient()

  const handleSubmit = React.useCallback(
    async (values, { setSubmitting, setStatus }) => {
      setSubmitting(true)

      const params = {
        product_variant_notification: {
          email: values.email,
          product_variant_id: product?.variantId,
          type: 'out-of-stock',
        },
      }

      try {
        const data = await apiClient.createNotification(params)
        if (data) {
          setStatus('subscribed')
          setIsSubscribed(true)
        }
      } catch (err) {
        setError(err)
      }

      setSubmitting(false)
    },
    [apiClient, product?.variantId],
  )

  React.useEffect(() => {
    if (isAuthenticated) {
      const checkUser = async () => {
        try {
          const data = await apiClient.getNotification(email, product?.variantId, 'out-of-stock')

          if (data && data.length > 0) setIsSubscribed(true)
        } catch (err) {
          console.error(err)
        }
      }
      checkUser()
    }
  }, [apiClient, email, isAuthenticated, product?.variantId])

  const t = useTranslations()

  return (
    <Formit
      validationErrors={{
        email: t('Web.ProductFlowNotification.EmailValid'),
      }}
      initialValues={{
        email: '',
      }}
      W
      initialStatus="unsubscribed"
      onSubmit={handleSubmit}
    >
      <Form ref={ref} className={className}>
        {({ getFieldMeta, getFieldProps, isSubmitting, status }) =>
          !isSubscribed || status !== 'subscribed' ? (
            <>
              {!toggleText && (
                <Typography>{t('Web.ProductFlowNotification.SignUpText')}</Typography>
              )}

              <TextField
                {...getFieldMeta('email')}
                {...getFieldProps('email')}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      size="small"
                      type="submit"
                      disabled={isSubmitting}
                      aria-label="Submit"
                      style={{ justifyContent: 'stretch', minWidth: 100 }}
                    >
                      <ArrowNextIcon style={{ flexGrow: 1 }} />
                    </IconButton>
                  ),
                }}
                label={t('Web.ProductFlowNotification.Email.Label')}
                margin="normal"
                type="email"
                variant="filled"
                disabled={isSubmitting}
                fullWidth
                required
                id="kalla-productFlowNotification-form-email" // Makes `label` and `helperText` accessible for screen readers.
              />

              <FormControlLabel
                control={<Checkbox required />}
                disabled={isSubmitting}
                label={
                  <>
                    {t('Web.ProductFlowNotification.TermsConditions.Link.Part1')}{' '}
                    <Link
                      href={settings.termsPageUrl}
                      target="_blank"
                      underline="always"
                      variant="inherit"
                    >
                      {t('Web.ProductFlowNotification.TermsConditions.Link.Part2')}
                    </Link>
                    .
                  </>
                }
              />
              {error && (
                <Alert severity="error">
                  {error?.data?.errors &&
                    Object.values(error?.data?.errors)?.map((i, idx) => (
                      <div key={idx}>{t(`${i}`)}</div>
                    ))}
                </Alert>
              )}
            </>
          ) : (
            <>
              <Typography component="p" variant="h5" gutterBottom>
                {t('Web.ProductFlowNotification.Subscription.Note1')}
              </Typography>

              <Typography variant="body1">
                {t('Web.ProductFlowNotification.Subscription.Note2')}
              </Typography>
            </>
          )
        }
      </Form>
    </Formit>
  )
})

ProductFlowNotification.propTypes = {
  toggleText: PropTypes.bool,
  className: PropTypes.string,
}

export default ProductFlowNotification
