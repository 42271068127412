import * as React from 'react'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Button from 'components/Button'
import OutlinedButton from 'components/OutlinedButton'
import Typography from 'components/Typography'
import { useTranslations } from 'containers/Translations/TranslationsContext'

const BREAKPOINT_KEY_UP = 'sm'

export const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.mixins.verticalBorders(theme.palette.divider),
    marginTop: -1,
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.up('md')]: {
      ...theme.mixins.horizontalBorders(theme.palette.divider),
      display: 'grid',
      gridTemplateColumns: '1fr 2fr',
    },
  },
  block: {
    ...theme.mixins.verticalRhythm(3, '*:not([aria-hidden])'),
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexGrow: 1,
    overflow: 'hidden',
    padding: 'var(--coa-section-spacing) var(--coa-container-spacing)',
  },
  blockContent: {},
  backgroundMediaContainer: {
    ...theme.mixins.absolute(0),
    zIndex: -1,
  },
  backgroundMedia: {
    height: '100%',
    objectfit: 'cover',
  },
  text: {
    position: 'relative',
  },
  categoryPreamble: theme.mixins.gutters(4),
  filterContainer: {
    textAlign: 'center',
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: theme.mixins.contain(400),
  },
  buttonList: {
    ...theme.mixins.verticalRhythm(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: theme.spacing(4, 0, 5),
  },
  filterButton: {
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: theme.typography.h4,
  },
}))

export const CATEGORIES = [
  {
    label: 'Web.Quiz.GeneralGutHealth',
    slug: 'gut-health',
  },
  {
    label: 'Web.Quiz.LowEnergy',
    slug: 'low-energy',
  },
  {
    label: 'Web.Quiz.Stress',
    slug: 'stress',
  },
  {
    label: 'Web.Quiz.DigestiveDiscomfort',
    slug: 'digestive-discomfort',
  },
  {
    label: 'Web.Quiz.FrequentSickDays',
    slug: 'frequent-sick-days',
  },
]

function Quiz(props) {
  const {
    ctaType = 'outlined',
    ctaButtonSize = 'large',
    ctaButtonFullRow = true,
    quizIntro,
    quizButtonLabel,
  } = props
  const classes = useStyles(props)

  const router = useRouter()

  const [selected, setSelected] = React.useState([])
  const handleChange = React.useCallback((event) => {
    const { value } = event.currentTarget

    setSelected((prevValue) => {
      const newValue = Array.isArray(prevValue) ? [...prevValue] : []
      const itemIndex = newValue.indexOf(value)

      if (itemIndex === -1) {
        newValue.push(value)
      } else {
        newValue.splice(itemIndex, 1)
      }

      return newValue
    })
  }, [])

  const handleSubmit = React.useCallback(() => {
    const params = new URLSearchParams()
    selected.forEach((term) => {
      params.append('term', term)
    })
    router.push(`/concerns?${params.toString()}`)
  }, [router, selected])

  const t = useTranslations()

  let cType = 'outlined'

  if (ctaType.includes('outlined')) {
    cType = 'outlined'
  } else if (ctaType.includes('contained')) {
    cType = 'contained'
  } else {
    cType = ctaType
  }

  return (
    <div className={classes.block}>
      <div className={classes.filterContainer}>
        <Typography className={classes.categoryPreamble}>{quizIntro}</Typography>

        <div className={classes.buttonList}>
          {CATEGORIES?.map((category, idx) => (
            <OutlinedButton
              key={idx}
              className={classes.filterButton}
              selected={selected.includes(category.label)}
              onClick={handleChange}
              value={category.label}
              variant="h5"
            >
              {t(category.label)}
            </OutlinedButton>
          ))}
        </div>

        <Button
          onClick={handleSubmit}
          className={classes.cta}
          variant={cType}
          size={ctaButtonSize}
          fullWidth={ctaButtonFullRow}
        >
          {quizButtonLabel}
        </Button>
      </div>
    </div>
  )
}

Quiz.propTypes = {
  ctaType: PropTypes.string,
  ctaButtonSize: PropTypes.string,
  ctaButtonFullRow: PropTypes.bool,
  quizIntro: PropTypes.string,
  quizButtonLabel: PropTypes.string,
}

export default Quiz
