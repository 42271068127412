/* eslint-disable import/no-cycle */
import * as React from 'react'
import getConfig from 'next/config'
import { formatters, getApiClient } from 'utils'
import StoryblokService from 'utils/storyblok-service'

export { default as CheckoutContext } from './CheckoutContext'
export * from './CheckoutContext'

export { default as GlobalContext } from './GlobalContext'
export * from './GlobalContext'

export { default as UserContext } from './UserContext'
export * from './UserContext'

export const onNewsletterSignUp = async (values) => {
  const apiClient = getApiClient()

  const { email } = values

  return apiClient.newsletter(email)
}

export const useReviews = (productCodes, perPage = 3) => {
  const apiClient = getApiClient()

  const [page, setPage] = React.useState(1)
  const [reviews, setReviews] = React.useState([])
  const [totalPages, setTotalPages] = React.useState(0)
  const [totalReviews, setTotalReviews] = React.useState(1)
  const [totalScore, setTotalScore] = React.useState(1)

  const [productCode, setProductCode] = React.useState()

  React.useEffect(() => {
    const reviewParams = {
      page,
      pageCount: perPage,
      productCodes,
    }

    const fetcher = async () => {
      try {
        const response = await apiClient.getReviews(reviewParams)
        setProductCode(response)
      } catch (err) {
        console.error(err)
      }
    }

    fetcher()
  }, [apiClient, page, perPage, productCodes])

  React.useEffect(() => {
    if (productCode) {
      const allReviews = []
      allReviews.push((productCode?.reviews ?? []).map(formatters.formatReview))

      setTotalPages(productCode?.pageCount)
      setTotalReviews(productCode?.totalReviewCount)
      setTotalScore(productCode?.score ?? 0)
      setReviews(allReviews.flat())
    }
  }, [perPage, productCode])

  const onChangePage = React.useCallback((index) => {
    setPage(index)
  }, [])

  return {
    reviews: reviews || [],
    page: page || 1,
    perPage,
    totalPages: totalPages || 1,
    totalReviews: totalReviews || 0,
    totalScore: totalScore || 0,
    onChangePage,
  }
}

export const submitProductReview = async (values) => {
  const response = await fetch('/api/yotpo/create', {
    method: 'POST',
    body: JSON.stringify(values),
  })
  const result = await response.json()

  return result
}

export const fetchSearchResults = async (searchTerm) => {
  const { publicRuntimeConfig } = getConfig()

  const results = await StoryblokService.get(`cdn/stories/`, {
    search_term: searchTerm,
    version: publicRuntimeConfig.STORYBLOK_ENV,
    filter_query: {
      component: { not_in: 'link,author,settings' },
      excludeFromSearchResults: { in: false },
    },
  })

  return results?.data?.stories.map(formatters.formatSearchResults)
}
