import PropTypes from 'prop-types'
import productType from './productType'

const orderItemType = PropTypes.shape({
  basketItemId: PropTypes.number,
  id: PropTypes.number,
  lineType: PropTypes.string,
  orderCurrencySign: PropTypes.string,
  orderDisplayPrice: PropTypes.string,
  orderDisplayPriceTotal: PropTypes.string,
  orderPrice: PropTypes.number,
  defaultPrice: PropTypes.number,
  orderPriceTotal: PropTypes.number,
  product: productType,
  qty: PropTypes.number,
})

export default orderItemType
