import * as React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Media from '@oakwood/oui/Media'
import MediaReveal from '@oakwood/oui/MediaReveal'
import { useEmblaCarousel } from 'embla-carousel/react'
import MediaLoader from '@oakwood/oui/MediaLoader'
import { ASPECT_RATIOS } from 'utils/constants'
import { productType } from 'utils'
import ProductDetails from 'containers/ProductDetails'
import ProductFlowChoices from 'containers/ProductFlowChoices'
import ProductFlowControl from 'containers/ProductFlowControl'
import ProductFlowCta from 'containers/ProductFlowCta'
import ProductFlowCtaDecorator from 'containers/ProductFlowCtaDecorator'
import ProductFlowPopup from 'containers/ProductFlowPopup'
import ProductFlowPopupContainer from 'containers/ProductFlowPopupContainer'
import RouterLink from 'containers/RouterLink'
import Button from 'components/Button'
import { useTranslations } from 'containers/Translations/TranslationsContext'
import Tag from 'components/Tag'
import Typography from 'components/Typography'
import LinkArrow from 'components/LinkArrow'
import ArrowBackIos from 'components/icons/ArrowBackIos'
import ArrowForwardIos from 'components/icons/ArrowForwardIos'
import IconButton from 'components/IconButton'

const BREAKPOINT_KEY_UP = 'sm'

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    gridGap: theme.spacing(3),
  },
  mediaContainer: {
    ...theme.mixins.contain(160),
  },
  media: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
  heading: {
    height: 24,
    ...theme.typography.subtitle1,
    ...theme.typography.h5,
  },
  articleText: {
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: {
      WebkitLineClamp: 6,
      height: '9.90em',
      lineHeight: 1.65,
    },
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    verticalAlign: 'text-top',
  },
  arrowLearnMore: {
    marginRight: 96,
  },
  mediaArea: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    aspectRatio: '0.666',
  },
  detailsArea: {
    padding: theme.spacing(4, 'var(--coa-container-spacing)'),
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: {
      padding: theme.spacing(6, 'var(--coa-container-spacing)'),
    },
    '& > *': {
      marginBottom: theme.spacing(3),
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
  emblaRoot: {
    height: '100%',
  },
  emblaWrapper: {
    display: 'flex',
    alignContent: 'center',
    height: '100%',
  },
  slideContainer: {
    // ...theme.mixins.contain(300),
    position: 'relative',
    height: '100%',
  },
  emblaSlide: {
    position: 'relative',
    flex: '0 0 100%',
    flexShrink: 0,
    height: '100%',

    '& > a': {
      height: '100%',
      display: 'block',
    },
  },
  navigationButton: {
    position: 'absolute',
    zIndex: 9,
    bottom: '48%',
    color: '#808080',
  },
  navigationPrev: {
    left: 12,
  },
  navigationNext: {
    right: 12,
  },
  attributeLabel: {
    color: theme.palette.common.white,
    width: 156,
    display: 'grid',
    placeItems: 'center',
    padding: theme.spacing(0.75, 0),
    textTransform: 'uppercase',
    fontWeight: 500,
  },
  attributeContainer: {
    position: 'absolute',
    zIndex: 5,
    top: 12,
    left: 12,
  },
}))

/**
 * The `ProductCard` is a convenience wrapper for the most common cases.
 */
const ProductCard = React.forwardRef(function ProductCard(props, ref) {
  const {
    className,
    ctaType = 'link',
    hideDescription,
    onbuttonDescription,
    hideMedia,
    product,
    showCustom4,
    showCustom5,
    showLearnMoreDescr,
    imageTag,
    selectionType,
    showPriceReview = false,
    showFromPrice = true,
    showReview = true,
    entryUrl,
    priceLabel,
    attributeLabel,
    attributeColor,
    useBasePrices,
    ...other
  } = props
  const classes = useStyles(props)
  const t = useTranslations()
  const [selectedPrice, setSelectedPrice] = React.useState({ lineType: 'R' })

  const [emblaRef, embla] = useEmblaCarousel({
    align: 'start',
    containScroll: 'trimSnaps',
  })

  const handlePrev = React.useCallback(() => embla && embla.scrollPrev(), [embla])
  const handleNext = React.useCallback(() => embla && embla.scrollNext(), [embla])

  const handleMediaLoaded = React.useCallback(() => {
    embla?.reInit() // Fixes unresponsive desktop bug
  }, [embla])

  let selectionTypeToUse = selectionType

  if (product?.prices?.length < 2) {
    selectionTypeToUse = 'onbutton'
  }

  return (
    <>
      {product && (
        <article className={clsx(classes.root, className)} ref={ref} {...other}>
          {showCustom4 && (
            <Typography className={classes.heading} paragraph>
              {product.custom4}
            </Typography>
          )}

          {!hideMedia &&
            (!imageTag ? (
              <Tag
                component="a"
                styleRoot={false}
                href={entryUrl || product?.href || '#'}
                aria-label={`${product?.name || 'Product'} image link `}
              >
                <MediaReveal className={classes.mediaContainer} {...ASPECT_RATIOS.product}>
                  <Media
                    className={classes.media}
                    src={product?.imageUrl}
                    alt={`${product?.name} Hero`}
                  />
                </MediaReveal>
              </Tag>
            ) : (
              <div className={classes.mediaArea}>
                <div className={classes.emblaRoot} ref={emblaRef}>
                  <MediaLoader className={classes.emblaWrapper} onLoaded={handleMediaLoaded}>
                    {product?.imagesData
                      ?.filter((image) => image.tag === `${imageTag}_${selectedPrice.lineType}`)
                      .map((image, idx) => (
                        <div key={idx} className={classes.emblaSlide}>
                          <Tag
                            component="a"
                            styleRoot={false}
                            href={product?.href || '#'}
                            aria-label={product?.name || `Product media CTA`}
                          >
                            <MediaReveal className={classes.slideContainer}>
                              <Media
                                className={classes.media}
                                src={image?.imageUrl}
                                alt={`${product?.name} Hero`}
                              />
                            </MediaReveal>
                          </Tag>
                          {product?.custom9 || (attributeLabel && attributeLabel !== '') ? (
                            <div className={classes.attributeContainer}>
                              <div
                                className={classes.attributeLabel}
                                style={{
                                  backgroundColor:
                                    attributeColor || product?.custom9?.split(';')[1] || '#2E2630',
                                }}
                              >
                                {attributeLabel ||
                                  product?.custom9?.split(';')[0] ||
                                  product?.custom9}
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      ))}
                  </MediaLoader>
                </div>

                <IconButton
                  className={clsx(classes.navigationButton, classes.navigationPrev)}
                  onClick={handlePrev}
                  edge="start"
                  aria-label="Previous slide"
                >
                  <ArrowBackIos />
                </IconButton>

                <IconButton
                  className={clsx(classes.navigationButton, classes.navigationNext)}
                  onClick={handleNext}
                  edge="end"
                  aria-label="Next slide"
                >
                  <ArrowForwardIos />
                </IconButton>
              </div>
            ))}

          <ProductDetails hideDescription={hideDescription} product={product} />

          {showCustom5 && (
            <Typography className={classes.articleText} paragraph>
              {product.custom5}
            </Typography>
          )}

          {showLearnMoreDescr && (
            <LinkArrow
              className={classes.arrowLearnMore}
              component={RouterLink}
              href={product.href}
            >
              {t('Web.ProductCard.LearnMore')}
            </LinkArrow>
          )}

          {ctaType === 'link' ? (
            <ProductFlowControl product={product} setSelectedPrice={setSelectedPrice}>
              {showPriceReview ? (
                <ProductFlowCtaDecorator
                  showFromPrice={showFromPrice}
                  showReview={showReview}
                  priceLabel={priceLabel}
                  reviewProductCodes={product.custom8}
                >
                  <Button component={RouterLink} href={product.href} variant="outlined" fullWidth>
                    {t('Web.ProductCard.LearnMore')}
                  </Button>
                </ProductFlowCtaDecorator>
              ) : (
                <Button component={RouterLink} href={product.href} variant="outlined" fullWidth>
                  {t('Web.ProductCard.LearnMore')}
                </Button>
              )}
            </ProductFlowControl>
          ) : (
            <ProductFlowControl product={product} setSelectedPrice={setSelectedPrice}>
              {selectionTypeToUse === 'scrollup' && (
                <ProductFlowPopupContainer>
                  <ProductFlowPopup>
                    <ProductFlowChoices useBasePrices={useBasePrices} />
                  </ProductFlowPopup>

                  {showPriceReview ? (
                    <ProductFlowCtaDecorator
                      showFromPrice={showFromPrice}
                      showReview={showReview}
                      priceLabel={priceLabel}
                      reviewProductCodes={product.custom8}
                    >
                      <ProductFlowCta selectionType={selectionTypeToUse} />
                    </ProductFlowCtaDecorator>
                  ) : (
                    <ProductFlowCta />
                  )}
                </ProductFlowPopupContainer>
              )}
              {selectionTypeToUse === 'grid' && (
                <>
                  <ProductFlowChoices />
                  <ProductFlowCta />
                </>
              )}
              {selectionTypeToUse === 'onbutton' && (
                <>
                  {showPriceReview ? (
                    <ProductFlowCtaDecorator
                      showFromPrice={showFromPrice}
                      showReview={showReview}
                      priceLabel={priceLabel}
                      reviewProductCodes={product.custom8}
                    >
                      <ProductFlowCta
                        selectionType={selectionTypeToUse}
                        onbuttonDescription={onbuttonDescription}
                      />
                    </ProductFlowCtaDecorator>
                  ) : (
                    <ProductFlowCta />
                  )}
                </>
              )}
            </ProductFlowControl>
          )}
        </article>
      )}
    </>
  )
})

ProductCard.propTypes = {
  className: PropTypes.string,
  ctaType: PropTypes.oneOf(['link', 'button', '']),
  hideDescription: PropTypes.bool,
  hideMedia: PropTypes.bool,
  product: productType.isRequired,
  showCustom4: PropTypes.bool,
  showCustom5: PropTypes.bool,
  showLearnMoreDescr: PropTypes.bool,
  imageTag: PropTypes.string,
  selectionType: PropTypes.oneOf(['scrollup', 'grid', 'onbutton', '']),
  showPriceReview: PropTypes.bool,
  showFromPrice: PropTypes.bool,
  showReview: PropTypes.bool,
  entryUrl: PropTypes.string,
  priceLabel: PropTypes.string,
  attributeLabel: PropTypes.string,
  attributeColor: PropTypes.string,
  useBasePrices: PropTypes.bool,
  onbuttonDescription: PropTypes.bool,
}

export default ProductCard
