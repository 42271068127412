import * as React from 'react'
import { createPortal } from 'react-dom'
import clsx from 'clsx'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useRouter } from 'next/router'
import Backdrop from 'components/Backdrop'
import { useTranslations } from 'containers/Translations/TranslationsContext'
import Button from 'components/Button'
import Typography from 'components/Typography'
import { useGlobal } from 'api/GlobalContext/GlobalContext'
import Close from 'components/icons/Close'
import IconButton from 'components/IconButton'

const useStyles = makeStyles((theme) => ({
  popup: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    maxWidth: '100%',
    width: '344px',
    padding: theme.spacing(6, 4),
    [theme.breakpoints.up('sm')]: {
      width: '500px',
      padding: theme.spacing(5, 9),
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(5),
    position: 'relative',
  },
  backdrop: {
    zIndex: theme.zIndex.modal,
  },
  popupButtons: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spacing(2),
    maxWidth: '240px',
  },
  popupSubmitButton: {
    backgroundColor: theme.palette.background.modal,
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: 'rgba(235,228,221, 0.8)',
      color: theme.palette.text.primary,
    },
  },
  title: {
    ...theme.typography.h5,
    fontWeight: 400,
    lineHeight: '25.31px',
    fontSize: '18px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '24px',
      lineHeight: '33.75px',
    },
  },
  description: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '19.69px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '16px',
      lineHeight: '22.5px',
    },
  },
  closeModal: {
    position: 'absolute',
    top: theme.spacing(3),
    right: theme.spacing(3),
    padding: 0,
    '&>span>svg': {
      fill: theme.palette.common.white,
      height: '28px !important',
      width: '28px !important',
    },
    '&:hover': {
      opacity: 0.8,
    },
  },
}))

const AppRedirectModal = React.forwardRef(function Popup(props, ref) {
  const t = useTranslations()
  const classes = useStyles(props)
  const router = useRouter()

  const { onChangeMarket } = useGlobal()

  const handleMarketChange = React.useCallback(async () => {
    await onChangeMarket({ marketCode: 'eu', path: router?.asPath })
  }, [onChangeMarket, router?.asPath])

  return createPortal(
    <Backdrop open className={classes.backdrop} ref={ref}>
      <section className={clsx(classes.popup)}>
        <Typography component="p" variant="h5" className={classes.title} align="center">
          {t('Web.Redirect.Popup.Title')}
        </Typography>

        <IconButton
          className={clsx(classes.closeModal)}
          onClick={handleMarketChange}
          edge="start"
          aria-label="Close modal"
        >
          <Close />
        </IconButton>

        <Typography component="p" variant="caption" className={classes.description} align="center">
          {t('Web.Redirect.Popup.Description')}
        </Typography>

        <div className={classes.popupButtons}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleMarketChange}
            fullWidth
            className={classes.popupSubmitButton}
          >
            {t('Web.Redirect.Popup.CTALabel')}
          </Button>
        </div>
      </section>
    </Backdrop>,
    document.body,
  )
})

export default AppRedirectModal
