export const SITE_FOOTER_ID = 'site-footer'
export const SITE_HEADER_ID = 'site-header'
export const SITE_MAIN_ID = 'main-content'

export const TYPE_IDS = {
  contacts: {
    facebook: 29,
    twitter: 30,
    instagram: 28,
    tiktok: 32,
    phoneNumber: 1,
  },
  consents: {
    newsletter: 10,
    shareDataWithAffiliate: 20,
  },
  userTypes: {
    customer: 1,
    ambassador: 2,
    partner: 3,
    regular: 4,
    system: 5,
  },
}

export const ASPECT_RATIOS = {
  square: {
    width: 1,
    height: 1,
  },
  video: {
    width: 16,
    height: 9,
  },
  // Add project specific ratios below
  article: {
    width: 280,
    height: 376,
  },
  author: {
    width: 106,
    height: 142,
  },
  hero: {
    width: 585,
    height: 771,
  },
  checkoutProduct: {
    width: 160,
    height: 90,
  },
  product: {
    width: 293,
    height: 676,
  },
  productHero: {
    width: 525,
    height: 737,
  },
  productHeroOther: {
    width: 525,
    height: 737,
  },
  smallImage: {
    width: 177,
    height: 100,
  },
  cartReplacementImage: {
    height: 60,
    width: 106,
  },
}

export const MONTHS = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
}
