import * as React from 'react'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'
import clsx from 'clsx'
import { Replay, Pause, Play } from 'components/icons'
import IconButton from 'components/IconButton'

export const useStyles = makeStyles((theme) => ({
  fullSize: {
    height: '100%',
    width: '100%',
  },
  videoBackground: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: '#2E2630',
  },
  videoContainer: {
    position: 'relative',
    width: '100%',
    minHeight: 'calc(100dvh - 47px)',
  },
  backgroundMediaMain: {
    objectFit: 'cover',
    position: 'relative',
    height: '100%',
    aspectRatio: '0.5629',
    cursor: 'pointer',
  },
  controlsIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
    backgroundColor: '#2E2630',
    borderRadius: '50%',
    width: '56px',
    height: '56px',
    pointerEvents: 'none',
    '&:hover': {
      backgroundColor: 'rgb(32, 26, 33)',
      boxShadow: 'none',
    },
  },
  pauseIcon: {
    height: '48px',
    width: '48px',
    '&>*': {
      fill: theme.palette.background.paper,
    },
  },
  playIcon: {
    height: '28px',
    width: '28px',
    marginLeft: '4px',
    '&>*': {
      fill: theme.palette.background.paper,
    },
  },
}))

const Video = (props) => {
  const { videoData, selectedIndex, itemIndex } = props
  const classes = useStyles(props)

  const [videoPaused, setVideoPaused] = React.useState(false)
  const [isVideoEnded, setIsVideoEnded] = React.useState(false)
  const [showPauseButton, setShowPauseButton] = React.useState(false)

  const videoRef = React.useRef(null)

  const replayVideo = React.useCallback(() => {
    const videoElement = videoRef.current
    if (!videoElement) return

    videoElement.currentTime = 0
    videoElement.play()
    setIsVideoEnded(false)
    setVideoPaused(false)
  }, [])

  React.useEffect(() => {
    if (videoRef.current) {
      if (itemIndex === selectedIndex) replayVideo()
      else videoRef?.current?.pause()
    }
  }, [itemIndex, replayVideo, selectedIndex])

  React.useEffect(() => {
    const videoElement = videoRef.current

    const handleVideoEnd = () => {
      setIsVideoEnded(true)
    }

    if (videoData && videoElement) {
      videoElement.addEventListener('ended', handleVideoEnd)
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('ended', handleVideoEnd)
        setIsVideoEnded(false)
      }
    }
  }, [videoData])

  React.useEffect(() => {
    if (videoPaused) videoRef.current?.pause()
  }, [videoPaused])

  const handleVideoControl = React.useCallback(() => {
    const videoElement = videoRef.current
    if (!videoElement) return

    if (isVideoEnded) replayVideo()
    else if (videoPaused) videoElement.play()
    else videoElement.pause()

    setVideoPaused(!videoPaused)
  }, [isVideoEnded, replayVideo, videoPaused])

  const handleMouseEnter = () => {
    if (!isVideoEnded && !videoPaused) {
      setShowPauseButton(true)
    }
  }

  React.useEffect(() => {
    if (showPauseButton && videoRef.current) {
      setTimeout(() => {
        setShowPauseButton(false)
      }, 3000)
    }
  }, [showPauseButton])

  function getVideoFormat(url) {
    const extension = url.split('.').pop()
    switch (extension) {
      case 'mp4':
        return 'video/mp4'
      case 'webm':
        return 'video/webm'
      case 'ogg':
        return 'video/ogg'
      default:
        return ''
    }
  }

  return (
    <div className={clsx(classes.videoContainer, classes.fullSize)}>
      <div className={clsx(classes.fullSize, classes.videoBackground)}>
        {(selectedIndex === itemIndex ||
          selectedIndex + 1 === itemIndex ||
          selectedIndex - 1 === itemIndex) && (
          <video
            className={clsx(classes.backgroundMediaMain)}
            controlsList="nodownload nofullscreen noremoteplayback"
            disablePictureInPicture
            autoPlay={selectedIndex === itemIndex}
            ref={videoRef}
            onClick={handleVideoControl}
            onMouseEnter={handleMouseEnter}
            onMouseMove={handleMouseEnter}
            onMouseLeave={() => setShowPauseButton(false)}
            playsInline
          >
            <source
              className={classes.backgroundMedia}
              src={videoData?.src}
              type={getVideoFormat(videoData?.src)}
            />
            <track kind="captions" />
          </video>
        )}
      </div>
      {showPauseButton && (
        <IconButton edge="start" aria-label="Close item" className={classes.controlsIcon}>
          <Pause className={classes.pauseIcon} />
        </IconButton>
      )}

      {isVideoEnded && (
        <IconButton edge="start" aria-label="Close item" className={classes.controlsIcon}>
          <Replay className={classes.pauseIcon} />
        </IconButton>
      )}

      {videoPaused && !isVideoEnded && (
        <IconButton edge="start" aria-label="Close item" className={classes.controlsIcon}>
          <Play className={classes.playIcon} />
        </IconButton>
      )}
    </div>
  )
}

Video.propTypes = {
  videoData: PropTypes.object,
  selectedIndex: PropTypes.number,
  itemIndex: PropTypes.number,
}

export default Video
