import * as React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useEmblaCarousel } from 'embla-carousel/react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Media from '@oakwood/oui/Media'
import MediaLoader from '@oakwood/oui/MediaLoader'
import MediaReveal from '@oakwood/oui/MediaReveal'
import { ASPECT_RATIOS } from 'utils/constants'
import { productType } from 'utils'
import ProductDetails from 'containers/ProductDetails'
import ProductFlowChoices from 'containers/ProductFlowChoices'
import ProductFlowControl from 'containers/ProductFlowControl'
import ProductFlowCta from 'containers/ProductFlowCta'
import ProductFlowCtaDecorator from 'containers/ProductFlowCtaDecorator'
import Tag from 'components/Tag'
import ArrowPrevIcon from 'components/icons/ArrowPrev'
import ArrowNextIcon from 'components/icons/ArrowNext'
import IconButton from 'components/IconButton'
import Section from 'components/Section'
import GtmTracker, { gtmFormatProduct } from 'containers/Gtm/GtmTracker'
import Typography from 'components/Typography'
import { useTranslations } from 'containers/Translations/TranslationsContext'
import ProductFlowNotification from 'containers/ProductFlowNotification'
import InfoCircle from '../../components/icons/InfoCircle'

const BREAKPOINT_KEY_UP = 'md'

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: -1,
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: {
      ...theme.mixins.horizontalBorders(theme.palette.divider),
      display: 'grid',
      gridTemplateColumns: 'minmax(300px, 1fr) minmax(300px, 500px)',
    },
  },
  mediaArea: {
    position: 'relative',
    overflow: 'hidden',
  },
  detailsArea: {
    padding: theme.spacing(4, 'var(--coa-container-spacing)'),
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: {
      padding: theme.spacing(6, 'var(--coa-container-spacing)'),
    },
    '& > *': {
      marginBottom: theme.spacing(3),
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
  emblaRoot: {
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: {
      ...theme.mixins.absolute(theme.spacing(6), 'var(--coa-container-spacing)'),
      '& *': {
        height: '100%',
      },
    },
  },
  emblaWrapper: {
    display: 'flex',
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: {
      margin: theme.spacing(0, -1),
    },
  },
  emblaSlide: {
    position: 'relative',
    flexShrink: 0,
    width: `calc(100% - ${theme.spacing(8)}px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(50% - ${theme.spacing(4)}px)`,
    },
    [theme.breakpoints.up('md')]: {
      width: 'auto',
      padding: theme.spacing(0, 1),
      '& *': {
        width: 'inherit',
      },
    },
  },
  navigationButton: {
    position: 'absolute',
    zIndex: 9,
    bottom: theme.spacing(3),
    display: 'none',
    minWidth: 100,
    justifyContent: 'stretch',
    transform: 'translateY(50%)',
    '& svg': {
      flexGrow: 1,
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  navigationPrev: {
    left: 'var(--coa-container-spacing)',
  },
  navigationNext: {
    right: 'var(--coa-container-spacing)',
  },
  mediaContainer: {
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: {
      // Disable `MediaReveal` aspect-ratio for desktop
      '&:before': { content: 'none' },
      '& > *': { position: 'static' },
    },
  },
  media: {},
  extraMediaContainer: {
    ...theme.mixins.contain(100),
    display: 'none',
    '@supports (max-width: clamp(1px, 1vw, 9px))': {
      maxWidth: 'clamp(100px, 8vw, 250px)',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  extraMedia: {},
  infoLabelContainer: {
    width: '100%',
    backgroundColor: '#D9C4B4',
    display: 'flex',
    justifyContent: 'flex-start',
    gap: 16,
    padding: theme.spacing(1.5, 2),
    marginBottom: theme.spacing(3),
  },
}))

function ProductHero(props) {
  const {
    className,
    packageShot,
    product,
    hideImage,
    hideDescription,
    refillsImageUrl,
    infoLabel,
    reviewProductCodes,
  } = props
  const classes = useStyles(props)

  const t = useTranslations()

  const [emblaRef, embla] = useEmblaCarousel({
    align: 'center',
    containScroll: 'trimSnaps',
  })

  const [selectedPrice, setSelectedPrice] = React.useState({ lineType: 'R' })

  const handlePrev = React.useCallback(() => embla && embla.scrollPrev(), [embla])
  const handleNext = React.useCallback(() => embla && embla.scrollNext(), [embla])
  const isEmpty = (obj) => Object.keys(obj).length === 0

  const handleMediaLoaded = React.useCallback(() => {
    embla?.reInit() // Fixes unresponsive desktop bug
  }, [embla])

  const stock =
    product?.variants?.find((variant) => variant?.id === product?.variantId)?.outOfStock || false

  return (
    <>
      {product && !isEmpty(product) && (
        <Section className={clsx(classes.root, className)}>
          <GtmTracker
            event="productDetailView"
            data={{
              ecommerce: {
                detail: {
                  actionField: { action: 'Detail' },
                  products: [gtmFormatProduct(product)],
                },
              },
            }}
          />
          <div className={classes.mediaArea}>
            <div className={classes.emblaRoot} ref={emblaRef}>
              <MediaLoader className={classes.emblaWrapper} onLoaded={handleMediaLoaded}>
                {product?.imagesData
                  ?.filter((image) => image.tag === `Hero_${selectedPrice.lineType}`)
                  .map((image, idx) => (
                    <div key={idx} className={classes.emblaSlide}>
                      <img
                        className={classes.mediaContainer}
                        src={image.imageUrl}
                        width={500}
                        height={760}
                        alt={image.description}
                      />
                    </div>
                  ))}
              </MediaLoader>
            </div>

            <IconButton
              className={clsx(classes.navigationButton, classes.navigationPrev)}
              onClick={handlePrev}
              edge="start"
              aria-label="Previous slide"
            >
              <ArrowPrevIcon />
            </IconButton>

            <IconButton
              className={clsx(classes.navigationButton, classes.navigationNext)}
              onClick={handleNext}
              edge="end"
              aria-label="Next slide"
            >
              <ArrowNextIcon />
            </IconButton>
          </div>

          <div className={classes.detailsArea}>
            {packageShot && !hideImage && (
              <Tag
                component="a"
                styleRoot={false}
                href={refillsImageUrl || '#'}
                aria-label={product.name || `Product media CTA`}
              >
                <MediaReveal className={classes.extraMediaContainer} {...ASPECT_RATIOS.product}>
                  <Media className={classes.extraMedia} src={packageShot} />
                </MediaReveal>
              </Tag>
            )}

            <ProductDetails product={product} hideDescription={hideDescription} />
            {!stock ? (
              infoLabel &&
              infoLabel !== '' && (
                <div className={classes.infoLabelContainer}>
                  <InfoCircle />
                  <Typography variant="body2">{infoLabel}</Typography>
                </div>
              )
            ) : (
              <div className={classes.infoLabelContainer}>
                <InfoCircle />
                <Typography variant="body2">{t('Web.Product.OutOfStock')}</Typography>
              </div>
            )}

            <ProductFlowControl product={product} setSelectedPrice={setSelectedPrice}>
              {!stock ? (
                <>
                  <ProductFlowChoices hideDescription={hideDescription} />

                  <ProductFlowCtaDecorator reviewProductCodes={reviewProductCodes}>
                    <ProductFlowCta />
                  </ProductFlowCtaDecorator>
                </>
              ) : (
                <ProductFlowNotification />
              )}
            </ProductFlowControl>
          </div>
        </Section>
      )}
    </>
  )
}

ProductHero.propTypes = {
  className: PropTypes.string,
  packageShot: PropTypes.string,
  product: productType.isRequired,
  hideImage: PropTypes.bool,
  hideDescription: PropTypes.bool,
  refillsImageUrl: PropTypes.string,
  infoLabel: PropTypes.string,
  reviewProductCodes: PropTypes.string,
}

export default ProductHero
