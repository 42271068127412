import * as React from 'react'
import PropTypes from 'prop-types'
import { FormatAddress, formatCurrency } from 'utils/formatters'
import getApiClient from 'utils/apiClient'

const apiClient = getApiClient()

const GlobalContext = React.createContext({})

if (process.env.NODE_ENV !== 'production') {
  GlobalContext.displayName = 'GlobalContext'
}

export function useGlobal() {
  return React.useContext(GlobalContext)
}

export function GlobalProvider(props) {
  const {
    children,
    images,
    menus,
    settings,
    hello: {
      checkoutEnabled,
      loginEnabled,
      myBiomaShowOnWeb,
      maintenanceEnabled,
      language,
      market,
      market: { deliveryCountries } = {},
      client: { markets } = {},
    },
    products,
    quiz,
    popup,
  } = props

  const currencyData = market?.currencies.find((currency) => currency.id === market.currencyId)
  const currencies = markets.map((m) => m.currencies).flat()

  const onGetDefaultCurrency = React.useCallback(
    (marketId) => markets.find((m) => m.id === marketId)?.currencies.find((c) => c.default),
    [markets],
  )

  const onFormatCurrency = React.useCallback(
    (amount) => formatCurrency(amount, currencyData),
    [currencyData],
  )

  const onFormatAddress = React.useCallback((address) => FormatAddress(address), [])

  const onChangeMarket = React.useCallback(async ({ marketCode, path = '' }) => {
    await apiClient.setMarket({ marketCode })

    window.location.href = `${window.location.protocol}//${window.location.host}/${marketCode}${path}`
  }, [])

  const onProductCartImage = React.useCallback(
    (productVariantId, lineType) =>
      products
        .find((product) => product.variantId === productVariantId)
        ?.imagesData.find((image) => image.tag?.toLowerCase() === `cart_${lineType}`.toLowerCase())
        ?.imageUrl,
    [products],
  )

  // Memoize handlers context separately so that one can subscribe
  // to them without re-rendering on state updates.
  const handlersContextValue = React.useMemo(
    () => ({
      onProductCartImage,
      onFormatCurrency,
      onFormatAddress,
      onChangeMarket,
      onGetDefaultCurrency,
    }),
    [onProductCartImage, onFormatCurrency, onFormatAddress, onChangeMarket, onGetDefaultCurrency],
  )

  // Change to `React.useState` if in need of conditionally updating values
  const { current: globalContext } = React.useRef({
    images,
    menus,
    settings,
    market,
    language,
    markets,
    products,
    myBiomaShowOnWeb,
    deliveryCountries,
    currencies,
    currencyData,
    checkoutEnabled,
    loginEnabled,
    maintenanceEnabled,
    quiz,
    popup,
    // Merge in handlers for easy access
    ...handlersContextValue,
  })

  return <GlobalContext.Provider value={globalContext}>{children}</GlobalContext.Provider>
}

GlobalProvider.propTypes = {
  children: PropTypes.node.isRequired,
  images: PropTypes.object,
  menus: PropTypes.object,
  settings: PropTypes.object,
  hello: PropTypes.object,
  products: PropTypes.array,
  quiz: PropTypes.object,
  popup: PropTypes.object,
}

export default React.memo(GlobalContext)
