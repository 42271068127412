import * as React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import makeStyles from '@material-ui/core/styles/makeStyles'
import ButtonBase from '@material-ui/core/ButtonBase'
import Collapse from '@material-ui/core/Collapse'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import dynamic from 'next/dynamic'
import { SITE_HEADER_ID } from 'utils/constants'
import { useScrollbarSize } from 'utils'
import { HeroUnwrapped } from 'blocks/Hero/Hero'
import Section from 'components/Section'
import ProductShowcaseSlide from './partials/ProductShowcaseSlide'

const SLIDE_COLLAPSED_SIZE_MOBILE = 155
const SLIDE_COLLAPSED_SIZE_DESKTOP = 58
const SLIDE_ID_PREFIX = 'product-showcase-slide-'

const Ingredient = dynamic(() => import('blocks/Ingredient/Ingredient'))

export const useStyles = (props) =>
  makeStyles((theme) => ({
    root: {
      '--slide-collapsed-size-mobile': `${SLIDE_COLLAPSED_SIZE_MOBILE}px`,
      '--slide-collapsed-size-desktop': `${SLIDE_COLLAPSED_SIZE_DESKTOP}px`,
      borderTop: `1px solid ${theme.palette.divider}`,
      borderBottom: `1px solid ${theme.palette.divider}`,
      marginTop: -1,
      [theme.breakpoints.up(props.BREAKPOINT_KEY_UP)]: {
        display: 'flex',
      },
    },
    slide: {
      flex: '0 1 var(--slide-collapsed-size-desktop)',
      position: 'relative',
      overflow: 'hidden',
      // Safari won't transition unless transition property is set to "all".
      transition: theme.transitions.create(['all'], {
        duration: theme.transitions.duration.complex,
      }),
      '& + &': {
        borderTop: `1px solid ${theme.palette.divider}`,
      },
      [theme.breakpoints.up(props.BREAKPOINT_KEY_UP)]: {
        '& + &': {
          borderLeft: `1px solid ${theme.palette.divider}`,
          borderTop: 0,
        },
      },
    },
    slideActive: {
      flexGrow: 1,
    },
    slideActiveHero: {
      overflow: 'visible',
    },
    slideContainer: {
      [theme.breakpoints.down(props.BREAKPOINT_KEY_DOWN)]: {
        width: 'auto !important', // Use `!important` instead of a `useMediaQuery` solution.
      },
      [theme.breakpoints.up(props.BREAKPOINT_KEY_UP)]: {
        height: '100% !important', // Use `!important` instead of a `useMediaQuery` solution.
      },
    },
    slideWrapper: {
      [theme.breakpoints.up(props.BREAKPOINT_KEY_UP)]: {
        height: 'inherit',
      },
    },
    slideWrapperInner: {
      height: 'inherit',
    },
    slideButton: {
      ...theme.mixins.absolute(0),
      width: '100%',
      [theme.breakpoints.down('sm')]: { zIndex: 9 },
    },
    slideContent: {
      height: '100%',
    },
    slideHero: {
      padding: 'var(--coa-section-spacing) var(--coa-container-spacing)',
      [theme.breakpoints.up(props.BREAKPOINT_KEY_UP)]: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
      },
    },
    slideHeroMediaContainer: {
      [theme.breakpoints.up(props.BREAKPOINT_KEY_MD)]: {
        position: 'relative',
        top: 0,
        bottom: 0,
      },
    },
    slideProduct: {},
  }))

function ProductShowcase(props) {
  const { slides } = props

  const breakpoints = {
    BREAKPOINT_KEY_DOWN: slides?.length > 7 ? 'sm' : 'xs',
    BREAKPOINT_KEY_UP: slides?.length > 7 ? 'md' : 'sm',
    BREAKPOINT_KEY_MD: slides?.length > 7 ? 'lg' : 'md',
  }

  const classes = useStyles(breakpoints)()

  const scrollbarSize = useScrollbarSize()

  const isBreakpointUp = useMediaQuery((theme) =>
    theme.breakpoints.up(breakpoints.BREAKPOINT_KEY_UP),
  )

  const [activeIdxs, setActiveIdxs] = React.useState([0])
  const [activeIdx, setActiveIdx] = React.useState(0)

  const handleSlideClick = React.useCallback(
    (event, index) => {
      const value = parseInt(event.currentTarget.value, 10) || index

      if (isBreakpointUp) {
        setActiveIdx(value)
      } else {
        setActiveIdxs((prevValue) => {
          const newValue = Array.isArray(prevValue) ? [...prevValue] : []
          const itemIndex = newValue.indexOf(value)

          if (itemIndex === -1) {
            newValue.push(value)
          } else {
            newValue.splice(itemIndex, 1)
          }

          return newValue
        })

        const target = document.getElementById(`${SLIDE_ID_PREFIX}${value}`)
        if (target) {
          const headerHeight = document.getElementById(SITE_HEADER_ID)?.clientHeight || 0
          const top = window.scrollY + target.getBoundingClientRect().top - headerHeight
          window.scrollTo({ top, behavior: 'smooth' })
        }
      }
    },
    [isBreakpointUp],
  )

  const otherWidth = SLIDE_COLLAPSED_SIZE_DESKTOP * (slides?.length - 1) + scrollbarSize
  const slideWidth = `calc(100vw - ${otherWidth}px)`

  const mountedRef = React.useRef(null)
  React.useEffect(() => {
    mountedRef.current = true
    return () => {
      mountedRef.current = false
    }
  }, [])

  React.useEffect(() => {
    if (document) {
      const element = document.getElementById(`${SLIDE_ID_PREFIX}0`)

      if (element) {
        if (activeIdx === 0 || !isBreakpointUp) {
          setTimeout(() => {
            element.classList.add(classes.slideActiveHero)
          }, 500)
        } else {
          element.classList.remove(classes.slideActiveHero)
        }
      }
    }
  }, [activeIdx, activeIdxs, classes.slideActiveHero, isBreakpointUp])

  return (
    <Section data-type="products" className={classes.root}>
      {slides?.map(({ type: slideType, props: slideProps }, idx) => {
        const isActive = isBreakpointUp ? activeIdx === idx : activeIdxs.includes(idx)
        const inert = mountedRef.current && !isActive ? '' : undefined

        return (
          <div
            key={idx}
            className={clsx(classes.slide, {
              [classes.slideActive]: isActive,
              // [classes.slideActiveHero]: isActive && idx === 0,
            })}
            id={`${SLIDE_ID_PREFIX}${idx}`}
          >
            <ButtonBase
              className={classes.slideButton}
              onClick={(e) => handleSlideClick(e, idx)}
              value={idx}
              disabled={isActive && slideType !== 'ingredient'}
              aria-label={`Go to slide ${idx + 1}`}
              id={`${SLIDE_ID_PREFIX}button-${
                slideType === 'ingredient'
                  ? slideProps.ingredient.name.toLowerCase().trim().replaceAll(' ', '_')
                  : idx
              }`}
            />

            <Collapse
              classes={{
                container: classes.slideContainer,
                wrapper: classes.slideWrapper,
                wrapperInner: classes.slideWrapperInner,
              }}
              collapsedHeight={SLIDE_COLLAPSED_SIZE_MOBILE}
              in={isActive}
              inert={inert}
              style={{ width: slideWidth }}
            >
              {slideType === 'hero' && (
                <HeroUnwrapped
                  classes={{
                    root: clsx(classes.slideContent, classes.slideHero),
                    mediaContainer: classes.slideHeroMediaContainer,
                  }}
                  collapsed={!isActive}
                  {...slideProps}
                />
              )}

              {slideType === 'product' && (
                <ProductShowcaseSlide
                  className={clsx(classes.slideContent, classes.slideProduct)}
                  active={isActive}
                  handleSlideClick={handleSlideClick}
                  index={idx}
                  breakpoints={breakpoints}
                  {...slideProps}
                />
              )}
              {slideType === 'ingredient' && (
                <Ingredient
                  className={clsx(classes.slideContent, classes.slideProduct)}
                  active={isActive}
                  handleSlideClick={handleSlideClick}
                  index={idx}
                  breakpoints={breakpoints}
                  {...slideProps}
                />
              )}
            </Collapse>
          </div>
        )
      })}
    </Section>
  )
}

const slideType = PropTypes.shape({
  type: PropTypes.string.isRequired,
  props: PropTypes.object.isRequired, // Let sub-components do nested prop validation.
})

ProductShowcase.propTypes = {
  slides: PropTypes.arrayOf(slideType).isRequired,
  ingredients: PropTypes.array,
}

export default ProductShowcase
