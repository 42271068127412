import * as React from 'react'
import getConfig from 'next/config'
import { formatters } from 'utils'
import StoryblokService from 'utils/storyblok-service'
import LinkArchiveComponent from './LinkArchive'

const LinkArchive = (props) => <LinkArchiveComponent {...props} />

LinkArchive.getInitialProps = async (props, ctx) => {
  const { publicRuntimeConfig } = getConfig()

  const page = ctx?.query?.page || 1
  const folder = 'links'
  const result = await StoryblokService.get(
    `cdn/stories/?starts_with=${folder}/&page=${page}&language=${ctx.locale}&per_page=100`,
    { version: publicRuntimeConfig.STORYBLOK_ENV },
  )

  const articles = result.data.stories.map((article) => formatters.formatLink(article))

  return {
    ...props,
    articles,
  }
}

export default LinkArchive
