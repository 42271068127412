// Based on https://github.com/mui-org/material-ui/blob/master/examples/nextjs/pages/_app.js

import * as React from 'react'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import Error from 'next/error'
import Head from 'next/head'
import Cookies from 'cookies'
// import * as Sentry from '@sentry/react'
// import { Integrations } from '@sentry/tracing'
import { ThemeProvider } from '@material-ui/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import dynamic from 'next/dynamic'
import { CheckoutProvider, GlobalProvider, UserProvider } from 'api'
import { fetchGlobalSettings } from 'utils/storyblok'
import { formatTranslations, formatProducts } from 'utils/formatters'
import getApiClient, { ApiClient } from 'utils/apiClient'
import getConfig from 'utils/getConfig'
import lightTheme from 'utils/theme.light'
import { AppProvider } from 'containers/App/AppContext'
import { AuthProvider } from 'containers/Auth/AuthContext'
import { gtm } from 'containers/Gtm/GtmTracker'
import { TranslationsProvider, useTranslations } from 'containers/Translations/TranslationsContext'
import AppBase from 'containers/App'
import Popup from 'blocks/Popup'

const HelloReload = dynamic(() => import('components/HelloReload'))
const Maintenance = dynamic(() => import('blocks/Maintenance/Maintenance'))
const Ebbot = dynamic(() => import('containers/Ebbot/Ebbot'))

// As of NextJS 9, all global css *must* be imported in pages/_app.js
// https://github.com/zeit/next.js/blob/master/errors/css-global.md

/**
 * Monkey patches React to notify you about avoidable re-renders.
 * Based on: https://github.com/vercel/next.js/blob/canary/examples/with-why-did-you-render/pages/_app.js
 */
// if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'production') {
//   // eslint-disable-next-line global-require
//   const whyDidYouRender = require('@welldone-software/why-did-you-render')
//   whyDidYouRender(React, { trackAllPureComponents: true })
// }

/**
 * Initialize polyfills
 * Based on: https://nextjs.org/docs/basic-features/supported-browsers-features#custom-polyfills
 */
if (typeof window !== 'undefined') {
  /* eslint-disable global-require */
  const smoothscroll = require('smoothscroll-polyfill')
  smoothscroll.polyfill()

  require('wicg-inert')
  /* eslint-enable global-require */
}

const { publicRuntimeConfig } = getConfig()

// Sentry.init({
//   dsn: publicRuntimeConfig.SENTRY_DSN,
//   integrations: [new Integrations.BrowserTracing()],
//   tracesSampleRate: 1.0,
// })

function App(props) {
  const { Component, pageProps, cmsProps, redirect } = props
  const router = useRouter()
  const t = useTranslations()

  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')

    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles)
    }

    // Facebook pixel tracks pageView on init
    const trackPageView = () => {
      setTimeout(() => gtm({ event: 'page_view' }), 150)
    }
    router.events.on('routeChangeComplete', trackPageView)
    return () => {
      router.events.off('routeChangeComplete', trackPageView)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (redirect) {
    return null
  }

  let component

  if (cmsProps?.hello?.maintenanceEnabled) {
    component = (
      <>
        <Head>
          <title>{t('Web.Maintenance.Title')}</title>
        </Head>

        <Maintenance content={t('Web.Maintenance.Content')} hideNewsletter />
      </>
    )
  } else if (Component.displayName === Error.displayName) {
    return (
      <ThemeProvider theme={lightTheme}>
        <Head>
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
          />
        </Head>

        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Component />
      </ThemeProvider>
    )
  } else if (pageProps?.page?.disableAppBase) {
    component = <Component {...pageProps} />
  } else {
    component = (
      <UserProvider>
        <AuthProvider>
          <CheckoutProvider>
            <AppBase>
              <Component {...pageProps} />
              {pageProps?.page?.enablePopup && <Popup {...cmsProps.popup} />}
              {cmsProps?.hello?.ebbotEnabled && cmsProps?.hello?.market?.code?.includes('se') && (
                <Ebbot />
              )}
            </AppBase>
          </CheckoutProvider>
        </AuthProvider>
      </UserProvider>
    )
  }

  return (
    <ThemeProvider theme={lightTheme}>
      <Head>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
        />
      </Head>

      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      {cmsProps.hello ? (
        <GlobalProvider {...cmsProps}>
          <AppProvider>
            <TranslationsProvider strings={cmsProps?.translations}>
              {component}
            </TranslationsProvider>
          </AppProvider>
        </GlobalProvider>
      ) : (
        <HelloReload />
      )}
    </ThemeProvider>
  )
}

const cache = {}
App.getInitialProps = async (props) => {
  const { Component, ctx, router } = props

  let apiClient = typeof window !== 'undefined' ? getApiClient() : null

  let cmsProps = {}

  // In case `getInitialProps` here fails, Next.js will call this method again
  // with `./_error.js` as `Component` and the stack trace as context.
  if (Component.displayName === Error.displayName) {
    const pageProps = await Component.getInitialProps(ctx)

    return {
      cmsProps: {},
      pageProps: {
        ...pageProps,
        page: {
          ...pageProps.page,
          disableAppBase: true,
          enablePopup: false,
        },
      },
      status: pageProps.statusCode,
    }
  }

  const pageProps = {}

  try {
    if (ctx.req) {
      const cookies = new Cookies(ctx.req, ctx.res, {
        secure: process.env.NODE_ENV === 'production',
      })

      apiClient = new ApiClient(cookies)
      cmsProps = await fetchGlobalSettings(ctx)
    }

    let url

    apiClient.readCookies()

    const domain =
      process.env.NODE_ENV === 'production'
        ? publicRuntimeConfig.TINYLINK_PREFIX
        : 'http://localhost:3000'

    const market = router.locale !== `redirect` && router.locale
    const marketAsPath = market ? `/${router.locale}` : ``
    const selectedMarket = apiClient.selectedMarket
    const selectedMarketAsPath = selectedMarket ? `/${selectedMarket}` : ``
    const origUrl = `${domain}${marketAsPath}${ctx.asPath.split('?')[0]}`.replace(/\/$/, '')

    const routingOpts = {
      url: `${domain}${marketAsPath}${ctx.asPath.split('?')[0]}`.replace(/\/$/, ''),
      urlLanguage: false,
      routing: true,
    }

    if (ctx.req) {
      routingOpts.ipAddress = ctx.req?.headers?.['x-real-ip'] || ''
    }

    const appInit = async (opts) => {
      const init = await apiClient.init(opts)
      if (init.url) {
        url = init.url
      }
    }

    if (!apiClient.sessionToken) {
      await appInit(routingOpts)
    } else {
      try {
        let decoded

        if (router.locale === 'redirect' && selectedMarket) {
          decoded = await apiClient.decodeShortLink({
            ...routingOpts,
            routing: false,
            url: `${domain}${selectedMarketAsPath}${ctx.asPath.split('?')[0]}`.replace(/\/$/, ''),
          })
        } else {
          decoded = await apiClient.decodeShortLink(routingOpts)
        }
        url = decoded.url
      } catch (error) {
        // If token error reinitialise app
        await appInit(routingOpts)
      }
    }

    if (apiClient?.cookies) {
      apiClient?.cookies.set('MARKET', router.locale === 'redirect' ? 'eu' : router.locale, {
        httpOnly: false,
        secure: process.env.NODE_ENV === 'production',
        domain: publicRuntimeConfig.COOKIE_DOMAIN,
        sameSite: publicRuntimeConfig.COOKIE_SAMESITE,
      })
    }

    if (url && url !== origUrl) {
      if (ctx.asPath.indexOf('?') !== -1) {
        url = `${url}?${ctx.asPath?.split('?', 2)[1]}`
      }

      if (ctx.res) {
        // On the server, we'll use an HTTP response to
        // redirect with the status code of our choice.
        // 307 is for temporary redirects.
        ctx.res.writeHead(307, { Location: url })
        ctx.res.end()
      } else {
        // On the client, we'll use the Router-object
        // from the 'next/router' module.
        router.replace(url)
      }
      // Return an empty object,
      // otherwise Next.js will throw an error
      return { redirect: true }
    }

    if (ctx.res) {
      ctx.res.setHeader('Cache-Control', 'public, s-maxage=600, stale-while-revalidate=600')
    }

    const differentMarket = cache.hello?.market?.code !== ctx.locale.replace('redirect', 'eu')

    const shouldFetchHello = !cache.hello || differentMarket

    if (shouldFetchHello) {
      cmsProps.hello = await apiClient.hello()
      cache.hello = cmsProps.hello
    } else {
      cmsProps.hello = cache.hello
    }

    let timestamp = null

    if (apiClient?.cookies) {
      timestamp = apiClient?.cookies.get('t_timestamp')
    }

    const setTimestamp = (value) => {
      if (apiClient?.cookies) {
        apiClient?.cookies?.set('t_timestamp', value, {
          httpOnly: false,
          secure: process.env.NODE_ENV === 'production',
          domain: publicRuntimeConfig.COOKIE_DOMAIN,
          sameSite: publicRuntimeConfig.COOKIE_SAMESITE,
          expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365),
        })
      }
    }

    if (!timestamp) {
      setTimestamp(cmsProps.hello?.languageLastUpdated)
    }
    const timestampDate = new Date(timestamp)

    if (timestampDate < new Date(cmsProps.hello?.languageLastUpdated)) {
      cache.translations = null
    }

    const shouldFetchTranslations = !cache.translations || differentMarket

    if (shouldFetchTranslations) {
      cmsProps.translations = await apiClient.translations()
      cache.translations = cmsProps.translations
      setTimestamp(cmsProps.hello?.languageLastUpdated)
    } else {
      cmsProps.translations = cache.translations
    }

    cmsProps.products = formatProducts(cmsProps?.hello?.products)
    cmsProps.translations = formatTranslations(cmsProps?.translations)
  } catch (error) {
    console.error('error', error)
  }

  return {
    cmsProps,
    pageProps,
  }
}

App.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired,
  cmsProps: PropTypes.object.isRequired,
  redirect: PropTypes.bool,
}

export default App
