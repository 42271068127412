// @inheritedComponent Link

import * as React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import withStyles from '@material-ui/core/styles/withStyles'
import ArrowNextIcon from '../icons/ArrowNext'
import Link from '../Link'

export const styles = (theme) => ({
  root: {
    display: 'flex',
    maxWidth: 400,
  },
  arrow: {
    flexGrow: 1,
    minWidth: 50,
    marginLeft: theme.spacing(1),
    '& > path': {
      transition: theme.transitions.create('transform'),
      transform: 'translate3d(-20px, 0, 0)',
      'a:hover &': {
        transform: 'translate3d(0, 0, 0)',
      },
    },
  },
})

const LinkArrow = React.forwardRef(function LinkArrow(props, ref) {
  const { children, classes, className, ...other } = props
  other.href = other.href === '' ? '#' : other.href

  return (
    <Link
      className={clsx(classes.root, className)}
      variant="button"
      underline="none"
      ref={ref}
      {...other}
    >
      {children}
      <ArrowNextIcon className={classes.arrow} />
    </Link>
  )
})

LinkArrow.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
}

export default withStyles(styles)(LinkArrow)
