import * as React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import withStyles from '@material-ui/core/styles/withStyles'
import Collapse from '@material-ui/core/Collapse'
// import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
// import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useProductFlowControl } from 'containers/ProductFlowControl'

export const styles = (theme) => ({
  container: {
    position: 'absolute',
    left: 0,
    bottom: '100%',
    width: '100%',
  },
  wrapper: {},
  wrapperInner: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  drawerHandleContainer: {
    height: 30,
  },
  drawerHandle: {
    ...theme.mixins.contain(30),
    height: 4,
    borderRadius: 5,
    backgroundColor: theme.palette.text.hint,
  },
})

function ProductFlowPopup(props) {
  const { children, classes, className } = props

  const { expanded, /* onClose, onOpen, */ setIsPopupType } = useProductFlowControl()
  // const isBreakpointUp = useMediaQuery((theme) => theme.breakpoints.up('sm'))

  React.useEffect(() => {
    setIsPopupType(true)
  }, [setIsPopupType])

  return (
    <Collapse
      classes={{
        container: clsx(classes.container, className),
        wrapper: classes.wrapper,
        wrapperInner: classes.wrapperInner,
      }}
      in={expanded}
      unmountOnExit
    >
      {children}
    </Collapse>
  )

  // if (isBreakpointUp) {
  //   return (
  //     <Collapse
  //       classes={{
  //         container: clsx(classes.container, className),
  //         wrapper: classes.wrapper,
  //         wrapperInner: classes.wrapperInner,
  //       }}
  //       in={expanded}
  //       unmountOnExit
  //     >
  //       {children}
  //     </Collapse>
  //   )
  // }

  // return (
  //   <SwipeableDrawer
  //     PaperProps={{ className: classes.wrapperInner }}
  //     onClose={onClose}
  //     onOpen={onOpen}
  //     open={expanded}
  //     anchor="bottom"
  //   >
  //     <div className={classes.drawerHandleContainer}>
  //       <div className={classes.drawerHandle} />
  //     </div>

  //     {children}
  //   </SwipeableDrawer>
  // )
}

ProductFlowPopup.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
}

export default withStyles(styles)(ProductFlowPopup)
