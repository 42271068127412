import { deepmerge } from '@material-ui/utils'

const caseAllCaps = {
  textTransform: 'uppercase',
}

const caseNoCaps = {
  textTransform: 'none',
}

export default function createTypography(palette, typography) {
  const {
    fontFamilyPrimary = '"Roobert", sans-serif',
    fontFamilySecondary = fontFamilyPrimary,
    // The default font size of the Material Specification.
    fontSize = 14, // px
    fontWeightLight = 300,
    fontWeightRegular = 400,
    fontWeightMedium = 500,
    fontWeightSemibold = 600,
    fontWeightBold = 700,
    // Tell Material-UI what's the font-size on the html element.
    // 16px is the default font-size used by browsers.
    htmlFontSize = 16,
    // Apply the CSS properties to all the variants.
    allVariants,
    ...other
  } = typeof typography === 'function' ? typography(palette) : typography

  const coef = fontSize / 14
  const pxToRem = (size) => `${(size / htmlFontSize) * coef}rem`
  const buildVariant = (typeFace, fontWeight, size, lineHeight, letterSpacing, casing) => ({
    fontFamily: typeFace,
    fontWeight,
    fontSize: size, // pxToRem(size), // Disable `pxToRem` for variable use with `fluidType` mixin.
    // Unitless following http://meyerweb.com/eric/thoughts/2006/02/08/unitless-line-heights/
    lineHeight,
    letterSpacing: `${letterSpacing}em`,
    ...casing,
    ...allVariants,
  })

  const variants = {
    h1: buildVariant(fontFamilySecondary, fontWeightMedium, 58, 1.15, -0.01, caseAllCaps),
    h2: buildVariant(fontFamilySecondary, fontWeightMedium, 42, 1.15, -0.01, caseAllCaps),
    h3: buildVariant(fontFamilySecondary, fontWeightMedium, 36, 1.15, -0.01, caseAllCaps),
    h4: buildVariant(fontFamilySecondary, fontWeightMedium, 31, 1.15, -0.01, caseAllCaps),
    h5: buildVariant(fontFamilySecondary, fontWeightMedium, 22, 1.2, 0.03, caseAllCaps),
    h6: buildVariant(fontFamilySecondary, fontWeightMedium, 18, 1.35, -0.01, caseAllCaps),
    subtitle1: buildVariant(fontFamilyPrimary, fontWeightMedium, 16, 1.65, 0, caseAllCaps),
    subtitle2: buildVariant(fontFamilyPrimary, fontWeightMedium, 14, 1.65, 0, caseAllCaps),
    preamble: buildVariant(fontFamilyPrimary, fontWeightLight, 22, 1.5, 0, caseNoCaps), // Custom variant
    body1: buildVariant(fontFamilyPrimary, fontWeightLight, 16, 1.65, 0, caseNoCaps),
    body2: buildVariant(fontFamilyPrimary, fontWeightLight, 14, 1.65, 0, caseNoCaps),
    button: buildVariant(fontFamilySecondary, fontWeightMedium, 14, 1.5, 0.05, caseAllCaps),
    caption: buildVariant(fontFamilyPrimary, fontWeightLight, 14, 1.62, 0, caseNoCaps),
    caption2: buildVariant(fontFamilyPrimary, fontWeightLight, 12, 1.5, 0, caseNoCaps), // Custom variant
    overline: buildVariant(fontFamilySecondary, fontWeightRegular, 12, 1.5, 0.055, caseAllCaps),
  }

  const typographyOutput = deepmerge(
    {
      htmlFontSize,
      pxToRem,
      fontFamilyPrimary,
      fontFamilySecondary,
      fontSize,
      fontWeightLight,
      fontWeightRegular,
      fontWeightMedium,
      fontWeightSemibold,
      fontWeightBold,
      // Mui uses standalone `fontFamily` internally.
      fontFamily: fontFamilyPrimary,
      ...variants,
    },
    other,
    {
      clone: false, // No need to clone deep
    },
  )

  return typographyOutput
}
