import * as React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Image from 'next/image'
import { productType } from 'utils'
import Typography from 'components/Typography'

export const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.mixins.verticalRhythm(1),
  },
  heading: {
    height: 60,
  },
  name: {},
  number: {
    color: theme.palette.text.hint,
  },
  list: {
    ...theme.mixins.horizontalRhythm(1),
    display: 'flex',
  },
  listWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    '& > * + *': {
      marginRight: theme.spacing(1.5),
    },
  },
  listItem: {
    width: 77,
    maxWidth: 100,
  },
  listItemWrap: {
    width: 70,
    maxWidth: 100,
  },
  listItemIcon: {
    fontSize: 44,
  },
  listItemImage: {
    width: 'auto',
    height: 44,
  },
  listItemLabel: {
    ...theme.typography.overline,
    display: 'block',
  },
  description: {},
  listRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
}))

const ProductDetails = React.forwardRef(function ProductDetails(props, ref) {
  const { className, hideDescription, product, type = 'product', ...other } = props
  const classes = useStyles(props)

  return (
    <div className={clsx(classes.root, className)} ref={ref} {...other}>
      <Typography className={classes.heading} component="h2" variant="h5">
        <span className={classes.name}>{product.name}</span>
        {type !== 'ingredient' && (
          <span className={classes.number}>&nbsp;{product.nameSuffix}</span>
        )}
      </Typography>

      {!hideDescription && (
        <Typography className={classes.description}>{product.description}</Typography>
      )}

      {product?.features?.length > 0 && (
        <div
          className={clsx({
            [classes.list]:
              (product.features.length < 5 && type !== 'ingredient') ||
              (product.features.length < 4 && type === 'ingredient'),
            [classes.listWrap]:
              product.features.length > 4 || (product.features.length > 3 && type === 'ingredient'),
          })}
        >
          {product.features?.map((feature, idx) => (
            <div
              key={idx}
              className={clsx({
                [classes.listRow]: type === 'ingredient',
                [classes.listItem]: product.features.length < 5 && type !== 'ingredient',
                [classes.listItemWrap]: product.features.length > 4 && type !== 'ingredient',
              })}
            >
              <Image
                className={classes.listItemImage}
                src={feature.imageUrl}
                width={44}
                height={44}
                quality={100}
                unoptimized
                alt={feature?.label || `Feature image ${idx}`}
              />
              <span className={classes.listItemLabel}>{feature.label}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  )
})

ProductDetails.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  hideDescription: PropTypes.bool,
  product: productType.isRequired,
}

export default ProductDetails
