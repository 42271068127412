export default function extractUniqueArticleTagsByKey(articles, key, regex) {
  return articles.reduce(
    (acc, a) =>
      acc.concat(
        a.tags.filter(
          (t) => (!regex || regex.test(t[key])) && !acc.some((at) => at[key] === t[key]),
        ),
      ),
    [],
  )
}
