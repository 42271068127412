import * as React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useGlobal } from 'api'
import RouterLink from 'containers/RouterLink'
import Newsletter from 'containers/Newsletter'
import BrandIcon from 'components/icons/Brand'
import Container from 'components/Container'
import IconButton from 'components/IconButton'
import Link from 'components/Link'
import Section from 'components/Section'
import { useTranslations } from 'containers/Translations/TranslationsContext'
import { useApp } from '../AppContext'
import AppFooterMenuItem, { getMenuLinkProps } from './AppFooterMenuItem'

export const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: -1,
  },
  nav: {
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden',
  },
  menu: {
    flex: '1 1 250px',
    padding: 'var(--coa-section-spacing) var(--coa-container-spacing)',
    margin: -1,
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.default,
  },
  menuTopLink: {
    display: 'inline-flex',
  },
  menuList: {},
  newsletterArea: {
    flexBasis: '450px',
  },
  newsletter: {
    maxWidth: 'var(--coa-content-max-width)',
  },
  extra: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: theme.spacing(2),
      alignItems: 'flex-start',
    },
  },
  share: {
    display: 'flex',
    alignItems: 'center',
  },
  shareLabel: theme.typography.overline,
  brandIcon: {
    width: 'auto',
  },
  shareComponent: {
    borderTop: 'none',
    borderBottom: 'none',
  },
  iconsWrapper: {
    display: 'flex',
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
  },
  iconMain: {
    display: 'flex',
    gap: theme.spacing(0),
    border: `1px solid ${theme.palette.text.secondary}`,
    padding: theme.spacing(0.8, 2),
    position: 'relative',
    width: 'fit-content',
  },
  iconsTitle: {
    position: 'absolute',
    top: -10,
    left: theme.spacing(1.5),
    backgroundColor: theme.palette.background.default,
    padding: '0 8px',
    ...theme.typography.overline,
    color: theme.palette.text.secondary,
  },
  iconItems: {
    display: 'flex',
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      gap: theme.spacing(1, 2),
    },
  },
  oneIcon: {
    left: '4px',
  },
  iconsImage: {
    objectFit: 'contain',
    maxHeight: 48,
    maxWidth: 96,
  },
}))

const AppFooter = React.memo(function AppFooter(props) {
  const { className, ...other } = props
  const classes = useStyles(props)

  const { menus } = useGlobal()
  const t = useTranslations()

  return (
    <Section className={clsx(classes.root, className)} component="footer" {...other}>
      <nav className={classes.nav}>
        {menus?.footer?.map((menuLink, idx) => {
          const submenu = menuLink.links
          const hasSubmenu = submenu?.length > 0

          return (
            <div key={idx} className={classes.menu}>
              {menuLink.label && (
                <Link
                  className={classes.menuTopLink}
                  color="textSecondary"
                  variant="overline"
                  {...getMenuLinkProps(menuLink)}
                >
                  {menuLink.label}
                </Link>
              )}

              {hasSubmenu && (
                <ul className={classes.menuList}>
                  {submenu.map((menuSublink, idx2) => (
                    <AppFooterMenuItem
                      key={idx2}
                      menuLink={menuSublink}
                      menuLinkProps={{ variant: 'subtitle2' }}
                    />
                  ))}
                </ul>
              )}
            </div>
          )
        })}

        <div className={clsx(classes.menu, classes.newsletterArea)}>
          <Newsletter className={classes.newsletter} />
        </div>
      </nav>

      <Container className={classes.extra} maxWidth={false}>
        <div className={classes.iconsWrapper}>
          {menus?.payment?.length > 0 && (
            <div className={classes.iconMain}>
              <span
                className={clsx(classes.iconsTitle, {
                  [classes.oneIcon]: menus.payment?.length === 1,
                })}
              >
                {t('Web.Footer.Payment')}
              </span>

              <div className={classes.iconItems}>
                {menus.payment?.map((payment, idx) => (
                  <img
                    key={idx}
                    src={payment.img.filename}
                    alt={payment.title}
                    className={classes.iconsImage}
                  />
                ))}
              </div>
            </div>
          )}

          {menus?.delivery?.length > 0 && (
            <div className={classes.iconMain}>
              <span
                className={clsx(classes.iconsTitle, {
                  [classes.oneIcon]: menus.delivery?.length === 1,
                })}
              >
                {t('Web.Footer.Delivery')}
              </span>

              <div className={classes.iconItems}>
                {menus.delivery?.map((delivery, idx) => (
                  <img
                    key={idx}
                    src={delivery.img.filename}
                    alt={delivery.title}
                    className={classes.iconsImage}
                  />
                ))}
              </div>
            </div>
          )}
        </div>

        <IconButton
          className={classes.brandButton}
          component={RouterLink}
          href="/"
          edge="end"
          aria-label={t('Web.Footer.GoToHomePage')}
        >
          <BrandIcon className={classes.brandIcon} />
        </IconButton>
      </Container>
    </Section>
  )
})

AppFooter.propTypes = {
  className: PropTypes.string,
}

function AppFooterContainer(props) {
  const { hideFooter } = useApp()

  if (hideFooter) {
    return null
  }

  return <AppFooter {...props} />
}

export default AppFooterContainer
