const styles = (theme) => ({
  '@global': {
    '@font-face': [
      // Include font files here:
      {
        fontFamily: 'Roobert',
        fontWeight: 500,
        fontDisplay: 'swap',
        src: `
          url('/fonts/Roobert/Roobert-Medium.woff2') format('woff2'),
          url('/fonts/Roobert/Roobert-Medium.woff') format('woff')
        `,
      },
      {
        fontFamily: 'Roobert',
        fontWeight: 400,
        fontDisplay: 'swap',
        src: `
          url('/fonts/Roobert/Roobert-Regular.woff2') format('woff2'),
          url('/fonts/Roobert/Roobert-Regular.woff') format('woff')
        `,
      },
      {
        fontFamily: 'Roobert',
        fontWeight: 300,
        fontDisplay: 'swap',
        src: `
          url('/fonts/Roobert/Roobert-Light.woff2') format('woff2'),
          url('/fonts/Roobert/Roobert-Light.woff') format('woff')
        `,
      },
    ],
    ':root': theme.mixins.root,
    // Opinionated defaults taken from sanitize.css
    // https://github.com/csstools/sanitize.css
    'iframe, img, input, select, textarea': {
      height: 'auto',
      maxWidth: '100%',
    },
    'ol ol, ol ul, ul ol, ul ul': {
      margin: 0,
    },
    'nav ol, nav ul': {
      listStyle: 'none',
      padding: 0,
    },
    'svg:not([fill])': {
      fill: 'currentColor',
    },
    strong: {
      fontWeight: 500,
    },
  },
})

export default styles
