/* eslint-disable import/no-cycle */
import dynamic from 'next/dynamic'

export const Accordion = dynamic(() => import(/* webpackChunkName: "./Accordion" */ './Accordion')) // prettier-ignore
export const ArticleAuthor = dynamic(() => import(/* webpackChunkName: "./ArticleAuthor" */ './ArticleAuthor')) // prettier-ignore
export const ArticleGroup = dynamic(() => import(/* webpackChunkName: "./ArticleGroup" */ './ArticleGroup')) // prettier-ignore
export const ArticleHero = dynamic(() => import(/* webpackChunkName: "./ArticleHero" */ './ArticleHero')) // prettier-ignore
export const CategoryPicker = dynamic(() => import(/* webpackChunkName: "./CategoryPicker" */ './CategoryPicker')) // prettier-ignore
export const CategoryPicker2 = dynamic(() => import(/* webpackChunkName: "./CategoryPicker2" */ './CategoryPicker2')) // prettier-ignore
export const Content = dynamic(() => import(/* webpackChunkName: "./Content" */ './Content')) // prettier-ignore
export const ContentHero = dynamic(() => import(/* webpackChunkName: "./ContentHero" */ './ContentHero')) // prettier-ignore
export const FullScreenContent = dynamic(() => import(/* webpackChunkName: "./FullScreenContent" */ './FullScreenContent')) // prettier-ignore
export const Hero = dynamic(() => import(/* webpackChunkName: "./Hero" */ './Hero')) // prettier-ignore
export const InfoBox = dynamic(() => import(/* webpackChunkName: "./InfoBox" */ './InfoBox')) // prettier-ignore
export const Maintenance = dynamic(() => import(/* webpackChunkName: "./Maintenance" */ './Maintenance')) // prettier-ignore
export const Marquee = dynamic(() => import(/* webpackChunkName: "./Marquee" */ './Marquee')) // prettier-ignore
export const Media = dynamic(() => import(/* webpackChunkName: "./Media" */ './Media')) // prettier-ignore
export const MediaGroup = dynamic(() => import(/* webpackChunkName: "./MediaGroup" */ './MediaGroup')) // prettier-ignore
export const Product = dynamic(() => import(/* webpackChunkName: "./Product" */ './Product')) // prettier-ignore
export const Quotes = dynamic(() => import(/* webpackChunkName: "./Quotes" */ './Quotes')) // prettier-ignore
export const Reviews = dynamic(() => import(/* webpackChunkName: "./Reviews" */ './Reviews')) // prettier-ignore
export const Share = dynamic(() => import(/* webpackChunkName: "./Share" */ './Share')) // prettier-ignore
export const UspGroup = dynamic(() => import(/* webpackChunkName: "./UspGroup" */ './UspGroup')) // prettier-ignore
export const InThePress = dynamic(() => import(/* webpackChunkName: "./InThePress" */ './InThePress')) // prettier-ignore
export const Popup = dynamic(() => import(/* webpackChunkName: "./Popup" */ './Popup')) // prettier-ignore
export const DialogTrail = dynamic(() => import(/* webpackChunkName: "./DialogTrail" */ './DialogTrail')) // prettier-ignore
export const Ingredient = dynamic(() => import(/* webpackChunkName: "./Ingredient" */ './Ingredient')) // prettier-ignore
export const ProductCatalog = dynamic(() => import(/* webpackChunkName: "./ProductCatalog" */ './ProductCatalog')) // prettier-ignore
export const ProductCatalogItem = dynamic(() => import(/* webpackChunkName: "./ProductCatalogItem" */ './ProductCatalogItem')) // prettier-ignore
export const MultipleContent = dynamic(() => import(/* webpackChunkName: "./ProductCatalogItem" */ './MultipleContent')) // prettier-ignore
export const KlaviyoTrack = dynamic(() => import(/* webpackChunkName: "./KlaviyoTrack" */ './KlaviyoTrack')) // prettier-ignore

// Non dynamic blocks with static method `getInitialProps` that fetches additional data. Dynamic import removes the static method.
export { default as ArticleArchive } from './ArticleArchive'
export { default as LinkArchive } from './LinkArchive'
export { default as Product2 } from './Product2'
export { default as ProductGroup } from './ProductGroup'
export { default as ProductGroupVariants } from './ProductGroupVariants'
export { default as ProductHero } from './ProductHero'
export { default as ProductHeroOther } from './ProductHeroOther'
export { default as Quiz } from './Quiz'
export { default as ProductShowcase } from './ProductShowcase'
export { default as Testimonials } from './Testimonials'
export { default as TestimonialsItem } from './TestimonialsItem'
