import * as React from 'react'
import createSvgIcon from '../utils/createSvgIcon'

export default createSvgIcon(
  <>
    <circle cx="12" cy="12" r="11" fill="#3C5A96" />
    <path
      d="M13.0996 17.9229V12.2818H15.0336L15.5172 9.93139H13.0996V8.99122C13.0996 8.05105 13.584 7.58097 14.5501 7.58097H15.5172V5.23055C15.0336 5.23055 14.434 5.23055 13.5831 5.23055C11.8062 5.23055 10.682 6.58486 10.682 8.52114V9.93139H8.74792V12.2818H10.682V17.9228L13.0996 17.9229Z"
      fill="white"
    />
  </>,
  'Facebook',
)
