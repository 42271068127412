import PropTypes from 'prop-types'

const tagType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
})

const articleType = PropTypes.shape({
  content: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  mediaSrc: PropTypes.string.isRequired,
  publishDate: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(tagType),
})

export default articleType
