import * as React from 'react'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import { useCheckout } from 'api'
import { productType } from 'utils'
// import { useAppHandlers } from 'containers/App/AppContext'
import ProductFlowControlContext from './ProductFlowControlContext'

function ProductFlowControl(props) {
  const { children, defaultExpanded = false, product, directPrice, setSelectedPrice } = props

  const { onItemAdd } = useCheckout()
  // const { onCartMenuOpen } = useAppHandlers()
  const [addResult, setAddResult] = React.useState(null)

  const defaultSelectedIdx =
    directPrice === undefined
      ? Math.max(
          product.prices?.findIndex((p) => p.defaultSelected),
          0,
        )
      : Math.max(
          product.prices?.findIndex((p) => p.id === directPrice.id),
          0,
        )

  const [isPopupType, setIsPopupType] = React.useState(false)
  const [expanded, setExpanded] = React.useState(defaultExpanded)
  const [selectedIdx, setSelectedIdx] = React.useState(defaultSelectedIdx)

  const router = useRouter()

  const onOpen = React.useCallback(() => {
    setExpanded(true)
  }, [])

  const onClose = React.useCallback(() => {
    setExpanded(false)
  }, [])

  const onProductPriceChange = React.useCallback(
    (value) => {
      setSelectedIdx(value)
      if (setSelectedPrice) setSelectedPrice(product?.prices?.[value])
    },
    [product?.prices, setSelectedPrice],
  )

  const prevRouteRef = React.useRef({})

  React.useEffect(() => {
    if (prevRouteRef.current !== router.asPath) {
      setSelectedIdx(defaultSelectedIdx)
    }
    prevRouteRef.current = router.asPath
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath])

  const onCtaClick = React.useCallback(async () => {
    if (isPopupType && !expanded) {
      setExpanded(true)
      return
    }
    const price = product?.prices?.[selectedIdx]

    if (onItemAdd && product && price) {
      try {
        const data = await onItemAdd(product.variantId, price.id, price.lineType)
        // onCartMenuOpen()
        setAddResult(data)
      } catch (error) {
        console.error(error)
      }
    }
  }, [isPopupType, expanded, product, selectedIdx, onItemAdd])

  React.useEffect(() => {
    if (addResult) {
      setTimeout(() => {
        setAddResult(false)
      }, 2000)
    }
  }, [addResult])

  const childContext = {
    expanded,
    isPopupType,
    addResult,
    onClose,
    onCtaClick,
    onOpen,
    product,
    selectedIdx,
    setIsPopupType,
    onProductPriceChange,
    // Computed props
    selectedPrice: product.prices?.[selectedIdx],
  }

  return (
    <ProductFlowControlContext.Provider value={childContext}>
      {typeof children === 'function' ? children(childContext) : children}
    </ProductFlowControlContext.Provider>
  )
}

ProductFlowControl.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  defaultExpanded: PropTypes.bool,
  product: productType.isRequired,
  directPrice: PropTypes.array,
  setSelectedPrice: PropTypes.func,
}

export default ProductFlowControl
