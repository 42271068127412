import * as React from 'react'
import createSvgIcon from '../utils/createSvgIcon'

export default createSvgIcon(
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.74064 12.0002C5.74064 11.2857 5.85924 10.6006 6.07116 9.95811L2.36382 7.12716C1.64125 8.59411 1.23425 10.2472 1.23425 12.0002C1.23425 13.7517 1.64091 15.4036 2.36227 16.8697L6.06755 14.0333C5.85769 13.3937 5.74064 12.7112 5.74064 12.0002Z"
      fill="#FBBC05"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.25 5.50003C13.8022 5.50003 15.2042 6.05003 16.3057 6.94997L19.5101 3.75C17.5575 2.04998 15.0539 1 12.25 1C7.89672 1 4.15534 3.48944 2.36389 7.127L6.07106 9.95795C6.92528 7.36505 9.36023 5.50003 12.25 5.50003Z"
      fill="#EA4335"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.25 18.4997C9.36041 18.4997 6.92545 16.6347 6.07123 14.0418L2.36389 16.8722C4.15534 20.5103 7.89672 22.9998 12.25 22.9998C14.9367 22.9998 17.5019 22.0457 19.4271 20.2582L15.9082 17.5378C14.9152 18.1632 13.6648 18.4997 12.25 18.4997Z"
      fill="#34A853"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.7654 12.0003C22.7654 11.3503 22.6652 10.6503 22.515 10.0004H12.2506V14.2504H18.159C17.8635 15.6994 17.0595 16.8134 15.9088 17.5383L19.4278 20.2588C21.45 18.3819 22.7654 15.5858 22.7654 12.0003Z"
      fill="#4285F4"
    />
  </>,
  'Google',
)
