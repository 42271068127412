/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import * as React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useEmblaCarousel } from 'embla-carousel/react'
import Image from 'next/image'
import { useRouter } from 'next/router'
import IconButton from 'components/IconButton'
import ProductCard from 'containers/ProductCard'
import Typography from 'components/Typography'
import { media } from 'utils/transforms'
import { Close, ArrowForwardIos } from 'components/icons'
import { useGlobal } from 'api'
import { gtm } from 'containers/Gtm/GtmTracker'
import Video from './Video'

const BREAKPOINT_KEY_UP = 'sm'

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#2E2630',
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: {
      backgroundColor: 'transparent',
    },
  },
  fullSize: {
    height: '100%',
    width: '100%',
  },
  slide: {
    position: 'relative',
    height: '100%',
    width: '100%',
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      gap: theme.spacing(1.5),
    },
  },
  expanded: {
    height: 'calc(100dvh - 47px)',
    width: '100%',
  },
  expandedContainer: {
    height: '100%',
    backfaceVisibility: 'hidden',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    touchAction: 'pan-x pinch-zoom',
  },
  videoBackground: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: '#2E2630',
  },

  controls: {
    position: 'absolute',
    top: '32px',
    left: '32px',
    zIndex: 110,
    display: 'flex',
    gap: theme.spacing(2.5),
    flexDirection: 'column',
  },
  closeIcon: {
    backgroundColor: '#2E2630',
    borderRadius: '100%',
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'rgb(32, 26, 33)',
      boxShadow: 'none',
    },
  },
  prevIcon: {
    marginTop: theme.spacing(1.5),
    rotate: '-90deg',
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  nextIcon: {
    rotate: '90deg',
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  controlIcon: {
    padding: theme.spacing(0.3),
    fill: `${theme.palette.background.paper} !important`,
  },
  close: {
    color: theme.palette.background.paper,
  },
  productContainer: {
    width: '100%',
    height: '88px',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(0, 2),
    position: 'absolute',
    bottom: theme.spacing(2),
    left: 0,
    transition: 'all 0.25s',
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: {
      minWidth: '424px',
    },
    [theme.breakpoints.up('md')]: {
      height: '100%',
      width: '30%',
      padding: theme.spacing(4, 1.5, 0, 0),
      position: 'relative',
    },
    [theme.breakpoints.up('lg')]: {
      width: '20%',
    },
  },
  product: {
    gap: theme.spacing(2),
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },

    '& > div> div> div > div > a > div> img ': {
      objectFit: 'contain',
      objectPosition: 'top',
    },
  },
  smallCard: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    backgroundColor: theme.palette.background.paper,
    cursor: 'pointer',
    transition: 'all 0.25s',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  cardInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  },
  suffix: {
    color: theme.palette.text.hint,
  },
  controlsIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
    backgroundColor: '#2E2630',
    borderRadius: '50%',
    width: '56px',
    height: '56px',
    pointerEvents: 'none',
    '&:hover': {
      backgroundColor: 'rgb(32, 26, 33)',
      boxShadow: 'none',
    },
  },
  videoContainer: {
    position: 'relative',
    width: '100%',
  },
}))

const Reels = (props) => {
  const { items, itemExpanded, handleCloseItem } = props

  const { products } = useGlobal()
  const { onFormatCurrency } = useGlobal()

  const classes = useStyles(props)
  const router = useRouter()

  const [emblaRef, embla] = useEmblaCarousel({
    align: 'start',
    loop: true,
    axis: 'y',
    containScroll: 'trimSnaps',
    startIndex: itemExpanded?.itemIndex || 0,
  })
  const [selectedIndex, setSelectedIndex] = React.useState(itemExpanded?.itemIndex || 0)

  function formatProduct(args) {
    const { assetUrl, videoUrl, product } = args

    const productData = product?.[0] || {}

    const filteredProduct = products.find((p) => p?.productCode === productData?.productCode)

    const transformedProps = {
      assetUrl: media(assetUrl),
      videoUrl: media(videoUrl),
      product: filteredProduct,
      productData,
    }

    return transformedProps
  }

  React.useEffect(() => {
    if (!embla) return
    embla?.reInit()
    embla.on('select', () => {
      setSelectedIndex(embla.selectedScrollSnap())

      gtm({
        event: 'testimonials',
        ecommerce: {
          testimonial: { step: 'scroll' },
        },
      })
    })
    embla.on('scroll', () => {
      setSelectedIndex(embla.selectedScrollSnap())
    })
  }, [embla])

  return (
    <div className={clsx(classes.root, classes.fullSize)} ref={emblaRef}>
      <div className={clsx(classes.expandedContainer, classes.fullSize)}>
        {items.map((single, index) => {
          const item = formatProduct(single)

          return (
            <div key={index} className={classes.slide}>
              {item?.videoUrl?.src && (
                <Video videoData={item?.videoUrl} selectedIndex={selectedIndex} itemIndex={index} />
              )}

              <div className={clsx(classes.productContainer)}>
                <ProductCard
                  className={clsx(classes.product, classes.fullSize)}
                  product={item?.product}
                  {...item?.productData}
                />
                <div
                  className={clsx(classes.smallCard, classes.fullSize)}
                  onClick={() => router.push(`${item?.product?.href}`)}
                >
                  <Image
                    src={
                      item?.product?.imagesData?.filter(
                        (image) =>
                          image.tag ===
                          (item?.productData?.imageTag !== ''
                            ? `${item?.productData?.imageTag}_R`
                            : 'Hero_R'),
                      )?.[0]?.imageUrl || item?.product?.imageUrl
                    }
                    alt={item?.product?.name}
                    height={88}
                    width={88}
                    objectFit="cover"
                  />

                  <div className={classes.cardInfoContainer}>
                    <Typography variant="subtitle2" className={classes.cardInfo}>
                      {`${item?.product?.name} `}
                      <span className={classes.suffix}>{item?.product?.nameSuffix}</span>
                    </Typography>

                    <Typography variant="subtitle1" className={classes.cardInfo}>
                      {onFormatCurrency(item?.product?.priceFrom)}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>

      <div className={clsx(classes.controls)}>
        <IconButton
          edge="start"
          aria-label="Close item"
          className={classes.closeIcon}
          onClick={handleCloseItem}
        >
          <Close className={classes.close} />
        </IconButton>

        <IconButton
          edge="start"
          aria-label="Previous item"
          className={clsx(classes.closeIcon, classes.prevIcon)}
          onClick={() => embla.scrollPrev()}
        >
          <ArrowForwardIos className={classes.controlIcon} />
        </IconButton>
        <IconButton
          edge="start"
          aria-label="Next video"
          className={clsx(classes.closeIcon, classes.nextIcon)}
          onClick={() => embla.scrollNext()}
        >
          <ArrowForwardIos className={classes.controlIcon} />
        </IconButton>
      </div>
    </div>
  )
}

Reels.propTypes = {
  className: PropTypes.string,
  itemExpanded: PropTypes.object,
  onSetItemExpanded: PropTypes.func,
  handleCloseItem: PropTypes.func,
  items: PropTypes.array,
}

export default Reels
