/* eslint-disable import/no-cycle */
import * as React from 'react'
import Image from 'next/image'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useEmblaCarousel } from 'embla-carousel/react'
import MediaLoader from '@oakwood/oui/MediaLoader'
import Media from '@oakwood/oui/Media'
import MediaReveal from '@oakwood/oui/MediaReveal'
import Collapse from '@material-ui/core/Collapse'
import Tag from 'components/Tag'
import { ASPECT_RATIOS } from 'utils/constants'
import { mediaType } from 'utils'
import RouterLink from 'containers/RouterLink'
import Button from 'components/Button'
import Container from 'components/Container'
import LinkArrow from 'components/LinkArrow'
import Section from 'components/Section'
import Typography from 'components/Typography'
import ArrowBackIos from 'components/icons/ArrowBackIos'
import ArrowForwardIos from 'components/icons/ArrowForwardIos'
import IconButton from 'components/IconButton'
import { cdnUrl } from 'utils/storyblok'

const BREAKPOINT_KEY_UP = 'xs'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: -1,
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}))

const useUnwrappedStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridGap: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      gridGap: theme.spacing(4),
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
  rootContentOnly: {
    display: 'grid',
    placeItems: 'center',
    height: '100%',
    '& > div ': {
      justifyContent: 'center',
    },
  },
  mediaArea: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      '$layoutReverse &': {
        justifyContent: 'flex-end',
      },
    },
    '& > a > div': {
      height: '100%',
      [theme.breakpoints.up('md')]: {
        height: '95%',
      },
    },
  },
  mediaContainer: {
    objectFit: 'contain',
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.complex,
    }),
    [theme.breakpoints.up('sm')]: {
      maxWidth: 500,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 640,
    },
  },
  contentArea: {
    // maxHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: {
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    [theme.breakpoints.up('md')]: {
      padding: '16% 0',
      '$layoutReverse &': {
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        order: -1,
      },
    },
  },
  contentAreaWithoutImage: {
    width: '100%',
    padding: theme.spacing(4, 0, 0, 0),

    [theme.breakpoints.up('md')]: {
      '$layoutReverse &': {
        alignItems: 'flex-start',
        justifyContent: 'center',
        order: -1,
      },
    },
  },
  mediaAreaWithoutSubheading: {
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: {
      justifyContent: 'end',
      alignItems: 'flex-start',
    },
    [theme.breakpoints.up('md')]: {
      padding: '8% 0 16% 0',
      '$layoutReverse &': {
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        order: -1,
      },
    },
  },
  content: {
    ...theme.mixins.verticalRhythm(2),
    [theme.breakpoints.up('sm')]: {
      maxWidth: 'clamp(435px, 34vw, 643px)',
      paddingLeft: theme.spacing(4),
    },
    [theme.breakpoints.up('md')]: theme.mixins.verticalRhythm(4),
  },
  contentIngredient: {
    height: 350,
    ...theme.mixins.verticalRhythm(2),
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: {
      maxWidth: 'clamp(435px, 34vw, 643px)',
      paddingLeft: theme.spacing(4),
    },
    [theme.breakpoints.up('md')]: {
      ...theme.mixins.verticalRhythm(4),
      height: 350,
    },
  },
  heading: theme.mixins.fluidType(320, 'lg', theme.typography.h4, theme.typography.h1),
  headingWithSub: theme.mixins.fluidType(320, 'lg', theme.typography.h4, theme.typography.h4),
  subHeading: {
    color: theme.palette.text.hint,
    margin: 0,
  },
  text: {
    margin: theme.spacing(5, 0),
  },
  cta: {},
  layoutReverse: {},
  emblaMediaArea: {
    position: 'relative',
    overflow: 'hidden',
  },
  emblaWrapper: {
    display: 'flex',
    alignContent: 'center',
  },
  slideContainer: {},
  emblaSlide: {
    position: 'relative',
    flex: '0 0 100%',
    flexShrink: 0,
  },
  media: {},
  navigationButton: {
    position: 'absolute',
    zIndex: 9,
    bottom: '48%',
    color: '#808080',
  },
  navigationPrev: {
    left: 12,
  },
  navigationNext: {
    right: 12,
  },
  ingredientButton: {
    zIndex: 500,
    [theme.breakpoints.up('md')]: { margin: theme.spacing(6, 0, 0, 0) },
  },
  ingredientsCollapse: {
    margin: 0,
    '& > div': {
      position: 'relative',
      zIndex: 500,
    },
  },
  backgroundMediaFullScreen: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
  },
  table: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    borderSpacing: 0,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  tableRow: {
    cursor: 'pointer',
  },
  tableData: {
    ...theme.typography.caption2,
    textAlign: 'start',
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:last-child': {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
    padding: theme.spacing(1, 2, 0.5, 2),
    fontWeight: 400,
  },
  tableHead: {},
  tableHeadData: {
    fontWeight: 600,
  },
  mediaContain: {
    objectFit: 'contain',
  },
}))

export function HeroUnwrapped(props) {
  const {
    className,
    collapsed,
    ctaLabel,
    ctaType = 'link',
    ctaUrl,
    ctaButtonSize = 'medium',
    ctaButtonFullRow = false,
    disableFluidType = false,
    heading,
    subHeading,
    mediaPlacement = 'start',
    mediaProps,
    mediaCtaUrl,
    renderIndex,
    mediaGroup,
    ingredients = {},
    backgroundMediaProps,
    text,
  } = props
  const classes = useUnwrappedStyles(props)

  const contentAreaRef = React.useRef(null)
  const mediaAreaRef = React.useRef(null)
  const mediaContainerRef = React.useRef(null)

  const [collapsedMediaOffsetLeft, setCollapsedMediaOffsetLeft] = React.useState(0)
  const [expanded, setExpanded] = React.useState(false)

  const syncCollapsedMediaOffsetLeft = React.useCallback(() => {
    const contentAreaOffsetLeft = contentAreaRef.current?.offsetLeft
    const mediaAreaOffsetLeft = mediaAreaRef.current?.offsetLeft
    const mediaContainerOffsetLeft = mediaContainerRef.current?.offsetLeft

    setCollapsedMediaOffsetLeft(
      mediaAreaOffsetLeft + mediaContainerOffsetLeft - contentAreaOffsetLeft,
    )
  }, [])

  const [emblaRef, embla] = useEmblaCarousel({
    align: 'start',
    containScroll: 'trimSnaps',
  })

  const handlePrev = React.useCallback(() => embla && embla.scrollPrev(), [embla])
  const handleNext = React.useCallback(() => embla && embla.scrollNext(), [embla])

  const handleMediaLoaded = React.useCallback(() => {
    embla?.reInit() // Fixes unresponsive desktop bug
  }, [embla])

  const composedMediaContainerStyle = collapsed
    ? { transform: `translate3d(-${collapsedMediaOffsetLeft}px, 0, 0)` }
    : undefined

  let mediaUrl

  if (mediaProps?.breakpoints === undefined) {
    mediaUrl = mediaProps?.src
  } else {
    mediaUrl = mediaProps.breakpoints.xs
  }

  let cType = 'contained'
  if (ctaType.includes('outlined')) {
    cType = 'outlined'
  } else if (ctaType.includes('contained')) {
    cType = 'contained'
  } else {
    cType = ctaType
  }

  const isEmpty = (obj) => Object.keys(obj).length === 0

  const handleIngredients = React.useCallback(() => {
    setExpanded(!expanded)
  }, [expanded])

  const handleIngredientClick = React.useCallback((name) => {
    const button = document.getElementById(
      `product-showcase-slide-button-${name.toLowerCase().replaceAll(' ', '_').trim()}`,
    )

    if (button) {
      button.click()
      setExpanded(false)
    }
  }, [])

  React.useEffect(() => {
    if (collapsed) setExpanded(false)
  }, [collapsed])

  return (
    <div
      className={clsx(
        {
          [classes.layoutReverse]: mediaPlacement === 'end',
          [classes.root]:
            mediaGroup?.length !== 0 ||
            !isEmpty(mediaGroup) ||
            mediaProps?.breakpoints?.xs !== cdnUrl(),
          [classes.rootContentOnly]:
            mediaGroup?.length === 0 &&
            isEmpty(mediaGroup) &&
            mediaProps?.breakpoints?.xs === cdnUrl(),
        },
        className,
      )}
    >
      {backgroundMediaProps && (
        <>
          {backgroundMediaProps?.component === 'video' ? (
            <MediaReveal className={classes.backgroundMediaFullScreen}>
              <Media
                {...backgroundMediaProps}
                priority={renderIndex === 0}
                style={{ width: '100%', height: '100%' }}
              />
            </MediaReveal>
          ) : (
            backgroundMediaProps?.breakpoints?.xs && (
              <MediaReveal className={classes.backgroundMediaFullScreen}>
                <Image
                  src={backgroundMediaProps?.breakpoints?.xs}
                  alt={'Hero Background Image'}
                  layout="fill"
                  sizes="100vw"
                  priority={renderIndex === 0}
                  style={{ zIndex: -1 }}
                />
              </MediaReveal>
            )
          )}
        </>
      )}

      {(mediaGroup?.length !== 0 ||
        !isEmpty(mediaGroup) ||
        mediaProps?.breakpoints?.xs !== cdnUrl()) && (
        <div className={classes.mediaArea} ref={mediaAreaRef}>
          {mediaGroup !== undefined && !isEmpty(mediaGroup) ? (
            <div className={classes.emblaMediaArea}>
              <div className={classes.emblaRoot} ref={emblaRef}>
                <MediaLoader className={classes.emblaWrapper} onLoaded={handleMediaLoaded}>
                  {mediaGroup?.map((image, idx) => (
                    <div key={idx} className={classes.emblaSlide}>
                      <Tag
                        component="a"
                        styleRoot={false}
                        href={mediaCtaUrl || '#'}
                        aria-label={`Hero media CTA ${idx + 1}`}
                      >
                        <MediaReveal className={classes.slideContainer}>
                          <Media className={classes.media} src={image?.filename} />
                        </MediaReveal>
                      </Tag>
                    </div>
                  ))}
                </MediaLoader>
              </div>

              <IconButton
                className={clsx(classes.navigationButton, classes.navigationPrev)}
                onClick={handlePrev}
                edge="start"
                aria-label="Previous slide"
              >
                <ArrowBackIos />
              </IconButton>

              <IconButton
                className={clsx(classes.navigationButton, classes.navigationNext)}
                onClick={handleNext}
                edge="end"
                aria-label="Next slide"
              >
                <ArrowForwardIos />
              </IconButton>
            </div>
          ) : (
            mediaProps && {
              ...(mediaProps?.component === 'video' ? (
                <MediaReveal
                  className={classes.mediaContainer}
                  onLoaded={syncCollapsedMediaOffsetLeft}
                  style={composedMediaContainerStyle}
                  ref={mediaContainerRef}
                  {...ASPECT_RATIOS.hero}
                >
                  <Media
                    {...(mediaProps?.component === 'video'
                      ? { autoPlay: true, muted: true, loop: true, playsInline: true }
                      : {})}
                    {...mediaProps}
                    priority={renderIndex === 0}
                  />
                </MediaReveal>
              ) : (
                <Tag
                  component="a"
                  styleRoot={false}
                  href={mediaCtaUrl || '#'}
                  aria-label={`Hero media CTA`}
                >
                  {mediaUrl && (
                    <Image
                      className={clsx(classes.mediaContainer, classes.mediaContain)}
                      src={mediaUrl}
                      height={ASPECT_RATIOS.hero.height}
                      width={ASPECT_RATIOS.hero.width}
                      priority={renderIndex <= 1}
                      unoptimized
                      alt="Hero image"
                    />
                  )}
                </Tag>
              )),
            }
          )}
        </div>
      )}

      <div
        className={clsx(classes.contentArea, {
          [classes.mediaAreaWithoutSubheading]: !subHeading,
          [classes.contentAreaWithoutImage]:
            mediaGroup?.length === 0 &&
            isEmpty(mediaGroup) &&
            mediaProps?.breakpoints?.xs !== cdnUrl,
        })}
        ref={contentAreaRef}
      >
        <div
          className={clsx(classes.content, {
            [classes.contentIngredient]: ctaType === 'ingredients',
          })}
        >
          <Typography
            className={clsx({
              [classes.heading]: !disableFluidType && !subHeading,
              [classes.headingWithSub]: subHeading,
            })}
            component="h1"
            variant="h4"
          >
            {heading}
          </Typography>

          {subHeading && (
            <Typography
              className={!disableFluidType ? classes.subHeading : ''}
              component="h2"
              variant="h4"
            >
              {subHeading}
            </Typography>
          )}

          <Typography
            component="div"
            className={classes.text}
            dangerouslySetInnerHTML={{ __html: text }}
          />

          {ctaType === 'ingredients' ? (
            <div className={classes.ingredientButton}>
              <Button
                className={classes.cta}
                variant={'contained'}
                onClick={() => handleIngredients()}
                size={ctaButtonSize.toLowerCase()}
                fullWidth={ctaButtonFullRow}
              >
                {ctaLabel}
              </Button>
              <Collapse
                in={expanded}
                timeout="auto"
                unmountOnExit
                className={classes.ingredientsCollapse}
              >
                <table className={classes.table}>
                  <thead className={classes.tableHead}>
                    <tr className={classes.tableRow}>
                      {ingredients &&
                        ingredients?.thead?.map((attribute) => {
                          return (
                            <th
                              key={attribute.value}
                              className={clsx(classes.tableData, classes.tableHeadData)}
                            >
                              {attribute.value}
                            </th>
                          )
                        })}
                    </tr>
                  </thead>
                  <tbody>
                    {ingredients &&
                      ingredients?.tbody?.map((attribute, idx) => {
                        return (
                          <tr
                            // eslint-disable-next-line no-underscore-dangle
                            key={attribute._uid || idx}
                            className={classes.tableRow}
                            onClick={() =>
                              handleIngredientClick(attribute?.body?.[0]?.value?.trim())
                            }
                          >
                            {attribute?.body?.map((b) => {
                              return (
                                <td key={b.value} className={classes.tableData}>
                                  {b.value}
                                </td>
                              )
                            })}
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </Collapse>
            </div>
          ) : (
            <>
              {ctaLabel && ctaUrl && (
                <>
                  {ctaType === 'link' ? (
                    <LinkArrow className={classes.cta} component={RouterLink} href={ctaUrl}>
                      {ctaLabel}
                    </LinkArrow>
                  ) : (
                    <Button
                      className={classes.cta}
                      component={RouterLink}
                      href={ctaUrl}
                      variant={cType}
                      size={ctaButtonSize.toLowerCase()}
                      fullWidth={ctaButtonFullRow}
                    >
                      {ctaLabel}
                    </Button>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

HeroUnwrapped.propTypes = {
  className: PropTypes.string,
  collapsed: PropTypes.bool,
  ctaLabel: PropTypes.string,
  ctaType: PropTypes.string,
  ctaUrl: PropTypes.string,
  ctaButtonSize: PropTypes.string,
  ctaButtonFullRow: PropTypes.bool,
  disableFluidType: PropTypes.bool,
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  mediaPlacement: PropTypes.oneOf(['start', 'end']),
  mediaProps: mediaType,
  backgroundMediaProps: PropTypes.object,
  renderIndex: PropTypes.number,
  text: PropTypes.string,
  mediaCtaUrl: PropTypes.string,
  mediaGroup: PropTypes.array,
  ingredients: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

function Hero(props) {
  const classes = useStyles(props)

  return (
    <Section className={classes.root} gutters="padding">
      <Container maxWidth={false}>
        <HeroUnwrapped {...props} />
      </Container>
    </Section>
  )
}

export default Hero
