import * as React from 'react'
import PropTypes from 'prop-types'

export const TranslationsContext = React.createContext({})

if (process.env.NODE_ENV !== 'production') {
  TranslationsContext.displayName = 'TranslationsContext'
}

export function useTranslations() {
  return React.useContext(TranslationsContext)
}

export function TranslationsProvider(props) {
  const { strings = {}, children } = props

  const translate = React.useCallback(
    (key) => strings[key] || `Missing translation: ${key}`,
    [strings],
  )

  return <TranslationsContext.Provider value={translate}>{children}</TranslationsContext.Provider>
}

TranslationsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  strings: PropTypes.object,
}

export default TranslationsContext
