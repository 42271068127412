import * as React from 'react'
import PropTypes from 'prop-types'
import Router, { useRouter } from 'next/router'
import { debounce } from '@material-ui/core/utils'

export const AppHandlersContext = React.createContext({})
export const AppContext = React.createContext({})

if (process.env.NODE_ENV !== 'production') {
  AppHandlersContext.displayName = 'AppHandlersContext'
  AppContext.displayName = 'AppContext'
}

export function useAppHandlers() {
  return React.useContext(AppHandlersContext)
}

export function useApp() {
  return React.useContext(AppContext)
}

const CLOSE_MENUS_ON_RESIZE = false
const COOKIE_CONSENT_ID = 'cookie-consent'
const COOKIE_BAR_ENTER_DELAY = 2000
const REDIRECT_POPUP_ENTER_DELAY = 1000
const EXCLUDED_MARKET_CODES = ['gb', 'no'] // In the future if you need to exclude another market/ display popup , just add the code to the list

export function AppProvider(props) {
  const { children } = props

  const [hideFooter, setHideFooter] = React.useState(false)
  const [hideHeader, setHideHeader] = React.useState(false)
  const [isCartMenuOpen, setCartMenuOpen] = React.useState(false)
  const [isCookieBarOpen, setCookieBarOpen] = React.useState(false)
  const [isRedirectPopupOpen, setRedirectPopupOpen] = React.useState(false)
  const [isLoginDialogOpen, setLoginDialogOpen] = React.useState(false)
  const [isMarketDialogOpen, setMarketDialogOpen] = React.useState(false)
  const [isNavMenuOpen, setNavMenuOpen] = React.useState(false)
  const [levelMenuOpen, setLevelMenuOpen] = React.useState(false)

  const router = useRouter()
  // Helpers

  const closeAllMenus = () => {
    setCartMenuOpen(false)
    setNavMenuOpen(false)
  }

  // Mount hook

  React.useEffect(() => {
    const handleResize = debounce(() => {
      if (CLOSE_MENUS_ON_RESIZE) {
        closeAllMenus()
      }
    })

    const handleRouteChangeStart = () => {
      closeAllMenus()
      setLevelMenuOpen(false)
    }

    if (!localStorage?.getItem(COOKIE_CONSENT_ID)) {
      setTimeout(() => {
        setCookieBarOpen(true)
      }, COOKIE_BAR_ENTER_DELAY)
    }

    window.addEventListener('resize', handleResize, { passive: true })
    Router.events.on('routeChangeStart', handleRouteChangeStart)

    return () => {
      handleResize.clear()
      window.removeEventListener('resize', handleResize)
      Router.events.off('routeChangeStart', handleRouteChangeStart)
    }
  }, [])

  React.useEffect(() => {
    if (EXCLUDED_MARKET_CODES.includes(router?.locale)) {
      setTimeout(() => {
        setRedirectPopupOpen(true)
      }, REDIRECT_POPUP_ENTER_DELAY)
    } else {
      setRedirectPopupOpen(false) // Just to prevent keeping true because of the context
    }
  }, [router.locale])

  // Public handlers

  const onSetLevelMenuOpen = React.useCallback((open) => {
    setLevelMenuOpen(open)
  }, [])

  const onNavMenuToggle = React.useCallback(() => {
    setNavMenuOpen((prev) => !prev)
    setCartMenuOpen(false)
    setCookieBarOpen(false)
  }, [])

  const onNavMenuClose = React.useCallback(() => {
    setNavMenuOpen(false)
  }, [])

  const onCartMenuToggle = React.useCallback(() => {
    setCartMenuOpen((prev) => !prev)
    setNavMenuOpen(false)
    setCookieBarOpen(false)
  }, [])

  const onCartMenuOpen = React.useCallback(() => {
    setCartMenuOpen(true)
    setNavMenuOpen(false)
  }, [])

  const onCartMenuClose = React.useCallback(() => {
    setCartMenuOpen(false)
  }, [])

  const onLoginDialogToggle = React.useCallback(() => {
    setLoginDialogOpen((prev) => !prev)
  }, [])

  const onLoginDialogOpen = React.useCallback(() => {
    setLoginDialogOpen(true)
  }, [])

  const onLoginDialogClose = React.useCallback(() => {
    setLoginDialogOpen(false)
  }, [])

  const onMarketDialogToggle = React.useCallback(() => {
    setMarketDialogOpen((prev) => !prev)
  }, [])

  const onMarketDialogClose = React.useCallback(() => {
    setMarketDialogOpen(false)
  }, [])

  const onCookieBarClose = React.useCallback(() => {
    localStorage.setItem(COOKIE_CONSENT_ID, 1)
    setCookieBarOpen(false)
  }, [])

  // Memoize handlers context separately so that one can subscribe
  // to them without re-rendering on state updates.
  const handlersContextValue = React.useMemo(
    () => ({
      onCartMenuClose,
      onCartMenuOpen,
      onCartMenuToggle,
      onCookieBarClose,
      onLoginDialogClose,
      onLoginDialogToggle,
      onLoginDialogOpen,
      onMarketDialogClose,
      onMarketDialogToggle,
      onNavMenuClose,
      onSetLevelMenuOpen,
      onNavMenuToggle,
      // Expose setters for custom hooks
      setHideFooter,
      setHideHeader,
    }),
    [
      onCartMenuClose,
      onCartMenuOpen,
      onCartMenuToggle,
      onCookieBarClose,
      onLoginDialogClose,
      onSetLevelMenuOpen,
      onLoginDialogOpen,
      onLoginDialogToggle,
      onMarketDialogClose,
      onMarketDialogToggle,
      onNavMenuClose,
      onNavMenuToggle,
    ],
  )

  const contextValue = {
    hideFooter,
    hideHeader,
    isCartMenuOpen,
    isCookieBarOpen,
    isLoginDialogOpen,
    isMarketDialogOpen,
    isNavMenuOpen,
    levelMenuOpen,
    isRedirectPopupOpen,
    // Computed props
    isSomeMenuOpen: isCartMenuOpen || isNavMenuOpen,
    // Merge in handlers for easy access
    ...handlersContextValue,
  }

  return (
    <AppHandlersContext.Provider value={handlersContextValue}>
      <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
    </AppHandlersContext.Provider>
  )
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AppContext
