/* eslint-disable import/no-cycle */
import * as React from 'react'
import PropTypes from 'prop-types'
import { media } from 'utils/transforms'
import { useGlobal } from 'api'
import TestimonialsItemComponent from './TestimonialsItem'

const TestimonialsItem = (props) => {
  const { assetUrl, videoUrl, product } = props
  const { products } = useGlobal()

  const productData = product?.[0] || {}

  const filteredProduct = products.find((p) => p?.productCode === productData?.productCode)

  const transformedProps = {
    ...props,
    assetUrl: media(assetUrl),
    videoUrl: { ...media(videoUrl), id: videoUrl?.id },
    product: filteredProduct,
    productData,
  }

  return <TestimonialsItemComponent {...transformedProps} />
}

TestimonialsItem.propTypes = {
  assetUrl: PropTypes.string,
  videoUrl: PropTypes.string,
  className: PropTypes.string,
  product: PropTypes.array,
}

export default TestimonialsItem
