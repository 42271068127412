import * as React from 'react'
import createSvgIcon from '../utils/createSvgIcon'

export default createSvgIcon(
  <>
    <path d="M19,13H5V11H19Z" />
    <path className="plus" d="M11,19V5h2V19Z" />
  </>,
  'Add',
)
