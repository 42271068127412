import * as React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import withStyles from '@material-ui/core/styles/withStyles'

export const styles = (theme) => ({
  root: {
    ...theme.typography.overline,
    display: 'inline-flex',
    padding: '0 4px',
    backgroundColor: theme.palette.action.selected,
    fontSize: theme.typography.pxToRem(12),
  },
})

const Tag = React.forwardRef(function Tag(props, ref) {
  const {
    children,
    classes,
    className,
    component: Component = 'div',
    styleRoot = true,
    ...other
  } = props

  return (
    <Component className={clsx(styleRoot && classes.root, className)} ref={ref} {...other}>
      {children}
    </Component>
  )
})

Tag.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  component: PropTypes.elementType,
  styleRoot: PropTypes.bool,
}

export default withStyles(styles)(Tag)
