import * as React from 'react'
import getConfig from 'next/config'
import { formatters } from 'utils'
import StoryblokService from 'utils/storyblok-service'
import ArticleArchiveComponent from './ArticleArchive'

const ArticleArchive = (props) => <ArticleArchiveComponent {...props} />

ArticleArchive.getInitialProps = async (props, ctx) => {
  const page = ctx?.query?.page || 1
  const folder = 'articles'
  const { publicRuntimeConfig } = getConfig()

  const result = await StoryblokService.get(
    `cdn/stories/?starts_with=${folder}/&page=${page}&language=${ctx.locale}`,
    { version: publicRuntimeConfig.STORYBLOK_ENV },
  )

  const articles = result.data.stories.map((article) => formatters.formatArticle(article))
  const featured = [...props.featured].map((article) => formatters.formatArticle(article))

  return {
    ...props,
    articles,
    featured,
  }
}

export default ArticleArchive
