import * as React from 'react'
import camelcaseKeys from 'camelcase-keys'
import htmlDecode from 'utils/htmlDecode'

export const FormatAddress = (address) => {
  const { recipientName, addressLine1, addressLine2, country } = address

  return (
    <div>
      {recipientName && <div>{recipientName}</div>}
      {addressLine1 && <div>{addressLine1}</div>}
      {addressLine2 && <div>{addressLine2}</div>}
      <div>
        {address.postcode} {address.city}
      </div>
      {country && <div>{country}</div>}
    </div>
  )
}

export const formatCurrency = (amount, currencyData, decimals = 0) => {
  // KALLA-76 - Trunc decimals for 0 digits
  const processedAmount =
    decimals === 0 ? Math.trunc(amount) : Number.parseFloat(amount).toFixed(decimals)

  const processedPriceString = currencyData?.displayPriceFormat
    .replace(
      '{price}',
      processedAmount?.toLocaleString(undefined, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
      }),
    )
    .replace('{sign}', currencyData.sign)

  return processedPriceString
}

export const formatPaymentMethods = (pMethods) => {
  if (!pMethods) {
    return []
  }

  const paymentMethods = pMethods.map((p, index) => ({ ...p, ordinal: index + 1 }))

  const klarnaPaymentMethod = paymentMethods?.find(
    (p) => p.type === 'KLARNA-PAYMENT' && p.clientToken,
  )
  const transformedKlarnaPaymentMethods =
    klarnaPaymentMethod?.paymentMethodCategories?.map((p) => ({
      ...p,
      id: `${klarnaPaymentMethod.id}:${p.identifier}`,
      klarna: true,
      paymentMethodCategories: 'klarnaPaymentType',
      imageUrl: klarnaPaymentMethod.imageUrl,
      ordinal: klarnaPaymentMethod?.ordinal,
    })) || []

  let isMac = false

  if (window && window.navigator) {
    isMac =
      window.navigator.userAgent.includes('Mac OS X') ||
      window.navigator.userAgent.includes('Macintosh')
  }

  const transformedPaymentMethods = [
    ...paymentMethods?.filter((p) => p.type !== 'PREPAID' && p.type !== 'KLARNA-PAYMENT'),
    ...transformedKlarnaPaymentMethods,
  ]?.sort((a, b) => a?.ordinal - b?.ordinal)

  if (!isMac) {
    return transformedPaymentMethods.filter((p) => p.type !== 'STRIPE-APPLEPAY')
  }

  return transformedPaymentMethods
}

export const formatArticle = (article) => ({
  ...article,
  tags: article?.tag_list?.map((tag) => ({
    label: tag,
    slug: tag,
  })),
  mediaSrc: article.content?.images?.[0]?.filename || article.mediaSrc || '',
  heading: article.content?.title || article.content?.heading || '',
  excerpt: article.content?.intro || article.content?.excerpt || '',
  showReadMore: article.content?.showReadMore || false,
  publishDate: article.content?.date?.split(' ')?.[0] || '',
  content: '',
  url: (article.full_slug && `/${article.full_slug}`) || '',
  disableLineClamp: article.content?.disableLineClamp || false,
  markets: article.content?.markets || '',
})

export const formatCustomArticle = (article) => ({
  ...article,
  mediaSrc: article.mediaSrc?.filename || '',
  markets: article.markets || '',
  disableLineClamp: true,
})

export const formatLink = (article) => ({
  ...article,
  ...article.content,
  tags: article?.tag_list?.map((tag) => ({
    label: tag,
    slug: tag,
  })),
  publishDate: article.content?.date?.split(' ')?.[0] || '',
})

export const formatSearchResults = (result) => ({
  heading: result.name,
  subheading: result?.content?.intro,
  imageUrl: result?.content?.images?.[0].filename,
  type: result.full_slug.lastIndexOf('products/', 0) === 0 ? 'product' : 'page', // TODO: Until tagging or similar
  url: `/${result.full_slug}`,
})

export const formatProduct = (product) => {
  // We skip variant selection step for the time being
  // const variant = product?.variants?.[0]
  const variant = product?.variants?.find((v) => v.custom2 === 'true')
  const prices = variant?.prices
  const priceFrom = prices?.reduce((acc, curr) => Math.min(acc, curr.price), prices[0]?.price || 0)
  const defaultPriceFrom = prices?.reduce(
    (acc, curr) => Math.min(acc, curr.defaultPrice),
    prices[0]?.defaultPrice || 0,
  )
  const priceTo = prices?.reduce((acc, curr) => Math.max(acc, curr.price), prices[0]?.price || 0)

  return {
    ...product,
    imagesData: product?.images,
    images: product?.imagesData?.filter((image) => image?.tag?.slice(0, 4) === 'Hero'),
    features: product?.images
      ?.filter((image) => image.tag === 'USP')
      .map((image) => ({
        imageUrl: image.imageUrl,
        label: image.description,
      })),
    variantId: variant?.id,
    name: product?.name,
    nameSuffix: product?.custom1 || '',
    prices: prices?.map((p) => ({
      name: p.custom1,
      id: p.id,
      price: p.price,
      defaultPrice: p.defaultPrice,
      priceDescription: p.custom2,
      lineType: p.lineType,
      displayDiscountAmount: p.price === priceFrom ? priceTo - priceFrom : null,
      supplyLongevity: p.custom3,
    })),
    href: `/products/${product?.slugUri}`,
    priceFrom,
    defaultPriceFrom,
    priceTo,
  }
}

export const formatProducts = (products) => {
  return products.map(formatProduct)
}

export const formatReview = (reviewProp) => {
  const review = camelcaseKeys(reviewProp, { deep: true })

  return {
    ...review,
    name: review?.yotpoUserName || '',
    content: htmlDecode(review?.content),
  }
}

export const formatTranslations = (translations) => {
  const transformedProps = {}

  Object.keys(translations).forEach((t) => {
    transformedProps[translations[t].key] = translations[t].translation
  })

  return transformedProps
}

export const formatDate = (date) => {
  let newDate = new Date(date)
  newDate = newDate.toLocaleDateString()

  return newDate
}

export default {
  FormatAddress,
  formatPaymentMethods,
  formatArticle,
  formatCurrency,
  formatCustomArticle,
  formatLink,
  formatSearchResults,
  formatProduct,
  formatProducts,
  formatReview,
  formatTranslations,
}
