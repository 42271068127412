/* eslint-disable import/no-cycle */
import * as React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useEmblaCarousel } from 'embla-carousel/react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Html from 'components/Html'
import { gtm } from 'containers/Gtm/GtmTracker'
import TestimonialsItem from '../TestimonialsItem'
import Reels from './partials/Reels'

const BREAKPOINT_KEY_UP = 'sm'

export const useStyles = makeStyles((theme) => ({
  root: {
    height: '424px',
    '--slide-spacing': `${theme.spacing(1)}px`,
    '--slide-width': '60%',
    transition: 'height 0.25s',
    overflow: 'hidden',
    marginTop: -1,
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: {
      '--slide-spacing': `${theme.spacing(2)}px`,
      '--slide-width': '45%',
      height: '464px',
    },
    [theme.breakpoints.up('md')]: {
      '--slide-spacing': `${theme.spacing(2)}px`,
      '--slide-width': '20%',
      height: '464px',
    },
  },
  fullSize: {
    height: '100%',
    width: '100%',
  },
  emblaRoot: {
    position: 'relative',
    overflow: 'hidden',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(3),
    textAlign: 'center',
    padding: theme.spacing(4, 0, 0),
  },
  wrapper: {
    display: 'flex',
    margin: theme.spacing(0, -1),
    height: '100%',
  },
  slide: {
    position: 'relative',
    flex: '0 0 var(--slide-width)',
    marginLeft: theme.spacing(1),
  },
  expanded: {
    height: 'calc(100dvh - 47px)',
    width: '100%',
    overflow: 'hidden',
    position: 'fixed',
    top: '47px',
    left: 0,
    zIndex: 100,
    backgroundColor: '#EBE4DD',
  },
  disabled: {
    pointerEvents: 'none',
  },
  invisible: {
    visibility: 'hidden',
    height: 0,
    overflow: 'hidden',
    padding: 0,
    margin: 0,
  },
}))

const Testimonials = (props) => {
  const { items, title } = props

  const classes = useStyles(props)

  const [itemExpanded, setItemExpanded] = React.useState(null)
  const [isScrolling, setIsScrolling] = React.useState(false)

  const [emblaRef, embla] = useEmblaCarousel({
    align: 'start',
    containScroll: 'trimSnaps',
    loop: true,
  })

  React.useEffect(() => {
    if (!embla) return
    embla.on('scroll', () => {
      setIsScrolling(true)
    })
    embla.on('settle', () => {
      setIsScrolling(false)
    })
  }, [embla])

  const onSetItemExpanded = React.useCallback((item) => {
    setItemExpanded(item)

    gtm({
      event: 'testimonials',
      ecommerce: {
        testimonial: { step: 'open' },
      },
    })
  }, [])

  const handleCloseItem = React.useCallback(() => {
    setItemExpanded(null)

    gtm({
      event: 'testimonials',
      ecommerce: {
        testimonial: { step: 'close' },
      },
    })
  }, [])

  React.useEffect(() => {
    if (itemExpanded) document.body.style.overflow = 'hidden'
    else document.body.style.overflow = 'auto'
  }, [itemExpanded])

  return (
    <div
      className={clsx(classes.root, {
        [classes.expanded]: itemExpanded,
      })}
    >
      <div
        className={clsx(
          classes.content,
          {
            [classes.invisible]: itemExpanded,
          },
          classes.fullSize,
        )}
      >
        <Html className={classes.text} dangerouslySetInnerHTML={{ __html: title }} />

        <div className={clsx(classes.emblaRoot, classes.fullSize)} ref={emblaRef}>
          <div className={classes.wrapper}>
            {items.map((item, index) => (
              <TestimonialsItem
                key={index}
                className={clsx(classes.slide, {
                  [classes.disabled]: isScrolling,
                })}
                onSetItemExpanded={onSetItemExpanded}
                itemExpanded={itemExpanded}
                itemIndex={index}
                {...item}
              />
            ))}
          </div>
        </div>
      </div>
      {itemExpanded && (
        <Reels
          className={classes.reels}
          itemExpanded={itemExpanded}
          handleCloseItem={handleCloseItem}
          items={items}
        />
      )}
    </div>
  )
}

Testimonials.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string,
}

export default Testimonials
