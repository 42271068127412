import * as React from 'react'
import createSvgIcon from '../utils/createSvgIcon'

export default createSvgIcon(
  <>
    <path d="M3.2334 7.93586C3.2337 8.10181 3.36751 8.23604 3.53258 8.23604C3.53287 8.23604 3.53287 8.23604 3.53317 8.23604C3.69853 8.23575 3.83205 8.10064 3.83176 7.93469L3.82475 4.67087C3.82446 4.50521 3.69094 4.37098 3.52615 4.37069L0.299763 4.36364C0.299471 4.36364 0.299471 4.36364 0.299179 4.36364C0.134105 4.36364 0.000292656 4.49787 4.78608e-07 4.66382C-0.000291699 4.82977 0.133228 4.96488 0.298594 4.96518L2.65798 4.97039C-1.02773 9.55725 -0.692105 16.3438 3.48554 20.5696C5.72208 22.8319 8.6972 24 11.6872 24C13.8975 23.9997 16.1159 23.3615 18.0494 22.0547C18.1868 21.9619 18.223 21.7751 18.131 21.6373C18.0386 21.4996 17.8528 21.462 17.7158 21.5554C13.4043 24.4691 7.59837 23.8769 3.90977 20.1455C-0.0926892 16.0967 -0.379012 9.57091 3.22749 5.2193L3.2334 7.93586Z" />
    <path d="M23.7043 19.0355L21.3602 19.0303C25.0185 14.4484 24.6885 7.66287 20.5482 3.43834C16.6198 -0.570527 10.4777 -1.14092 5.94438 2.08073C5.81042 2.17609 5.77801 2.36358 5.87176 2.49943C5.96608 2.63498 6.15068 2.66814 6.28465 2.57307C10.5816 -0.48133 16.4037 0.0614793 20.1281 3.86203C24.0921 7.90682 24.3756 14.4255 20.8037 18.7723L20.7979 16.0682C20.7976 15.9024 20.6651 15.7683 20.5016 15.7683C20.5013 15.7683 20.5013 15.7683 20.501 15.7683C20.3373 15.7686 20.205 15.9036 20.2053 16.0694L20.2123 19.3295C20.2125 19.4949 20.3448 19.629 20.508 19.6293L23.7031 19.6364C23.7034 19.6364 23.7034 19.6364 23.7037 19.6364C23.8672 19.6364 23.9997 19.5023 24 19.3365C24.0003 19.1707 23.8681 19.0358 23.7043 19.0355Z" />
    <path d="M12.0018 19.6364C12.1654 19.6364 12.2978 19.5014 12.2978 19.3345V16.6176C15.9699 16.4581 18.9091 13.3624 18.9091 9.57983C18.9091 9.42949 18.8004 9.30185 18.6544 9.28092C17.1548 9.06572 15.872 9.21836 14.8172 9.72445C14.7975 8.65757 14.4782 6.24148 12.1813 4.42687C12.0749 4.34256 11.9252 4.34256 11.8188 4.42687C9.52181 6.24148 9.20258 8.65759 9.18285 9.72445C8.12822 9.21825 6.8459 9.06528 5.34566 9.28092C5.19965 9.30185 5.09094 9.42949 5.09094 9.57983C5.09094 13.3636 8.03187 16.4599 11.7057 16.6179V19.3345C11.7057 19.5014 11.8381 19.6364 12.0018 19.6364ZM18.3118 9.84512C18.1788 13.1728 15.5571 15.8582 12.2978 16.0133V15.2716C12.2978 15.2619 12.2978 15.2357 12.2964 15.1944C12.3149 14.7334 12.4889 12.3327 14.106 10.9025C15.1303 9.99634 16.5427 9.64084 18.3118 9.84512ZM12 5.05238C14.1973 6.91808 14.2436 9.44885 14.2197 10.0613C14.0438 10.1794 13.8746 10.3077 13.7145 10.4497C12.7668 11.2895 12.2652 12.3955 12 13.3367C11.7349 12.3955 11.2333 11.2895 10.2856 10.4497C10.1253 10.3077 9.95599 10.1794 9.78003 10.0612C9.7544 9.45036 9.79416 6.92446 12 5.05238ZM5.68826 9.84512C7.45507 9.63996 8.86974 9.99634 9.89409 10.9025C11.5111 12.333 11.6852 14.7337 11.7037 15.1941C11.7022 15.2357 11.7022 15.2619 11.7022 15.2716C11.7022 15.2873 11.7034 15.3029 11.7057 15.3179V16.0136C8.44473 15.8603 5.82126 13.1743 5.68826 9.84512Z" />
  </>,
  'Sustainable',
)
