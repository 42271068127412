import * as React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { chainPropTypes } from '@material-ui/utils'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { render } from 'storyblok-rich-text-react-renderer'
import Media from '@oakwood/oui/Media'
import MediaReveal from '@oakwood/oui/MediaReveal'
import { ASPECT_RATIOS } from 'utils/constants'
import { articleType } from 'utils'
import RouterLink from 'containers/RouterLink'
import BlockButton from 'components/BlockButton'
import Link from 'components/Link'
import LinkArrow from 'components/LinkArrow'
import Typography from 'components/Typography'
import { useTranslations } from 'containers/Translations/TranslationsContext'

export const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.mixins.verticalRhythm(2),
    display: 'flex',
    flexDirection: 'column',
  },
  mediaContainer: {},
  media: {},
  hgroup: {},
  heading: {
    display: 'block',
  },
  subheading: {
    display: 'block',
    color: theme.palette.text.secondary,
  },
  excerpt: {},
  excerptLineClamp: theme.mixins.lineClamp(6),
  ctaArea: {
    marginTop: 'auto',
  },
  cta: {
    // display: 'inline-block',
    marginTop: theme.spacing(2),
  },
  mediaPlacementEnd: {
    marginTop: theme.spacing(-2),

    '& > *:first-child': {
      order: 1,
      marginTop: theme.spacing(5),
    },
  },
}))

const ArticleCard = React.forwardRef(function ArticleCard(props, ref) {
  const {
    article,
    id,
    className,
    disableLineClamp = false,
    excerpt: excerptProp,
    heading: headingProp,
    mediaPlacement = 'start',
    mediaSrc: mediaSrcProp,
    subheading: subheadingProp,
    url: urlProp,
    ...other
  } = props
  const classes = useStyles(props)

  // Setup props via `article` data type or custom fields.
  const url = article ? `/articles/${article.slug}` : urlProp
  const excerpt = article ? article.excerpt : excerptProp
  const heading = article ? article.heading : headingProp
  const mediaSrc = article ? article.mediaSrc : mediaSrcProp
  const subheading = article ? article.tags?.map((tag) => tag.label).join(' • ') : subheadingProp
  const t = useTranslations()
  const linkProps = {}
  let MediaWrapperComponent = React.Fragment
  let HeadingComponent = Typography

  if (url) {
    linkProps.component = RouterLink
    linkProps.href = url

    MediaWrapperComponent = BlockButton
    HeadingComponent = Link
  }

  return (
    <article
      className={clsx(
        classes.root,
        {
          [classes.mediaPlacementEnd]: mediaPlacement === 'end',
        },
        className,
      )}
      ref={ref}
      {...other}
      key={id}
    >
      {mediaSrc && (
        <MediaWrapperComponent {...linkProps}>
          <MediaReveal className={classes.mediaContainer} {...ASPECT_RATIOS.article}>
            <Media className={classes.media} src={mediaSrc} />
          </MediaReveal>
        </MediaWrapperComponent>
      )}

      <div className={classes.hgroup}>
        <HeadingComponent
          className={classes.heading}
          component="p"
          variant="h5"
          gutterBottom
          {...linkProps}
        >
          {heading}
        </HeadingComponent>

        {subheading && (
          <Typography className={classes.subheading} variant="subtitle2">
            {subheading}
          </Typography>
        )}
      </div>

      <Typography
        component="div"
        className={clsx(classes.excerpt, !disableLineClamp && classes.excerptLineClamp)}
      >
        {render(excerpt)}
      </Typography>

      {url && (
        <div className={classes.ctaArea}>
          <LinkArrow className={classes.cta} {...linkProps}>
            {t('Web.ArticleCard.ReadMore')}
          </LinkArrow>
        </div>
      )}
    </article>
  )
})

ArticleCard.propTypes = {
  article: chainPropTypes(articleType, (props) => {
    if (!props.article && !props.heading && !props.excerpt) {
      return new Error('UI: Either `article` or `heading` + `excerpt` props must be specified.')
    }
    return null
  }),
  className: PropTypes.string,
  disableLineClamp: PropTypes.bool,
  excerpt: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  heading: PropTypes.string,
  mediaPlacement: PropTypes.oneOf(['start', 'end']),
  mediaSrc: PropTypes.string,
  subheading: PropTypes.string,
  url: PropTypes.string,
  id: PropTypes.number,
}

export default ArticleCard
