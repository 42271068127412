import * as React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Newsletter from 'containers/Newsletter'
import Section from 'components/Section'
// eslint-disable-next-line import/no-cycle
import * as blockVariants from 'blocks'
import Backdrop from 'components/Backdrop'
import Typography from 'components/Typography'
import { Close } from 'components/icons'
import IconButton from 'components/IconButton'

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  backdrop: {
    zIndex: theme.zIndex.modal,
  },
  topBar: {
    display: 'flex',
    padding: theme.spacing(2),
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  sectionBorder: {
    border: 0,
    '&>*': {
      border: 0,
    },
  },
  menu: {
    flex: '1 1 250px',
    padding: 'var(--coa-section-spacing) var(--coa-container-spacing)',
    margin: -1,
  },
  newsletterArea: {
    flexBasis: '450px',
  },
  newsletter: {
    maxWidth: 'var(--coa-content-max-width)',
  },
}))

const POPUP_ENTER_DELAY = 2000

function Popup(props) {
  const { blocks, heading, width, sectionBorder } = props

  const [open, setOpen] = React.useState(false)
  const classes = useStyles(props)

  const createBlock = (block) => {
    const { component, _uid: uid, ...blockProps } = block
    const Block = blockVariants[component] || <div>No block</div>

    return <Block key={uid} {...blockProps} />
  }

  React.useEffect(() => {
    const popupFromStorage = localStorage.getItem('POPUP')

    if (popupFromStorage === 'disable') setOpen(false)

    if (!popupFromStorage) {
      setTimeout(() => {
        setOpen(true)
      }, POPUP_ENTER_DELAY)
    }
  }, [])

  const handleClose = React.useCallback(() => {
    localStorage.setItem('POPUP', 'disable')
    setOpen(false)
  }, [])

  return (
    <Section>
      <Backdrop open={open} className={classes.backdrop}>
        <Section
          className={clsx(classes.root, {
            [classes.sectionBorder]: !sectionBorder,
          })}
          style={{ width: Number(width) }}
        >
          <div className={classes.topBar}>
            <Typography variant={'subtitle1'}>{heading}</Typography>
            <IconButton onClick={handleClose} edge="start" aria-label="Close popup">
              <Close />
            </IconButton>
          </div>
          {blocks && blocks.length && blocks.map((block) => createBlock(block))}

          <div className={clsx(classes.menu, classes.newsletterArea)}>
            <Newsletter className={classes.newsletter} />
          </div>
        </Section>
      </Backdrop>
    </Section>
  )
}

Popup.propTypes = {
  blocks: PropTypes.array,
  heading: PropTypes.string,
  width: PropTypes.string,
  sectionBorder: PropTypes.bool,
}

export default Popup
