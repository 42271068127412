// @inheritedComponent AppBar

import * as React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useRouter } from 'next/router'
import { useGlobal, useCheckout, useUser } from 'api'
import { useDimensions } from 'utils'
import RouterLink from 'containers/RouterLink'
import BrandIcon from 'components/icons/Brand'
import CartIcon from 'components/icons/Cart'
import MenuIcon from 'components/icons/Menu'
import AppBar from 'components/AppBar'
import IconButton from 'components/IconButton'
import Toolbar from 'components/Toolbar'
import { useApp } from '../AppContext'
import LoginPerson from '../../../components/icons/LoginPerson'

export const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbarPush: {
    marginLeft: 'auto',
  },
  salesToolbar: {
    padding: theme.spacing(0.5, 2),
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    textAlign: 'center',
  },
  menuToolbar: {
    '--coa-toolbar-min-height': '46px',
  },
  menuButton: {},
  brandButton: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  actionButtonIcon: {
    fontSize: 27,
  },
  brandIcon: {
    width: 'auto',
    transform: 'translateY(-4px)', // Optically align to middle.
  },
  cartButton: {},
  loginButton: {
    paddingRight: theme.spacing(2),
  },
  navTransparent: {
    backgroundColor: 'transparent',
  },
  navDefault: {
    backgroundColor: theme.palette.background.default,
  },
}))

const AppHeader = React.memo(function AppHeader(props) {
  const {
    className,
    isCartMenuOpen,
    isNavMenuOpen,
    isSomeMenuOpen,
    onCartMenuToggle,
    isLoggedIn,
    onLoginDialogToggle,
    onNavMenuToggle,
    ...other
  } = props
  const classes = useStyles(props)

  const { totals } = useCheckout()
  const { settings, checkoutEnabled } = useGlobal()
  const [rootRef, dimensions] = useDimensions()
  const router = useRouter()

  // const [bgColor, setBgColor] = React.useState(false)

  // React.useEffect(() => {
  //   window.addEventListener('scroll', () => {
  //     const scroll = window.scrollY
  //     // eslint-disable-next-line no-unused-expressions
  //     scroll >= 5 ? setBgColor(true) : setBgColor(false)
  //   })
  // }, [bgColor])

  const onAccountToggle = React.useCallback(() => {
    if (!isLoggedIn) onLoginDialogToggle()
    else router.push('/account')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, onLoginDialogToggle])

  return (
    <AppBar
      className={clsx(
        classes.root,
        className,
        classes.navDefault, //  bgColor ? classes.navDefault : classes.navTransparent,
      )}
      position="sticky"
      ref={rootRef}
      {...other}
    >
      <style
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `
          :root {
            --coa-header-height: ${dimensions.height}px;
          }
        `,
        }}
      />

      {settings.globalSalesBanner && (
        <div
          className={classes.salesToolbar}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: settings.globalSalesBanner }}
        />
      )}

      <Toolbar className={classes.menuToolbar}>
        <IconButton
          className={classes.menuButton}
          onClick={onNavMenuToggle}
          edge="start"
          size="small"
          aria-haspopup="true"
          aria-expanded={isNavMenuOpen}
          aria-label="Toggle main menu"
        >
          <MenuIcon className={classes.actionButtonIcon} />
        </IconButton>

        <IconButton
          className={classes.brandButton}
          component={RouterLink}
          size="small"
          href="/"
          aria-label="Go to the homepage"
        >
          <BrandIcon className={classes.brandIcon} />
        </IconButton>

        <div className={classes.toolbarPush} />

        <IconButton
          className={classes.loginButton}
          onClick={onAccountToggle}
          edge="end"
          id="app-login-dialog"
          size="small"
          aria-label="Toggle login dialog"
        >
          <LoginPerson className={classes.actionButtonIcon} />
        </IconButton>

        {checkoutEnabled && (
          <IconButton
            className={classes.cartButton}
            onClick={onCartMenuToggle}
            edge="end"
            size="small"
            aria-haspopup="true"
            aria-expanded={isCartMenuOpen}
            aria-label="Toggle cart menu"
          >
            <CartIcon className={classes.actionButtonIcon} amount={totals?.totalQuantity} />
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
  )
})

AppHeader.propTypes = {
  className: PropTypes.string,
  isCartMenuOpen: PropTypes.bool,
  isNavMenuOpen: PropTypes.bool,
  isSomeMenuOpen: PropTypes.bool,
  onCartMenuToggle: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  onLoginDialogToggle: PropTypes.func,
  onNavMenuToggle: PropTypes.func,
}

function AppHeaderContainer(props) {
  const {
    hideHeader,
    isCartMenuOpen,
    isNavMenuOpen,
    isSomeMenuOpen,
    onCartMenuToggle,
    onLoginDialogToggle,
    onNavMenuToggle,
  } = useApp()

  const { isAuthenticated } = useUser()

  if (hideHeader) {
    return null
  }

  return (
    <AppHeader
      isCartMenuOpen={isCartMenuOpen}
      isNavMenuOpen={isNavMenuOpen}
      isSomeMenuOpen={isSomeMenuOpen}
      onCartMenuToggle={onCartMenuToggle}
      isLoggedIn={isAuthenticated}
      onLoginDialogToggle={onLoginDialogToggle}
      onNavMenuToggle={onNavMenuToggle}
      {...props}
    />
  )
}

export default AppHeaderContainer
