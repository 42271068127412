export { default } from '@material-ui/core/AccordionSummary'

export const styles = (theme) => ({
  root: {
    ...theme.mixins.gutters('var(--coa-toolbar-spacing)'),
    ...theme.mixins.toolbar,
    '&$expanded': {
      minHeight: 'var(--coa-toolbar-min-height)',
    },
  },
  content: {
    margin: '12px 0',
    '&$expanded': {
      margin: '12px 0',
    },
  },
})
