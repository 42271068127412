/* eslint-disable import/no-cycle */
import { cdnUrl } from 'utils/storyblok'
import StoryblokService from 'utils/storyblok-service'

export const media = (obj) => {
  const isVideo = obj.filename?.indexOf('.mp4') > -1

  if (isVideo) {
    return {
      component: 'video',
      src: obj.filename,
      autoPlay: true,
      loop: true,
      playsInline: true,
      muted: true,
      lazy: false,
    }
  }

  return {
    component: 'picture',
    alt: obj.alt || '',
    breakpoints: {
      xs: cdnUrl(obj.filename),
    },
  }
}

export const hero = (propsArg) => {
  const props = { ...propsArg }

  if (props.mediaXs) {
    props.mediaProps = media(props.mediaXs)

    delete props.mediaXs
  }

  if (props.backgroundMedia && props.backgroundMedia?.filename) {
    props.backgroundMediaProps = media(props.backgroundMedia)

    delete props.backgroundMedia
  }

  if (props.text) {
    props.text =
      typeof props.text === 'string'
        ? props.text
        : StoryblokService?.client?.richTextResolver?.render(props.text)
  }

  return props
}
