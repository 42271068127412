import * as React from 'react'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import { useGlobal } from 'api'
import ProductGroupComponent from './ProductGroup'

const ProductGroup = (props) => {
  const { products } = useGlobal()

  const router = useRouter()

  if (props.products) {
    return <ProductGroupComponent products={props.products} />
  }

  // TODO: Investigate if this below could be removed
  const filteredProducts = []
  if (router.query?.codes) {
    router.query?.codes?.split(',')?.forEach((productCode) => {
      filteredProducts.push(products.find((product) => product.productCode === productCode.trim()))
    })
  } else {
    props.productCodes?.split(',')?.forEach((productCode) => {
      filteredProducts.push(products.find((product) => product.productCode === productCode.trim()))
    })
  }

  const filters = props.filters?.map((filter) => {
    const { name, productCodes, options } = filter

    return {
      name,
      productCodes,
      options,
    }
  })

  const transformProps = {
    ...props,
    filters,
    products: filteredProducts,
  }

  return <ProductGroupComponent {...transformProps} />
}

ProductGroup.propTypes = {
  productCodes: PropTypes.string,
  ctaButton: PropTypes.string,
  products: PropTypes.array,
  filters: PropTypes.array,
}

export default ProductGroup
