import * as React from 'react'
import createSvgIcon from '../utils/createSvgIcon'

export default createSvgIcon(
  <g
    transform="translate(0.000000,641.000000) scale(0.100000,-0.100000)"
    fill="#000000"
    stroke="none"
  >
    <path d="M3100 5323 c-589 -29 -1156 -305 -1538 -748 -690 -801 -712 -1968 -52 -2795 78 -97 254 -272 350 -347 290 -226 637 -377 1009 -440 151 -26 544 -25 686 1 412 74 758 234 1063 491 333 279 588 685 697 1110 83 322 93 651 30 971 -161 824 -780 1480 -1593 1690 -195 50 -456 77 -652 67z m440 -154 c431 -73 809 -268 1115 -574 314 -314 504 -686 581 -1140 24 -141 25 -461 1 -612 -56 -358 -209 -704 -434 -983 -83 -102 -271 -284 -370 -357 -290 -215 -616 -345 -988 -394 -58 -7 -165 -10 -300 -6 -174 4 -231 10 -334 32 -885 186 -1531 885 -1641 1775 -15 122 -12 371 5 502 77 587 404 1110 901 1444 263 176 613 300 939 334 135 13 381 4 525 -21z" />
    <path d="M2902 4518 l-40 -51 27 -22 c14 -12 72 -56 128 -98 56 -42 104 -81 107 -85 3 -5 -25 -12 -63 -16 -157 -14 -364 -98 -507 -204 -230 -171 -385 -421 -434 -701 -17 -93 -14 -301 5 -400 50 -260 199 -500 413 -664 59 -46 216 -137 237 -137 8 0 59 111 52 115 -1 1 -38 21 -81 44 -241 128 -411 347 -483 621 -35 133 -37 325 -4 452 44 171 106 293 214 419 151 177 386 302 625 332 56 7 56 6 -60 -145 l-92 -120 34 -29 c65 -55 64 -55 117 14 39 49 149 193 285 373 5 7 -17 30 -64 66 -40 31 -141 107 -225 171 l-151 116 -40 -51z" />
    <path d="M3716 4059 c-15 -28 -24 -54 -20 -58 5 -4 38 -28 74 -53 36 -25 99 -81 141 -125 175 -184 269 -416 269 -668 0 -409 -256 -777 -636 -914 -83 -30 -242 -65 -269 -59 -9 2 21 46 85 125 55 67 100 126 100 130 0 10 -84 76 -95 75 -5 -1 -115 -133 -322 -389 l-34 -41 211 -171 c116 -95 216 -174 223 -176 6 -2 29 18 49 44 l38 49 -135 108 -134 109 37 7 c20 3 71 12 112 18 407 67 761 397 866 808 49 188 42 434 -17 612 -54 165 -148 319 -269 441 -72 73 -211 179 -234 179 -6 0 -24 -23 -40 -51z" />
  </g>,
  'Replay',
  '0 0 641.000000 641.000000',
  'xMidYMid meet',
)
