import * as React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import withStyles from '@material-ui/core/styles/withStyles'
import Collapse from '@material-ui/core/Collapse'
import { menuLinkType } from 'utils'
import RouterLink from 'containers/RouterLink'
import ExpandMoreIcon from 'components/icons/ExpandMore'
import LinkArrowIcon from 'components/icons/LinkArrow'
import IconButton from 'components/IconButton'
import Link from 'components/Link'
import Button from 'components/Button'

export const styles = (theme) => ({
  submenu: {
    paddingLeft: theme.spacing(2),
    borderLeft: `1px solid ${theme.palette.divider}`,
  },
  menuList: {},
  menuListItem: {},
  menuListItemActions: {
    position: 'relative',
    padding: theme.spacing(1, 0),
    minHeight: 36,
  },
  menuListItemPrimaryAction: {
    display: 'flex',
    alignItems: 'center',
  },
  menuListItemSecondaryAction: {
    position: 'absolute',
    right: 0,
    top: '50%',
    borderRadius: '100%',
    transform: 'translateY(-50%)',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  menuListItemIcon: {
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  menuListItemIconActive: {
    transform: 'rotate(180deg)',
  },
  menuListItemArrow: {
    fontSize: 17,
  },
  menuButton: {
    padding: 0,
    textAlign: 'start',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent',
    },
  },
})

export function getMenuLinkProps(menuLink) {
  const menuLinkProps = {}
  if (menuLink.url && menuLink.url !== '/undefined') {
    menuLinkProps.component = RouterLink
    menuLinkProps.href = menuLink.url
  } else {
    menuLinkProps.component = 'span'
    menuLinkProps.underline = 'none'
  }

  return menuLinkProps
}

function AppFooterMenuItem(props) {
  const {
    classes,
    level = 0,
    menuLink,
    menuLinkLevelProps: menuLinkLevelPropsProp = {},
    menuLinkProps: menuLinkPropsProp = {},
  } = props

  const [expanded, setExpanded] = React.useState(false)
  const handleClick = React.useCallback(() => {
    setExpanded((prev) => !prev)
  }, [])

  const submenu = menuLink.links
  const hasSubmenu = submenu?.length > 0

  const menuLinkLevelProp = menuLinkLevelPropsProp[level] || {}
  const { className: menuLinkClassName, ...menuLinkProps } = menuLinkPropsProp
  const { className: menuLinkLevelClassName, ...menuLinkLevelProps } = menuLinkLevelProp

  const handleOpenEbbot = () => {
    if (window && window.Ebbot) {
      window.Ebbot?.openChatWindow()
    }
  }

  return (
    <li className={classes.menuListItem}>
      <div className={classes.menuListItemActions}>
        {menuLink?.url?.includes('ebbot') ? (
          <Button
            variant="text"
            onClick={handleOpenEbbot}
            className={clsx(
              classes.menuListItemPrimaryAction,
              classes.menuButton,
              menuLinkClassName,
              menuLinkLevelClassName,
            )}
          >
            {menuLink.label}
          </Button>
        ) : (
          <Link
            className={clsx(
              classes.menuListItemPrimaryAction,
              menuLinkClassName,
              menuLinkLevelClassName,
            )}
            color="inherit"
            {...getMenuLinkProps(menuLink)} // 1. Get default props
            {...menuLinkProps} // 2. Get default override props
            {...menuLinkLevelProps} // 3. Get level specific override props
          >
            {menuLink.label}

            {menuLink?.url?.includes('http') && (
              <LinkArrowIcon className={classes.menuListItemArrow} />
            )}
          </Link>
        )}

        {hasSubmenu && (
          <IconButton
            className={classes.menuListItemSecondaryAction}
            onClick={handleClick}
            color="inherit"
            size="small"
            aria-label="Expand menu"
            aria-haspopup="true"
            aria-expanded={expanded}
          >
            <ExpandMoreIcon
              className={clsx(classes.menuListItemIcon, {
                [classes.menuListItemIconActive]: expanded,
              })}
            />
          </IconButton>
        )}
      </div>

      {hasSubmenu && (
        <Collapse classes={{ wrapperInner: classes.submenu }} in={expanded} unmountOnExit>
          <ul className={classes.menuList}>
            {submenu.map((sublink, idx) => (
              <AppFooterMenuItem
                key={idx}
                classes={classes}
                level={level + 1}
                menuLink={sublink}
                menuLinkLevelProps={menuLinkLevelPropsProp}
                menuLinkProps={menuLinkPropsProp}
              />
            ))}
          </ul>
        </Collapse>
      )}
    </li>
  )
}

AppFooterMenuItem.propTypes = {
  classes: PropTypes.object.isRequired,
  level: PropTypes.number,
  menuLink: menuLinkType.isRequired,
  menuLinkLevelProps: PropTypes.object,
  menuLinkProps: PropTypes.object,
}

export default withStyles(styles)(AppFooterMenuItem)
