import * as React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Image from 'next/image'
import ProductFlowControl from 'containers/ProductFlowControl'
import ProductFlowCta from 'containers/ProductFlowCta'
import Typography from 'components/Typography'

const BREAKPOINT_KEY_UP = 'sm'

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridGap: theme.spacing(3),
  },
  mediaContainer: {
    ...theme.mixins.contain(160),
  },
  media: {},
  text: {
    paddingRight: theme.spacing(4),
  },
  articleText: {
    paddingTop: 16,
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: {
      height: '17vh',
    },
    verticalAlign: 'text-top',
  },
  heading: {
    ...theme.typography.subtitle1,
    ...theme.typography.h5,
  },
}))

const PriceCard = React.forwardRef(function PriceCard(props, ref) {
  const { className, product, price, ...other } = props
  const classes = useStyles(props)

  const imageUrl = product?.imagesData?.find((p) => p.tag === `GC${price.price}`)?.imageUrl

  return (
    <article className={clsx(classes.root, className)} ref={ref} {...other}>
      <Typography className={classes.heading} paragraph>
        {price.custom1}
      </Typography>

      {imageUrl !== undefined && (
        <Image
          className={classes.mediaContainer}
          src={imageUrl}
          objectfit={'contain'}
          priority
          width={395}
          height={152}
          alt="Price card image"
        />
      )}

      <Typography className={classes.articleText} paragraph>
        {price.custom3}
      </Typography>

      <ProductFlowControl product={product} directPrice={price}>
        <ProductFlowCta showAmount={false} />
      </ProductFlowControl>
    </article>
  )
})

PriceCard.propTypes = {
  className: PropTypes.string,
  product: PropTypes.array,
  price: PropTypes.array,
}

export default PriceCard
