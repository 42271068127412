/* eslint-disable import/no-cycle */
import * as React from 'react'
import StoryblokService from 'utils/storyblok-service'
import TestimonialsComponent from './Testimonials'

const transformProps = (props) => {
  const transformedProps = { ...props }

  transformedProps.title = StoryblokService?.client?.richTextResolver?.render(
    transformedProps.title,
  )

  return transformedProps
}

const Testimonials = (props) => <TestimonialsComponent {...transformProps(props)} />

export default Testimonials
