import * as React from 'react'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useFormit } from '@oakwood/formit'
import { articleType, extractUniqueArticleTagsByKey } from 'utils'
import Container from 'components/Container'
import MenuItem from 'components/MenuItem'
import Section from 'components/Section'
import TextField from 'components/TextField'
import Typography from 'components/Typography'
import LinkArchiveCard from './partials/LinkArchiveCard'

const BREAKPOINT_KEY_UP = 'sm'

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: -1,
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  header: {
    textAlign: 'center',
  },
  heading: {
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: theme.typography.h3,
  },
  preamble: {
    ...theme.mixins.contain('var(--coa-content-max-width)'),
    marginTop: 'var(--coa-container-spacing)',
  },
  actions: {
    ...theme.mixins.verticalRhythm(1),
    ...theme.mixins.contain('md'),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  main: {
    marginTop: 'var(--coa-section-spacing)',
  },
  list: {
    ...theme.mixins.contain(770),
    display: 'grid',
    gridGap: theme.spacing(2),
    marginTop: 'var(--coa-section-spacing)',
  },
  listItem: {},
}))

const FILTER_BY_KEY = 'slug'

function LinkArchive(props) {
  const { articles, heading, text, regex } = props
  const classes = useStyles(props)

  const { getFieldProps, values } = useFormit({
    initialValues: {
      filterBy: 'all',
      sortBy: 'date',
    },
  })

  const articleTags = React.useMemo(
    () => extractUniqueArticleTagsByKey(articles, FILTER_BY_KEY, regex && new RegExp(regex)),
    [articles, regex],
  )

  let visibleArticles = articles
  if (values.filterBy !== 'all') {
    visibleArticles = visibleArticles.filter((a) =>
      a.tags.some((at) => at[FILTER_BY_KEY] === values.filterBy),
    )
  }

  return (
    <Section className={classes.root} gutters="padding">
      <Container className={classes.header} component="header" maxWidth="md">
        <Typography className={classes.heading} component="h1" variant="h4">
          {heading}
        </Typography>

        {text && <Typography className={classes.preamble}>{text}</Typography>}
      </Container>

      <Container className={classes.main}>
        <div className={classes.actions}>
          <TextField
            {...getFieldProps('filterBy')}
            size="small"
            variant="outlined"
            fullWidth
            select
          >
            <MenuItem value="all">All</MenuItem>
            {articleTags?.map((tag, idx) => (
              <MenuItem key={idx} value={tag.slug}>
                {tag.label}
              </MenuItem>
            ))}
          </TextField>
        </div>

        <div className={classes.list}>
          {visibleArticles.map((article, idx) => (
            <LinkArchiveCard key={idx} className={classes.listItem} article={article} index={idx} />
          ))}
        </div>
      </Container>
    </Section>
  )
}

LinkArchive.propTypes = {
  articles: PropTypes.arrayOf(articleType).isRequired,
  heading: PropTypes.string.isRequired,
  text: PropTypes.string,
  regex: PropTypes.string,
}

export default LinkArchive
