import * as React from 'react'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'
import PriceCard from 'containers/PriceCard'
import Section from 'components/Section'

const BREAKPOINT_KEY_UP = 'sm'

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: -1,
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  list: {
    display: 'grid',
    overflow: 'hidden',
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: {
      gridTemplateColumns: 'repeat(auto-fit, minmax(400px, 1fr))',
    },
  },
  listItem: {
    padding: 'var(--coa-section-spacing) var(--coa-container-spacing)',
    margin: '0 -1px -1px 0 ',
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  product: theme.mixins.contain(400),
}))

function ProductGroupVariants(props) {
  const { product, ctaType } = props

  const prices = product?.variants?.[0].prices
  const classes = useStyles(props)
  return (
    <Section className={classes.root}>
      <div className={classes.list}>
        {prices?.map((price, idx) => (
          <div key={idx} className={classes.listItem}>
            <PriceCard
              className={classes.product}
              product={product}
              price={price}
              ctaType={ctaType}
            />
          </div>
        ))}
      </div>
    </Section>
  )
}

ProductGroupVariants.propTypes = {
  ctaType: PropTypes.oneOf(['link', 'button']),
  product: PropTypes.array.isRequired,
}

export default ProductGroupVariants
