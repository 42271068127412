import { darken, lighten } from '@material-ui/core/styles/colorManipulator'

const styles = (theme) => {
  const light = theme.palette.type === 'light'
  const backgroundColor = light ? theme.palette.common.white : theme.palette.background.paper
  const backgroundColor2 = light ? darken(backgroundColor, 0.02) : lighten(backgroundColor, 0.04)

  return {
    root: {
      border: '1px solid currentColor',
      backgroundColor,
      '&:hover': {
        backgroundColor: backgroundColor2,
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor,
        },
      },
      '&$focused': {
        backgroundColor: backgroundColor2,
      },
    },
    underline: {
      '&:before, &:after': {
        display: 'none',
      },
    },
  }
}

export default styles
