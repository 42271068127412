/* eslint-disable import/no-cycle */
import * as React from 'react'
import getConfig from 'next/config'
import * as blockVariants from 'blocks'
import StoryblokService from './storyblok-service'

export const useStoryblokEditor = (uid, callback) => {
  const handleInput = React.useCallback(
    (event) => {
      // eslint-disable-next-line no-underscore-dangle
      if (event.story.content._uid === uid) {
        const newContent = window.storyblok.addComments(event.story.content, event.story.id)
        callback(newContent)
      }
    },
    [callback, uid],
  )

  React.useEffect(() => {
    if (window.storyblok) {
      window.storyblok.init()
      window.storyblok.on(['change', 'published'], () => window.location.reload())
      window.storyblok.on('input', handleInput)
    }
  }, [handleInput])
}

export const useStoryblok = (page) => {
  const [content, setContent] = React.useState(page?.data?.story?.content)
  // eslint-disable-next-line no-underscore-dangle
  useStoryblokEditor(content?._uid, setContent)

  React.useEffect(() => {
    setContent(page?.data?.story?.content)
  }, [page])

  return content
}

export const cdnUrl = (image, params = []) => {
  if (image?.indexOf('storyblok.com') < 0) {
    return image
  }

  const path = image?.replace('https://a.storyblok.com', '')?.slice(1) || ''
  const paths = ['https://img2.storyblok.com', ...params, path]

  return paths.join('/')
}

export const fetchGlobalSettings = async (props) => {
  const { locale } = props
  const { publicRuntimeConfig } = getConfig()

  const global = await StoryblokService.get(`cdn/stories/global?language=${locale}`, {
    cv: Date.now(),
    resolve_links: 'url',
    version: publicRuntimeConfig.STORYBLOK_ENV,
  })

  const formatMenu = (item) => ({
    label: item.label || '',
    url: item.url.url || `/${item.url.story?.full_slug}` || '',
    links: item.links?.map(formatMenu) || [],
    markets: item.markets || '',
  })

  const formatIcons = (icon) => {
    if (!icon.markets || icon?.markets?.includes(locale.replace('redirect', 'eu'))) {
      return { ...icon }
    }

    return null
  }

  const menuPrimary = global.data.story.content.menuPrimary?.map(formatMenu) || []
  const menuSecondary = global.data.story.content.menuSecondary?.map(formatMenu) || []
  const menuFooter = global.data.story.content.menuFooter?.map(formatMenu) || []
  const menuPopularSearch = global.data.story.content.menuPopularSearch?.map(formatMenu) || []
  const share = global.data.story.content.Share || []
  const delivery =
    global.data.story.content.Delivery?.map(formatIcons)?.filter((icon) => icon !== null) || []
  const payment =
    global.data.story.content.Payment?.map(formatIcons)?.filter((icon) => icon !== null) || []
  const menuSecondaryRemake = global.data.story.content.menuSecondaryRemake?.map(formatMenu) || []
  const menuPrimaryRemake = global.data.story.content.menuPrimaryRemake?.map(formatMenu) || []
  const menuVersion = global.data.story.content.menuVersion || 'v2'
  const accountProductCodes =
    global.data.story.content.accountProductCodes || '101, 102, 103, 104, 105'

  const globalSalesBanner = global.data.story.content.globalSalesBanner || ''
  const purchaseUsps = global.data.story.content.purchaseUsps?.split('\n') || []
  const termsPageUrl =
    global.data.story.content.termsPageUrl?.url ||
    `/${global.data.story.content.termsPageUrl?.url.story?.full_slug}` ||
    ''

  const images = {
    userReferralHero:
      (global.data.story.content.userReferralHeroImage?.filename &&
        cdnUrl(global.data.story.content.userReferralHeroImage.filename)) ||
      '',
    userReferralStep2:
      (global.data.story.content.userReferralStep2Image?.filename &&
        cdnUrl(global.data.story.content.userReferralStep2Image.filename)) ||
      '',
    notFound:
      (global.data.story.content.notFoundImage?.filename &&
        cdnUrl(global.data.story.content.notFoundImage.filename)) ||
      '',
    receipt:
      (global.data.story.content.receiptImage?.filename &&
        cdnUrl(global.data.story.content.receiptImage.filename)) ||
      '',
    emptyCart:
      (global.data.story.content.emptyCartImage?.filename &&
        cdnUrl(global.data.story.content.emptyCartImage.filename)) ||
      '',
    ambassadorOnboarding:
      (global.data.story.content.ambassadorOnboardingImage?.filename &&
        cdnUrl(global.data.story.content.ambassadorOnboardingImage.filename)) ||
      '',
    myBiomaActivation:
      (global.data.story.content.myBiomaActivationImage?.filename &&
        cdnUrl(global.data.story.content.myBiomaActivationImage.filename)) ||
      '',
  }

  const quizAllowedProductCodes = global.data.story.content.allowedProductCodes

  const popup = {
    blocks: global.data.story.content.popupBlocks || [],
    heading: global.data.story.content.popupHeading || '',
    width: global.data.story.content.popupWidth || '80%',
    sectionBorder: global.data.story.content.popupSectionBorder || false,
  }

  const cmsProps = {
    menus: {
      menuVersion,
      primary: menuPrimary,
      secondary: menuSecondary,
      popularSearch: menuPopularSearch,
      footer: menuFooter,
      share,
      delivery,
      payment,
      primaryRemake: menuPrimaryRemake,
      secondaryRemake: menuSecondaryRemake,
    },
    settings: {
      globalSalesBanner,
      purchaseUsps,
      termsPageUrl,
      quizAllowedProductCodes,
      accountProductCodes,
    },
    images,
    quiz: {
      quizAllowedProductCodes,
    },
    popup,
  }

  return cmsProps
}

export const parseBlockData = async (block, ctx) => {
  const { component } = block
  const Block = blockVariants[component]

  if (!Block || !Block.getInitialProps) {
    return block
  }

  const props = await Block.getInitialProps(block, ctx)

  return {
    ...block,
    ...props,
  }
}

export const parseSeoData = (pageData) => {
  return {
    title: pageData?.data?.story?.content?.seoTitle || 'KÄLLA',
    description: pageData?.data?.story?.content?.seoDescription || '',
    image: pageData?.data?.story?.content?.seoImage || {},
    keywords: pageData?.data?.story?.content?.keywords || '',
    productCode: pageData?.data?.story?.content?.seoProductCode || '',
    productGroup: pageData?.data?.story?.content?.productGroup?.replaceAll(' ', '_') || '',
  }
}

export const parsePageData = async (pageData, ctx, blocksKey = 'body') => {
  const blocks = pageData?.data?.story?.content[blocksKey]

  if (!blocks || blocks.length < 1) {
    return pageData
  }

  const filterBlocks = (value) => {
    const items = value.filter((block) => {
      let marketAssigned = true
      if (block.markets !== undefined && block.markets.trim() !== '') {
        marketAssigned = false
        block.markets.split(',')?.forEach((market) => {
          // TODO: MARKET HANDLING IS NOT WORKING CORRECTLY IN ROUTER, once market handling is fixed below line is correct to be used
          // if (market.trim() === ctx.res.req.cookies.MARKET) marketAssigned = true

          if (market.trim() === ctx.locale.replace('redirect', 'eu')) marketAssigned = true
        })
      }

      if (block.items && Array.isArray(block.items) && block.items.length > 0) {
        const subItems = filterBlocks(block.items)
        block.items = subItems
      }
      return marketAssigned
    })
    return items
  }

  const filteredBlocks = filterBlocks(blocks)

  const parsedBlocks = await Promise.all(filteredBlocks.map((block) => parseBlockData(block, ctx)))
  const page = {
    ...pageData,
  }
  page.data.story.content[blocksKey] = parsedBlocks

  const seo = parseSeoData(page)

  return {
    ...page,
    seo,
    disableAppBase: page?.data?.story?.content?.disableAppBase || false,
    enablePopup: page?.data?.story?.content?.enablePopup || false,
  }
}

export default useStoryblok
