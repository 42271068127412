import * as React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useEmblaCarousel } from 'embla-carousel/react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Avatar from '@material-ui/core/Avatar'
import { articleType } from 'utils'
import RouterLink from 'containers/RouterLink'
import BlockButton from 'components/BlockButton'
import Typography from 'components/Typography'

const BREAKPOINT_KEY_UP = 'sm'

export const useStyles = makeStyles((theme) => ({
  root: {
    '--slide-spacing': `${theme.spacing(1)}px`,
    '--slide-width': '100px',
    overflow: 'hidden',
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: {
      '--slide-spacing': `${theme.spacing(2)}px`,
      '--slide-width': '130px',
    },
  },
  wrapper: {
    display: 'flex',
    margin: theme.spacing(0, -1),
  },
  slide: {
    boxSizing: 'content-box',
    position: 'relative',
    flexShrink: 0,
    width: 'var(--slide-width)',
    padding: theme.spacing(0, 1),
  },
  article: {
    overflow: 'hidden',
    padding: 'var(--slide-spacing)',
    borderRadius: 4,
  },
  articleAvatar: {
    width: '1em',
    height: '1em',
    fontSize: 'calc(var(--slide-width) - var(--slide-spacing) * 2)',
  },
  articleLabel: {
    ...theme.mixins.lineClamp(2),
    marginTop: theme.spacing(2),
  },
}))

const ArticleArchiveSlideshow = React.memo(function ArticleArchiveSlideshow(props) {
  const { articles, className, ...other } = props
  const classes = useStyles(props)

  const [emblaRef] = useEmblaCarousel({
    align: 'center',
    containScroll: 'trimSnaps',
  })

  return (
    <div className={clsx(classes.root, className)} ref={emblaRef} {...other}>
      <div className={classes.wrapper}>
        {articles.map((article, idx) => (
          <div key={idx} className={classes.slide}>
            <BlockButton
              className={classes.article}
              component={RouterLink}
              href={`/articles/${article.slug}`}
            >
              <Avatar className={classes.articleAvatar} src={article.mediaSrc} />
              <Typography className={classes.articleLabel}>{article.heading}</Typography>
            </BlockButton>
          </div>
        ))}
      </div>
    </div>
  )
})

ArticleArchiveSlideshow.propTypes = {
  articles: PropTypes.arrayOf(articleType).isRequired,
  className: PropTypes.string,
}

export default ArticleArchiveSlideshow
