import { styles as MuiAccordion } from '../Accordion/Accordion'
import { styles as MuiAccordionDetails } from '../AccordionDetails/AccordionDetails'
import { styles as MuiAccordionSummary } from '../AccordionSummary/AccordionSummary'
import { styles as MuiAppBar } from '../AppBar/AppBar'
import { styles as MuiBackdrop } from '../Backdrop/Backdrop'
import { styles as MuiButton } from '../Button/Button'
import { styles as MuiCheckbox } from '../Checkbox/Checkbox'
import { styles as MuiContainer } from '../Container/Container'
import { styles as MuiFormControlLabel } from '../FormControlLabel/FormControlLabel'
import { styles as MuiIconButton } from '../IconButton/IconButton'
import { styles as MuiLink } from '../Link/Link'
import { styles as MuiRadio } from '../Radio/Radio'
import { styles as MuiToolbar } from '../Toolbar/Toolbar'
import { styles as MuiTypography } from '../Typography/Typography'
import * as privateOverrides from './privateOverrides'

export default function createOverrides(theme) {
  const overrides = {
    MuiAccordion,
    MuiAccordionDetails,
    MuiAccordionSummary,
    MuiAppBar,
    MuiBackdrop,
    MuiButton,
    MuiCheckbox,
    MuiContainer,
    MuiFormControlLabel,
    MuiIconButton,
    MuiLink,
    MuiRadio,
    MuiToolbar,
    MuiTypography,
    ...privateOverrides,
  }

  return Object.entries(overrides).reduce((acc, [muiName, styles]) => {
    acc[muiName] = typeof styles === 'function' ? styles(theme) : styles
    return acc
  }, {})
}
