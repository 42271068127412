import * as React from 'react'
import PropTypes from 'prop-types'
import StoryblokService from 'utils/storyblok-service'
import { useGlobal } from 'api'
import ProductHeroOtherComponent from './ProductHeroOther'

const ProductHeroOther = (props) => {
  const { products } = useGlobal()
  const product = products?.find((p) => p.productCode === props.productCode)

  const packageShot = product?.imageUrl || ''

  const accordionItems = props.accordion?.map((item) => ({
    ...item,
    details:
      typeof item.details === 'string'
        ? item.details?.replace(/\n/g, '<br />')
        : StoryblokService?.client?.richTextResolver?.render(item.details),
  }))

  const transformProps = {
    ...props,
    accordionItems,
    product,
    packageShot,
    content: StoryblokService?.client?.richTextResolver?.render(props.content),
  }

  return <ProductHeroOtherComponent {...transformProps} />
}

const itemType = PropTypes.shape({
  details: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
})

ProductHeroOther.propTypes = {
  productCode: PropTypes.string,
  content: PropTypes.object,
  accordion: PropTypes.arrayOf(itemType).isRequired,
}

export default ProductHeroOther
