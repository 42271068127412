import * as React from 'react'

/**
 * @ignore - internal component.
 */
const ProductFlowControlContext = React.createContext({})

if (process.env.NODE_ENV !== 'production') {
  ProductFlowControlContext.displayName = 'ProductFlowControlContext'
}

export function useProductFlowControl() {
  return React.useContext(ProductFlowControlContext)
}

export default ProductFlowControlContext
