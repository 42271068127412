import * as React from 'react'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'
import dynamic from 'next/dynamic'
import { useGlobal } from 'api'
import { productType } from 'utils'
import ProductCard from 'containers/ProductCard'
import Section from 'components/Section'
import Concerns from 'blocks/Concerns'
import Filter from 'components/icons/Filter'
import Button from 'components/Button'
import StoryblokService from 'utils/storyblok-service'
import Html from 'components/Html'
import { useTranslations } from 'containers/Translations/TranslationsContext'

const BREAKPOINT_KEY_UP = 'sm'

const FilterDrawer = dynamic(() => import('./partials/FilterDrawer'), { ssr: false })

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: -1,
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  list: {
    display: 'grid',
    overflow: 'hidden',
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: {
      gridTemplateColumns: 'repeat(auto-fit, minmax(400px, 1fr))',
    },
  },
  listItem: {
    padding: 'var(--coa-section-spacing) var(--coa-container-spacing)',
    margin: '0 -1px -1px 0 ',
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  product: theme.mixins.contain(400),
  filterIcon: {
    height: '18px',
    width: '18px',
    '& > g > path': {
      fill: '#FFF !important',
    },
  },
  filterContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
      flexDirection: 'row',
      padding: '0 var(--coa-container-spacing)',
    },
  },
  filterButtonContainer: {
    display: 'flex',
    alignSelf: 'flex-end',
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('md')]: {
      alignSelf: 'center',
      padding: 0,
    },
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  filterButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > span': {
      gap: theme.spacing(0.5),
    },
    padding: theme.spacing(1, 2),
  },
  topContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(6),
    borderBottom: `1px solid ${theme.palette.divider}`,
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2, 0, 4, 0),
  },
  descriptionContainer: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    width: '100%',
    padding: '0 var(--coa-container-spacing)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  description: {
    maxWidth: '576px',
  },
  filterWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      gap: theme.spacing(4),
    },
  },
}))

function ProductGroup(props) {
  const {
    items,
    products: transformedProducts,
    ctaType,
    hideDescription,
    onbuttonDescription,
    showCustom4,
    showCustom5,
    showLearnMoreDescr,
    hideMedia,
    imageTag,
    selectionType = 'scrollup',
    showPriceReview,
    showFromPrice,
    showReview,
    priceLabel,
    useBasePrices = false,
    concerns,
    filters,
  } = props
  const classes = useStyles(props)

  const { products } = useGlobal()
  const t = useTranslations()

  const [displayProducts, setDisplayProducts] = React.useState(transformedProducts)
  const [displayItems, setDisplayItems] = React.useState(items)
  const [selectedC, setSelectedC] = React.useState(concerns?.[0]?.name || '')
  const [filterOpen, setFilterOpen] = React.useState(false)

  const onSetTransformedProducts = React.useCallback((prod) => {
    setDisplayProducts(prod)
  }, [])

  const onSetTransformedItems = React.useCallback((itms) => {
    setDisplayItems(itms)
  }, [])

  const transformedConserns = React.useMemo(() => {
    if (!concerns && !concerns?.length) return []

    return concerns?.map((concern) => {
      const { name, image, productCodes, description, textAlign } = concern

      return {
        name,
        imageUrl: image?.filename || undefined,
        productCodes,
        description: StoryblokService?.client?.richTextResolver?.render(description),
        textAlign,
      }
    })
  }, [concerns])

  return (
    <Section className={classes.root}>
      {concerns && concerns.length > 0 && (
        <div className={classes.topContainer}>
          <div className={classes.filterWrapper}>
            <div className={classes.filterContainer}>
              {concerns?.length > 0 && (
                <Concerns
                  concerns={transformedConserns}
                  onSetTransformedProducts={onSetTransformedProducts}
                  onSetTransformedItems={onSetTransformedItems}
                  items={items}
                  products={transformedProducts}
                  setSelectedC={setSelectedC}
                />
              )}
              <div className={classes.filterButtonContainer}>
                <Button
                  className={classes.filterButton}
                  variant="contained"
                  onClick={() => setFilterOpen(true)}
                >
                  <Filter className={classes.filterIcon} />
                  {t('Web.AllProducts.Filter')}
                </Button>
              </div>
            </div>

            {selectedC.description && (
              <div className={classes.descriptionContainer}>
                <Html
                  className={classes.description}
                  style={{ textAlign: selectedC.textAlign }}
                  dangerouslySetInnerHTML={{ __html: selectedC.description }}
                />
              </div>
            )}
          </div>
        </div>
      )}

      <div className={classes.list}>
        {displayItems && displayItems.length > 0
          ? displayItems?.map((product, idx) => (
              <div key={idx} className={classes.listItem}>
                {product.productCode !== '' && (
                  <ProductCard
                    className={classes.product}
                    product={products?.find((p) => p.productCode === product.productCode.trim())}
                    ctaType={product.ctaType}
                    hideDescription={product.hideDescription}
                    showCustom4={product.showCustom4}
                    showCustom5={product.showCustom5}
                    showLearnMoreDescr={product.showLearnMoreDescr}
                    hideMedia={product.hideMedia}
                    imageTag={product.imageTag}
                    selectionType={product.selectionType}
                    showPriceReview={product.showPriceReview}
                    showFromPrice={product.showFromPrice}
                    showReview={product.showReview}
                    priceLabel={product.priceLabel}
                    attributeLabel={product.attributeLabel}
                    attributeColor={product.attributeColor}
                    onbuttonDescription={product.onbuttonDescription}
                    useBasePrices={useBasePrices}
                  />
                )}
              </div>
            ))
          : displayProducts?.map((product, idx) => (
              <div key={idx} className={classes.listItem}>
                {product && (
                  <ProductCard
                    className={classes.product}
                    product={product}
                    ctaType={ctaType}
                    hideDescription={hideDescription}
                    showCustom4={showCustom4}
                    showCustom5={showCustom5}
                    showLearnMoreDescr={showLearnMoreDescr}
                    hideMedia={hideMedia}
                    imageTag={imageTag}
                    selectionType={selectionType}
                    showPriceReview={showPriceReview}
                    onbuttonDescription={onbuttonDescription}
                    showFromPrice={showFromPrice}
                    showReview={showReview}
                    priceLabel={priceLabel}
                    useBasePrices={useBasePrices}
                  />
                )}
              </div>
            ))}
      </div>

      {filters && filters?.length > 0 && (
        <FilterDrawer
          filterOpen={filterOpen}
          setFilterOpen={setFilterOpen}
          products={transformedProducts}
          setProducts={onSetTransformedProducts}
          setItems={onSetTransformedItems}
          items={items}
          filters={filters}
        />
      )}
    </Section>
  )
}

ProductGroup.propTypes = {
  items: PropTypes.array,
  ctaType: PropTypes.oneOf(['link', 'button', '']),
  selectionType: PropTypes.oneOf(['scrollup', 'grid', 'onbutton', '']),
  products: PropTypes.arrayOf(productType),
  showCustom4: PropTypes.bool,
  hideDescription: PropTypes.bool,
  onbuttonDescription: PropTypes.bool,
  showCustom5: PropTypes.bool,
  showLearnMoreDescr: PropTypes.bool,
  hideMedia: PropTypes.bool,
  imageTag: PropTypes.string,
  showPriceReview: PropTypes.bool,
  showFromPrice: PropTypes.bool,
  showReview: PropTypes.bool,
  priceLabel: PropTypes.string,
  useBasePrices: PropTypes.bool,
  concerns: PropTypes.arrayOf(PropTypes.object),
  filters: PropTypes.arrayOf(PropTypes.object),
}

export default ProductGroup
