import * as React from 'react'
import createSvgIcon from '../utils/createSvgIcon'

export default createSvgIcon(
  <>
    <circle cx="76.5" cy="76.5" r="76.5" fill="#2E2630" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M65 105.674L108.235 48L114 52.3256L70.7647 110L65 105.674Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M53.4707 89.8399L74.6822 104.772L70.2609 110.646L49.0495 95.7139L53.4707 89.8399Z"
      fill="white"
    />
  </>,
  'BigCheck',
  '0 0 153 153',
)
